import React, { useState, useEffect,useMemo, useReducer,useRef } from 'react';
import {soloDNI, soloCelular, soloNumero } from '../../utils/utils';
import { useRegiones } from '../../hooks/useRegiones';
import { useEmpresas } from '../../hooks/useEmpresas';
import { useReclamo } from '../../hooks/registros/useReclamo';
import { notify } from '../../utils/utils';
import { AuthFetch } from '../../services/api'
import logoAbexa from "../../assets/images/LogoAbexa_BN.png";
// const catastroTipo = {
//     MOSTRAR_POLIGONOS: 'MOSTRAR_POLIGONOS',
//     CAMBIAR_VALOR: 'CAMBIAR_VALOR'}

// const estadoInicial = {
//     codPais: 1,
//     codDepartamento: 0,
//     codProvincia: 0,
//     codDistrito: 0,
// }


// const catastroTerritorialReducer = (state, action) => {
//     switch (action.type) {
//         case catastroTipo.CAMBIAR_VALOR:
//             const { key, value } = action.payload;
//             return {
//                 ...state,
//                 [key]: value
//             }
//         default:
//             throw new Error('Not implemented');
//     }
// }

// const useCatastroTerritorial = () => {
//     const [ state, dispatch ] = useReducer(catastroTerritorialReducer, estadoInicial);
//     // const [ paises, obtenerPaises, departamentos, obtenerDepartamentos, provincias, obtenerProvincias, distritos, obtenerDistritos ] = useRegiones();





    
//     // useMemo(() => {
//     //     obtenerPaises();
//     // }, [])

//     // useMemo(() => {
//     //     obtenerDepartamentos(state.codPais);
//     // }, [paises])

//     // useMemo(() => {     
//     //     obtenerDistritos(0)
//     // }, [state.codDepartamento])

//     // useMemo(() => {
//     //     obtenerDistritos(Number(state.codProvincia))
//     // }, [state.codProvincia])

//     // useEffect(() => {
//     // }, [state.codDistrito])


    
//     return {
//         state,
//         dispatch,
//         // departamentos,
//         // provincias,
//         // distritos
//     }
// }




export const RegistroReclamaciones= () => {
    const classNameInput = "text-gray-700 h-[30px] rounded px-4 mt-1 w-full border text-[14px] focus:outline-none focus:ring-1 focus:ring-blue-300 lg:w-full caret-gray"   
    const classNameDiv = " lg:w-[200px] text-gray-700 flex items-center gap-2 lg:w-[80%] w-[300px] mb-5"
    // const { state, dispatch, departamentos, provincias, distritos } = useCatastroTerritorial();
    const {reclamo, guardarReclamo, editarValorReclamo} = useReclamo();
    const {departamentos, obtenerDepartamentos, provincias, obtenerProvincias, distritos, obtenerDistritos } = useRegiones();
    const {empresas, obtenerEmpresas} = useEmpresas();
    useEffect(()=>{
        obtenerDepartamentos();
        obtenerEmpresas();
    },[])
    useEffect(()=>{

    },[])
    
        
    return (
        <> 
            <div className=" w-full flex gap-8 items-center flex-wrap justify-center mt-5 bg-gray-200 mb-5">
                <div className="lg:w-80  lg:mt-0 lg:mb-0 mt-5 mb-5 px-4 lg:border-none border  bg-white">
                    <div className="flex flex-col mb-5 items-center m-0">
                        <div className={` items-center mt-2 mb-2 lg:w-48 w-80`}>
                            <img src={logoAbexa}></img>
                        </div>
                        <div className={`${classNameDiv } text-justify mt-0 mb-0`}>
                            <label className='text-sm'>En Beex, estamos comprometidos con brindar un servicio excepcional a nuestros clientes.<br></br>Si ha tenido alguna experiencia insatisfactoria, por favor, utilice este libro de reclamaciones para que podamos atenderlo de manera inmediata y efectiva.</label>
                        </div>
                        <div className={`${classNameDiv }`}>
                            <label className='w-full  mt-3 lg:text-  text-xl text-gray-800'><strong>Datos personales:</strong></label>
                        </div>
                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Tipo de Documento:</label>
                            </div>
                            <div className={`${classNameDiv }`}>
                                <select className={`${classNameInput} w-full `}  defaultValue={reclamo.codDocumento} onChange={(e) => editarValorReclamo("codDocumento", e.target.value)} >
                                    <option value={1}> DNI</option>
                                    <option value={2}>Carne Extranjería</option>
                                </select>
                            </div>
                        </div>

                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Documento:</label>
                            </div>    
                            <div className={`${classNameDiv }`}>
                                <input className={`${classNameInput}`} id='inputnroDocumento' autoFocus={true}  onKeyPress={(e) => soloDNI(e, e.target)} autoComplete='false' defaultValue={reclamo.nroDocumento} onChange={(e) => editarValorReclamo("nroDocumento", e.target.value)}  />
                            </div>
                        </div>

                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Nombres:</label>
                            </div>
                            <div className={`${classNameDiv }`}>
                                <input className={`${classNameInput}`} id='inputnomPersona'  autoComplete='false' defaultValue={reclamo.nomPersona} onChange={(e) => editarValorReclamo("nomPersona", e.target.value)}  />
                            </div>
                        </div>
                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Apellido Paterno:</label>
                            </div>
                            <div className={`${classNameDiv }`}>
                                <input className={`${classNameInput} `} id='inputapePaterno' autoComplete='false' defaultValue={reclamo.apePaterno} onChange={(e) => editarValorReclamo("apePaterno", e.target.value)}  />
                            </div>
                        </div>

                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Apellido Materno:</label>
                            </div>
                            <div className={`${classNameDiv }`}>
                                <input className={`${classNameInput} `} id='inputapeMaterno' autoComplete='false'  defaultValue={reclamo.apeMaterno} onChange={(e) => editarValorReclamo("apeMaterno", e.target.value)}  />
                            </div>
                        </div>

                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Telefono:</label>
                            </div>
                            <div className={`${classNameDiv }`}>
                                <input className={`${classNameInput} `} onKeyPress={(e) => soloCelular(e, e.target)}  autoComplete='false'  defaultValue={reclamo.telefono} onChange={(e) => editarValorReclamo("telefono", e.target.value)}/>
                            </div>
                        </div>
                        
                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Email:</label>
                            </div>    
                            <div className={`${classNameDiv }`}>
                                <input className={`${classNameInput} `}   autoComplete='false' defaultValue={reclamo.email} onChange={(e) => editarValorReclamo("email", e.target.value)} />
                            </div>
                        </div>

                        {/* ////////////////////DETALLE DE RECLAMO   */}
                        <div className={`${classNameDiv} mt-0 mb-0`}>
                            <label className='flex lg: flex-col  justify-center lg:w-[250px] mt-3 lg:text- mb-5 text-xl text-gray-800'><strong>Detalle del reclamo</strong></label>
                        </div>

                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Departamento:</label>
                            </div>    
                            <div className={`${classNameDiv }`}>
                                <select className={`${classNameInput}  `} defaultValue={departamentos.CodDepartamento} onChange={(e) => {obtenerProvincias(e.target.value) ; editarValorReclamo("departamento", e.target.options[e.target.selectedIndex].text)}} >
                                    <option value="0">--Seleccione--</option>
                                {
                                    departamentos.map(d => {
                                        return <option key={d.CodDepartamento} value={d.CodDepartamento}>{d.NomDepartamento}</option>
                                    })
                                }   
                                </select>
                            </div>
                        </div>
                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Provincia:</label>
                            </div>
                            <div className={`${classNameDiv }`}>
                                <select className={`${classNameInput}`} id='selectProvincia' defaultValue={provincias.CodProvincia} onChange={(e) => {obtenerDistritos(e.target.value) ; editarValorReclamo("provincia", e.target.options[e.target.selectedIndex].text)}}>  
                                    <option value="0">--Seleccione--</option>
                                {
                                    provincias.map( p => {
                                        return <option key={p.CodProvincia} value={p.CodProvincia}>{p.NomProvincia}</option>
                                    })
                                }
                                </select>
                            </div>
                        </div>

                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Distrito:</label>
                            </div>    
                            <div className={`${classNameDiv }`}>
                                <select className={`${classNameInput}  `} onChange={(e) => editarValorReclamo("distrito", e.target.options[e.target.selectedIndex].text)}>
                                    <option value="0">--Seleccione--</option>
                                    {
                                        distritos.map(d => {
                                            return <option key={d.CodDistrito} value={d.CodDistrito}>{d.NomDistrito}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        
                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Empresa:</label>
                            </div>    
                            <div className={`${classNameDiv }`}>
                                <select className={`${classNameInput}  `}  onChange={(e) => editarValorReclamo("codEmpresaGeneral", e.target.value)}>
                                    <option value="0">--Seleccione--</option>
                                    {
                                        empresas.map(d => {
                                            return <option key={d.codEmpresa} value={d.codEmpresa}>{d.nomEmpresa}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        
                        < div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Tipo:</label>
                            </div>    
                            <div className={`${classNameDiv }`}>
                                <select className={`${classNameInput} flex lg: flex-col lg:w-full`}  defaultValue={reclamo.codTipoConsulta} onChange={(e) => editarValorReclamo("codTipoConsulta", e.target.value)}>
                                    <option value={1}>Reclamo</option>
                                    <option value={2}>Queja</option>
                                </select>
                            </div>
                        </div>
                        <div className='flex lg: flex-col lg:w-full items-center'>
                            <div className={`${classNameDiv } mb-0`}>
                                <label className=''>Coméntanos lo sucedido:</label>
                            </div>    
                            <div className={`${classNameDiv }`}>
                                <textarea className={`py-2 h-[100px] ${classNameInput} `} id='inputdetalleReclamo'  autoComplete='false' defaultValue={reclamo.detalleReclamo} onChange={(e) => editarValorReclamo("detalleReclamo", e.target.value)}  ></textarea>
                            </div>
                            <div className={`${classNameDiv } text-justify `}>
                                <label className='text-sm'>Agradecemos su confianza en nuestro libro de reclamaciones.<br></br>Sus comentarios y sugerencias son muy valiosos para nosotros y nos permiten mejorar continuamente. Esperamos haber atendido todas sus inquietudes de manera efectiva, lo invitamos a seguir contando con nuestros productos y servicios en el futuro.</label>
                            </div>
                            <div className='text-center lg:text-center  w-[300px]'>
                                <button className='lg:text-xl lg:p-8 lg:px-14 lg:py-3 hover:shadow-sm hover:shadow-gray-900 bg-gray-800 hover:bg-gray-600  text-white rounded-xl text-xl border p-5 py-2 ' onClick={()=>guardarReclamo()}  >ENVIAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

)}