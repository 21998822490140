import React, { useContext } from 'react'
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
// import { Web } from '../pages/Web'
import { RegistroReclamaciones } from '../pages/RegistroReclamaciones';
import { Sugerencias } from '../pages/Sugerencias';


export function PublicRoutes() {
    return (
        <>
            <Switch>             
                <Route path='/registro' component={RegistroReclamaciones} />
                <Route path='/sugerencias' component={Sugerencias} />
                <Route path='/' component={RegistroReclamaciones} />
            </Switch>
        </>
    )
}