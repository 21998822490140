import { useState, useEffect, useContext } from "react";
import { notify } from "../../utils/utils";
import { AuthFetch, Fetch } from "../../services/api";

export const useSugerencias = () => {
    const sugerenciaDefault= {"NroDocumento": '',"NomPersona": '',"ComentarioSugerencia": ''}
    const [sugerencia, setSugerencia] = useState(sugerenciaDefault)
    const guardarSugerencia = async () => {
        const variable =['NroDocumento','NomPersona','ComentarioSugerencia']
        var contador =0
        variable.forEach((element,i)  => {
            if(document.getElementById(`input${element}`).value == ''){
                document.getElementById(`input${element}`).focus()
                notify('No puede dejar el campo vacío','error')
                contador++
            }
        });
        if(contador>0){
            return
        }
        const response = await Fetch({
            url: 'https://atuwebapi.plaminoficial.com/api/Reclamo/sugerencia',
            method: 'POST',
            body: JSON.stringify({
                NroDocumento: sugerencia.NroDocumento,
                NomPersona: sugerencia.NomPersona,
                ComentarioSugerencia: sugerencia.ComentarioSugerencia
            })
        });
        if (response.isValid){
            setSugerencia(sugerenciaDefault);
            notify(response.content,'success');
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        }else{
            notify(response.exceptions[0].description, 'error')
        }
    }
    const editarValorSugerencia = (key,value) =>{
        console.log('key → ',key)
        console.log('value → ',value)
        setSugerencia(sugerencia => {
            return {
                ...sugerencia,
                [key]: value
            }
        });
    }
    return {sugerencia,  guardarSugerencia,editarValorSugerencia};
}