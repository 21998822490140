import React, { useEffect, useState, useRef } from 'react';
import logoAbexa from "../../assets/images/LogoAbexa_BN.png";
import {soloDNI, soloCelular, soloNumero } from '../../utils/utils';
import { useSugerencias } from '../../hooks/registros/useSugerencias';
export const Sugerencias =()=>{
    const {sugerencia, guardarSugerencia, editarValorSugerencia} = useSugerencias();
    const classNameInput = "text-gray-700 h-[30px] rounded px-4 mt-1 w-full border text-[14px] focus:outline-none focus:ring-1 focus:ring-blue-300 lg:w-full caret-gray"   
    return(
    <>
        <div className='py-5 flex justify-center items-center w-full'>
            <div className=" w-full flex gap-8 items-center flex-wrap justify-center bg-gray-200">
                <div className='lg:py-5 py-5 w-full flex justify-center'>
                    <div className="lg:w-[80%] w-[50%] lg:border-none border bg-white rounded-md">
                        <div className="flex flex-col items-center py-2 lg:py-2 w-full">
                            <div className={` items-center py-2 lg:w-[80%] w-[30%]`}>
                                <img src={logoAbexa}></img>
                            </div>
                            <div className='text-center py-2 lg:py-2'>
                                <label className='underline text-3xl lg:text-2xl font-bold'>Sugerencia</label>
                            </div>
                            <div className='flex-col w-[80%] py-2 lg:w-[80%] lg:py-2'>
                                <label>DNI</label>
                                <input className={`${classNameInput}`} id='inputNroDocumento' defaultValue={sugerencia.NroDocumento} onKeyPress={(e) => soloDNI(e, e.target)} autoComplete='false' onChange={(e) => editarValorSugerencia('NroDocumento',e.target.value)}></input>
                            </div>
                            <div className='flex-col w-[80%] lg:w-[80%] py-2 lg:py-2'>
                                <label>NOMBRES</label>
                                <input className={`${classNameInput}` } id='inputNomPersona' defaultValue={sugerencia.NomPersona} onChange={(e) => editarValorSugerencia('NomPersona',e.target.value)}></input>
                            </div>
                            <div className='flex-col py-2 w-[80%] lg:w-[80%] lg:py-2'>
                                <label>COMENTARIO</label>
                                <textarea autoFocus={true} id='inputComentarioSugerencia' defaultValue={sugerencia.ComentarioSugerencia} className={`${classNameInput} caret-input h-[200px] lg:h-[250px]`} onChange={(e) => editarValorSugerencia('ComentarioSugerencia',e.target.value)}></textarea>
                            </div>
                            <div className='flex justify-center items-center p-2'>
                                <button className='bg-gray-800 py-2 px-5 rounded-lg hover:shadow-sm text-white text-xl border hover:shadow-gray-900 hover:border-gray-600 hover:bg-slate-700' onClick={()=>guardarSugerencia()}>ENVIAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </>
    
    
    )
    
    
}