import { useState, useEffect, useContext } from "react";
import { notify } from "../../utils/utils";
import { AuthFetch, Fetch } from "../../services/api";

export const useReclamo = () => {
    const reclamoDefault = {
            "codDocumento": 1,
            "nroDocumento": '',
            "nomPersona": '',
            "apePaterno": '',
            "apeMaterno": '',
            "telefono": '',
            "email": '',
            "departamento": '',
            "provincia": '',
            "distrito": '',
            "codEmpresaGeneral": 0,
            "codTipoConsulta": 1,
            "detalleReclamo": ''
    }

    const [ reclamo, setReclamo ] = useState(reclamoDefault);


    const guardarReclamo = async () => {
        // !reclamo.nroDocumento && notify("Ingrese su número de documento", 'error')
        // !reclamo.nomPersona && notify("Ingrese su número de documento", 'error')
        // !reclamo.apePaterno && notify("Ingrese su número de documento", 'error')
        // !reclamo.apeMaterno && notify("Ingrese su número de documento", 'error')
        // !reclamo.detalleReclamo && notify("Ingrese su número de documento", 'error')
        const variable =['nroDocumento','nomPersona','apePaterno','apeMaterno','detalleReclamo']
        var contador =0
        variable.forEach((element,i)  => {
            if(document.getElementById(`input${element}`).value == ''){
                document.getElementById(`input${element}`).focus()
                notify('No puede dejar el campo vacío','error')
                contador++
            }
        });
        if(contador>0){
            return
        }
        // return
        const response = await Fetch({
            url: 'https://atuwebapi.plaminoficial.com/api/Reclamo',
            method: 'POST',
            body: JSON.stringify({
                codDocumento: reclamo.codDocumento,
                nroDocumento: reclamo.nroDocumento,
                nomPersona: reclamo.nomPersona,
                apePaterno: reclamo.apePaterno,
                apeMaterno: reclamo.apeMaterno,
                telefono: reclamo.telefono,
                email: reclamo.email,
                departamento: reclamo.departamento,
                provincia: reclamo.provincia,
                distrito: reclamo.distrito,
                codEmpresaGeneral: reclamo.codEmpresaGeneral,
                codTipoConsulta: reclamo.codTipoConsulta,
                detalleReclamo: reclamo.detalleReclamo
            })
        });
        if (response.isValid){
            setReclamo(reclamoDefault);
            notify(response.content,'success');
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        }else{
            notify(response.exceptions[0].description, 'error')
        }
    }

    const editarValorReclamo = (key,value) =>{
        setReclamo(reclamo => {
            return {
                ...reclamo,
                [key]: value
            }
        });
    }
    return {reclamo,  guardarReclamo, editarValorReclamo };
}