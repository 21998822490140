import { useState, useEffect } from 'react';
import { notify } from '../utils/utils';
import { AuthFetch,Fetch } from '../services/api';

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";
const departamentosPrueba = [{"CodDepartamento":1,"NomDepartamento":"AMAZONAS"},
                                    {"CodDepartamento":2,"NomDepartamento":"ANCASH"},
                                    {"CodDepartamento":3,"NomDepartamento":"APURIMAC"},
                                    {"CodDepartamento":4,"NomDepartamento":"AREQUIPA"},
                                    {"CodDepartamento":5,"NomDepartamento":"AYACUCHO"},
                                    {"CodDepartamento":6,"NomDepartamento":"CAJAMARCA"},
                                    {"CodDepartamento":7,"NomDepartamento":"CALLAO"},
                                    {"CodDepartamento":8,"NomDepartamento":"CUSCO"},
                                    {"CodDepartamento":9,"NomDepartamento":"HUANCAVELICA"},
                                    {"CodDepartamento":10,"NomDepartamento":"HUANUCO"},
                                    {"CodDepartamento":11,"NomDepartamento":"ICA"},
                                    {"CodDepartamento":12,"NomDepartamento":"JUNIN"},
                                    {"CodDepartamento":13,"NomDepartamento":"LA LIBERTAD"},
                                    {"CodDepartamento":14,"NomDepartamento":"LAMBAYEQUE"},
                                    {"CodDepartamento":15,"NomDepartamento":"LIMA"},
                                    {"CodDepartamento":16,"NomDepartamento":"LORETO"},
                                    {"CodDepartamento":17,"NomDepartamento":"MADRE DE DIOS"},
                                    {"CodDepartamento":18,"NomDepartamento":"MOQUEGUA"},
                                    {"CodDepartamento":19,"NomDepartamento":"PASCO"},
                                    {"CodDepartamento":20,"NomDepartamento":"PIURA"},
                                    {"CodDepartamento":21,"NomDepartamento":"PUNO"},
                                    {"CodDepartamento":22,"NomDepartamento":"SAN MARTIN"},
                                    {"CodDepartamento":23,"NomDepartamento":"TACNA"},
                                    {"CodDepartamento":24,"NomDepartamento":"TUMBES"},
                                    {"CodDepartamento":25,"NomDepartamento":"UCAYALI"}]

const provinciasPruebas = [
    {"CodProvincia":1,"NomProvincia":"BAGUA","CodDepartamento":1},{"CodProvincia":2,"NomProvincia":"BONGARA","CodDepartamento":1},
    {"CodProvincia":3,"NomProvincia":"CHACHAPOYAS","CodDepartamento":1},{"CodProvincia":4,"NomProvincia":"CONDORCANQUI","CodDepartamento":1},
    {"CodProvincia":5,"NomProvincia":"LUYA","CodDepartamento":1},{"CodProvincia":6,"NomProvincia":"RODRiGUEZ DE MENDOZA","CodDepartamento":1},
    {"CodProvincia":7,"NomProvincia":"UTCUBAMBA","CodDepartamento":1},{"CodProvincia":8,"NomProvincia":"AIJA","CodDepartamento":2},
    {"CodProvincia":9,"NomProvincia":"ANTONIO RAYMONDI","CodDepartamento":2},{"CodProvincia":10,"NomProvincia":"ASUNCION","CodDepartamento":2},
    {"CodProvincia":11,"NomProvincia":"BOLOGNESI","CodDepartamento":2},{"CodProvincia":12,"NomProvincia":"CARHUAZ","CodDepartamento":2},
    {"CodProvincia":13,"NomProvincia":"CARLOS FERMIN FITZCARRALD","CodDepartamento":2},{"CodProvincia":14,"NomProvincia":"CASMA","CodDepartamento":2},
    {"CodProvincia":15,"NomProvincia":"CORONGO","CodDepartamento":2},{"CodProvincia":16,"NomProvincia":"HUARAZ","CodDepartamento":2},
    {"CodProvincia":17,"NomProvincia":"HUARI","CodDepartamento":2},{"CodProvincia":18,"NomProvincia":"HUARMEY","CodDepartamento":2},
    {"CodProvincia":19,"NomProvincia":"HUAYLAS","CodDepartamento":2},{"CodProvincia":20,"NomProvincia":"MARISCAL LUZURIAGA","CodDepartamento":2},
    {"CodProvincia":21,"NomProvincia":"OCROS","CodDepartamento":2},{"CodProvincia":22,"NomProvincia":"PALLASCA","CodDepartamento":2},
    {"CodProvincia":23,"NomProvincia":"POMABAMBA","CodDepartamento":2},{"CodProvincia":24,"NomProvincia":"RECUAY","CodDepartamento":2},
    {"CodProvincia":25,"NomProvincia":"SANTA","CodDepartamento":2},{"CodProvincia":26,"NomProvincia":"SIHUAS","CodDepartamento":2},
    {"CodProvincia":27,"NomProvincia":"YUNGAY","CodDepartamento":2},{"CodProvincia":28,"NomProvincia":"ABANCAY","CodDepartamento":3},
    {"CodProvincia":29,"NomProvincia":"ANDAHUAYLAS","CodDepartamento":3},{"CodProvincia":30,"NomProvincia":"ANTABAMBA","CodDepartamento":3},
    {"CodProvincia":31,"NomProvincia":"AYMARAES","CodDepartamento":3},{"CodProvincia":32,"NomProvincia":"CHINCHEROS","CodDepartamento":3},
    {"CodProvincia":33,"NomProvincia":"COTABAMBAS","CodDepartamento":3},{"CodProvincia":34,"NomProvincia":"GRAU","CodDepartamento":3},
    {"CodProvincia":35,"NomProvincia":"AREQUIPA","CodDepartamento":4},{"CodProvincia":36,"NomProvincia":"CAMANA","CodDepartamento":4},
    {"CodProvincia":37,"NomProvincia":"CARAVELI","CodDepartamento":4},{"CodProvincia":38,"NomProvincia":"CASTILLA","CodDepartamento":4},
    {"CodProvincia":39,"NomProvincia":"CAYLLOMA","CodDepartamento":4},{"CodProvincia":40,"NomProvincia":"CONDESUYOS","CodDepartamento":4},
    {"CodProvincia":41,"NomProvincia":"ISLAY","CodDepartamento":4},{"CodProvincia":42,"NomProvincia":"LA UNION","CodDepartamento":4},
    {"CodProvincia":43,"NomProvincia":"CANGALLO","CodDepartamento":5},{"CodProvincia":44,"NomProvincia":"HUAMANGA","CodDepartamento":5},
    {"CodProvincia":45,"NomProvincia":"HUANCA SANCOS","CodDepartamento":5},{"CodProvincia":46,"NomProvincia":"HUANTA","CodDepartamento":5},
    {"CodProvincia":47,"NomProvincia":"LA MAR","CodDepartamento":5},{"CodProvincia":48,"NomProvincia":"LUCANAS","CodDepartamento":5},
    {"CodProvincia":49,"NomProvincia":"PARINACOCHAS","CodDepartamento":5},{"CodProvincia":50,"NomProvincia":"PAUCAR DEL SARA SARA","CodDepartamento":5},
    {"CodProvincia":51,"NomProvincia":"SUCRE","CodDepartamento":5},{"CodProvincia":52,"NomProvincia":"VICTOR FAJARDO","CodDepartamento":5},
    {"CodProvincia":53,"NomProvincia":"VILCAS HUAMAN","CodDepartamento":5},{"CodProvincia":54,"NomProvincia":"CAJABAMBA","CodDepartamento":6},
    {"CodProvincia":55,"NomProvincia":"CAJAMARCA","CodDepartamento":6},{"CodProvincia":56,"NomProvincia":"CELENDIN","CodDepartamento":6},
    {"CodProvincia":57,"NomProvincia":"CHOTA","CodDepartamento":6},{"CodProvincia":58,"NomProvincia":"CONTUMAZA","CodDepartamento":6},
    {"CodProvincia":59,"NomProvincia":"CUTERVO","CodDepartamento":6},{"CodProvincia":60,"NomProvincia":"HUALGAYOC","CodDepartamento":6},
    {"CodProvincia":61,"NomProvincia":"JAEN","CodDepartamento":6},{"CodProvincia":62,"NomProvincia":"SAN IGNACIO","CodDepartamento":6},
    {"CodProvincia":63,"NomProvincia":"SAN MARCOS","CodDepartamento":6},{"CodProvincia":64,"NomProvincia":"SAN MIGUEL","CodDepartamento":6},
    {"CodProvincia":65,"NomProvincia":"SAN PABLO","CodDepartamento":6},{"CodProvincia":66,"NomProvincia":"SANTA CRUZ","CodDepartamento":6},
    {"CodProvincia":67,"NomProvincia":"PROV. CONST. DEL CALLAO","CodDepartamento":7},{"CodProvincia":68,"NomProvincia":"ACOMAYO","CodDepartamento":8},
    {"CodProvincia":69,"NomProvincia":"ANTA","CodDepartamento":8},{"CodProvincia":70,"NomProvincia":"CALCA","CodDepartamento":8},
    {"CodProvincia":71,"NomProvincia":"CANAS","CodDepartamento":8},{"CodProvincia":72,"NomProvincia":"CANCHIS","CodDepartamento":8},
    {"CodProvincia":73,"NomProvincia":"CHUMBIVILCAS","CodDepartamento":8},{"CodProvincia":74,"NomProvincia":"CUSCO","CodDepartamento":8},
    {"CodProvincia":75,"NomProvincia":"ESPINAR","CodDepartamento":8},{"CodProvincia":76,"NomProvincia":"LA CONVENCION","CodDepartamento":8},
    {"CodProvincia":77,"NomProvincia":"PARURO","CodDepartamento":8},{"CodProvincia":78,"NomProvincia":"PAUCARTAMBO","CodDepartamento":8},
    {"CodProvincia":79,"NomProvincia":"QUISPICANCHI","CodDepartamento":8},{"CodProvincia":80,"NomProvincia":"URUBAMBA","CodDepartamento":8},
    {"CodProvincia":81,"NomProvincia":"ACOBAMBA","CodDepartamento":9},{"CodProvincia":82,"NomProvincia":"ANGARAES","CodDepartamento":9},
    {"CodProvincia":83,"NomProvincia":"CASTROVIRREYNA","CodDepartamento":9},{"CodProvincia":84,"NomProvincia":"CHURCAMPA","CodDepartamento":9},
    {"CodProvincia":85,"NomProvincia":"HUANCAVELICA","CodDepartamento":9},{"CodProvincia":86,"NomProvincia":"HUAYTARA","CodDepartamento":9},
    {"CodProvincia":87,"NomProvincia":"TAYACAJA","CodDepartamento":9},{"CodProvincia":88,"NomProvincia":"AMBO","CodDepartamento":10},
    {"CodProvincia":89,"NomProvincia":"DOS DE MAYO","CodDepartamento":10},{"CodProvincia":90,"NomProvincia":"HUACAYBAMBA","CodDepartamento":10},
    {"CodProvincia":91,"NomProvincia":"HUAMALIES","CodDepartamento":10},{"CodProvincia":92,"NomProvincia":"HUANUCO","CodDepartamento":10},
    {"CodProvincia":93,"NomProvincia":"LAURICOCHA","CodDepartamento":10},{"CodProvincia":94,"NomProvincia":"LEONCIO PRADO","CodDepartamento":10},
    {"CodProvincia":95,"NomProvincia":"MARAÑON","CodDepartamento":10},{"CodProvincia":96,"NomProvincia":"PACHITEA","CodDepartamento":10},
    {"CodProvincia":97,"NomProvincia":"PUERTO INCA","CodDepartamento":10},{"CodProvincia":98,"NomProvincia":"YAROWILCA","CodDepartamento":10},
    {"CodProvincia":99,"NomProvincia":"CHINCHA","CodDepartamento":11},{"CodProvincia":100,"NomProvincia":"ICA","CodDepartamento":11},
    {"CodProvincia":101,"NomProvincia":"NASCA","CodDepartamento":11},{"CodProvincia":102,"NomProvincia":"PALPA","CodDepartamento":11},
    {"CodProvincia":103,"NomProvincia":"PISCO","CodDepartamento":11},{"CodProvincia":104,"NomProvincia":"CHANCHAMAYO","CodDepartamento":12},
    {"CodProvincia":105,"NomProvincia":"CHUPACA","CodDepartamento":12},{"CodProvincia":106,"NomProvincia":"CONCEPCION","CodDepartamento":12},
    {"CodProvincia":107,"NomProvincia":"HUANCAYO","CodDepartamento":12},{"CodProvincia":108,"NomProvincia":"JAUJA","CodDepartamento":12},
    {"CodProvincia":109,"NomProvincia":"JUNIN","CodDepartamento":12},{"CodProvincia":110,"NomProvincia":"SATIPO","CodDepartamento":12},
    {"CodProvincia":111,"NomProvincia":"TARMA","CodDepartamento":12},{"CodProvincia":112,"NomProvincia":"YAULI","CodDepartamento":12},
    {"CodProvincia":113,"NomProvincia":"ASCOPE","CodDepartamento":13},{"CodProvincia":114,"NomProvincia":"BOLIVAR","CodDepartamento":13},
    {"CodProvincia":115,"NomProvincia":"CHEPEN","CodDepartamento":13},{"CodProvincia":116,"NomProvincia":"GRAN CHIMU","CodDepartamento":13},
    {"CodProvincia":117,"NomProvincia":"JULCAN","CodDepartamento":13},{"CodProvincia":118,"NomProvincia":"OTUZCO","CodDepartamento":13},
    {"CodProvincia":119,"NomProvincia":"PACASMAYO","CodDepartamento":13},{"CodProvincia":120,"NomProvincia":"PATAZ","CodDepartamento":13},
    {"CodProvincia":121,"NomProvincia":"SANCHEZ CARRION","CodDepartamento":13},{"CodProvincia":122,"NomProvincia":"SANTIAGO DE CHUCO","CodDepartamento":13},
    {"CodProvincia":123,"NomProvincia":"TRUJILLO","CodDepartamento":13},{"CodProvincia":124,"NomProvincia":"VIRU","CodDepartamento":13},
    {"CodProvincia":125,"NomProvincia":"CHICLAYO","CodDepartamento":14},{"CodProvincia":126,"NomProvincia":"FERREÑAFE","CodDepartamento":14},
    {"CodProvincia":127,"NomProvincia":"LAMBAYEQUE","CodDepartamento":14},{"CodProvincia":128,"NomProvincia":"BARRANCA","CodDepartamento":15},
    {"CodProvincia":129,"NomProvincia":"CAJATAMBO","CodDepartamento":15},{"CodProvincia":130,"NomProvincia":"CAÑETE","CodDepartamento":15},
    {"CodProvincia":131,"NomProvincia":"CANTA","CodDepartamento":15},{"CodProvincia":132,"NomProvincia":"HUARAL","CodDepartamento":15},
    {"CodProvincia":133,"NomProvincia":"HUAROCHIRI","CodDepartamento":15},{"CodProvincia":134,"NomProvincia":"HUAURA","CodDepartamento":15},
    {"CodProvincia":135,"NomProvincia":"LIMA","CodDepartamento":15},{"CodProvincia":136,"NomProvincia":"OYON","CodDepartamento":15},
    {"CodProvincia":137,"NomProvincia":"YAUYOS","CodDepartamento":15},{"CodProvincia":138,"NomProvincia":"ALTO AMAZONAS","CodDepartamento":16},
    {"CodProvincia":139,"NomProvincia":"DATEM DEL MARAÑON","CodDepartamento":16},{"CodProvincia":140,"NomProvincia":"LORETO","CodDepartamento":16},
    {"CodProvincia":141,"NomProvincia":"MARISCAL RAMON CASTILLA","CodDepartamento":16},{"CodProvincia":142,"NomProvincia":"MAYNAS","CodDepartamento":16},
    {"CodProvincia":143,"NomProvincia":"PUTUMAYO","CodDepartamento":16},{"CodProvincia":144,"NomProvincia":"REQUENA","CodDepartamento":16},
    {"CodProvincia":145,"NomProvincia":"UCAYALI","CodDepartamento":16},{"CodProvincia":146,"NomProvincia":"MANU","CodDepartamento":17},
    {"CodProvincia":147,"NomProvincia":"TAHUAMANU","CodDepartamento":17},{"CodProvincia":148,"NomProvincia":"TAMBOPATA","CodDepartamento":17},
    {"CodProvincia":149,"NomProvincia":"GENERAL SANCHEZ CERRO","CodDepartamento":18},{"CodProvincia":150,"NomProvincia":"ILO","CodDepartamento":18},
    {"CodProvincia":151,"NomProvincia":"MARISCAL NIETO","CodDepartamento":18},{"CodProvincia":152,"NomProvincia":"DANIEL ALCIDES CARRION","CodDepartamento":19},
    {"CodProvincia":153,"NomProvincia":"OXAPAMPA","CodDepartamento":19},{"CodProvincia":154,"NomProvincia":"PASCO","CodDepartamento":19},
    {"CodProvincia":155,"NomProvincia":"AYABACA","CodDepartamento":20},{"CodProvincia":156,"NomProvincia":"HUANCABAMBA","CodDepartamento":20},
    {"CodProvincia":157,"NomProvincia":"MORROPON","CodDepartamento":20},{"CodProvincia":158,"NomProvincia":"PAITA","CodDepartamento":20},
    {"CodProvincia":159,"NomProvincia":"PIURA","CodDepartamento":20},{"CodProvincia":160,"NomProvincia":"SECHURA","CodDepartamento":20},
    {"CodProvincia":161,"NomProvincia":"SULLANA","CodDepartamento":20},{"CodProvincia":162,"NomProvincia":"TALARA","CodDepartamento":20},
    {"CodProvincia":163,"NomProvincia":"AZANGARO","CodDepartamento":21},{"CodProvincia":164,"NomProvincia":"CARABAYA","CodDepartamento":21},
    {"CodProvincia":165,"NomProvincia":"CHUCUITO","CodDepartamento":21},{"CodProvincia":166,"NomProvincia":"EL COLLAO","CodDepartamento":21},
    {"CodProvincia":167,"NomProvincia":"HUANCANE","CodDepartamento":21},{"CodProvincia":168,"NomProvincia":"LAMPA","CodDepartamento":21},
    {"CodProvincia":169,"NomProvincia":"MELGAR","CodDepartamento":21},{"CodProvincia":170,"NomProvincia":"MOHO","CodDepartamento":21},
    {"CodProvincia":171,"NomProvincia":"PUNO","CodDepartamento":21},{"CodProvincia":172,"NomProvincia":"SAN ANTONIO DE PUTINA","CodDepartamento":21},
    {"CodProvincia":173,"NomProvincia":"SANDIA","CodDepartamento":21},{"CodProvincia":174,"NomProvincia":"SAN ROMAN","CodDepartamento":21},
    {"CodProvincia":175,"NomProvincia":"YUNGUYO","CodDepartamento":21},{"CodProvincia":176,"NomProvincia":"BELLAVISTA","CodDepartamento":22},
    {"CodProvincia":177,"NomProvincia":"EL DORADO","CodDepartamento":22},{"CodProvincia":178,"NomProvincia":"HUALLAGA","CodDepartamento":22},
    {"CodProvincia":179,"NomProvincia":"LAMAS","CodDepartamento":22},{"CodProvincia":180,"NomProvincia":"MARISCAL CACERES","CodDepartamento":22},
    {"CodProvincia":181,"NomProvincia":"MOYOBAMBA","CodDepartamento":22},{"CodProvincia":182,"NomProvincia":"PICOTA","CodDepartamento":22},
    {"CodProvincia":183,"NomProvincia":"RIOJA","CodDepartamento":22},{"CodProvincia":184,"NomProvincia":"SAN MARTIN","CodDepartamento":22},
    {"CodProvincia":185,"NomProvincia":"TOCACHE","CodDepartamento":22},{"CodProvincia":186,"NomProvincia":"CANDARAVE","CodDepartamento":23},
    {"CodProvincia":187,"NomProvincia":"JORGE BASADRE","CodDepartamento":23},{"CodProvincia":188,"NomProvincia":"TACNA","CodDepartamento":23},
    {"CodProvincia":189,"NomProvincia":"TARATA","CodDepartamento":23},{"CodProvincia":190,"NomProvincia":"CONTRALMIRANTE VILLAR","CodDepartamento":24},
    {"CodProvincia":191,"NomProvincia":"TUMBES","CodDepartamento":24},{"CodProvincia":192,"NomProvincia":"ZARUMILLA","CodDepartamento":24},
    {"CodProvincia":193,"NomProvincia":"ATALAYA","CodDepartamento":25},{"CodProvincia":194,"NomProvincia":"CORONEL PORTILLO","CodDepartamento":25},
    {"CodProvincia":195,"NomProvincia":"PADRE ABAD","CodDepartamento":25},{"CodProvincia":196,"NomProvincia":"PURUS","CodDepartamento":25}
]
const distritosPrueba = 
    [
        {"CodDistrito":1,"NomDistrito":"ARAMANGO","CodProvincia":1},{"CodDistrito":2,"NomDistrito":"BAGUA","CodProvincia":1},{"CodDistrito":3,"NomDistrito":"COPALLIN","CodProvincia":1},
        {"CodDistrito":4,"NomDistrito":"EL PARCO","CodProvincia":1},{"CodDistrito":5,"NomDistrito":"IMAZA","CodProvincia":1},{"CodDistrito":6,"NomDistrito":"LA PECA","CodProvincia":1},
        {"CodDistrito":7,"NomDistrito":"CHISQUILLA","CodProvincia":2},{"CodDistrito":8,"NomDistrito":"CHURUJA","CodProvincia":2},{"CodDistrito":9,"NomDistrito":"COROSHA","CodProvincia":2},
        {"CodDistrito":10,"NomDistrito":"CUISPES","CodProvincia":2},{"CodDistrito":11,"NomDistrito":"FLORIDA","CodProvincia":2},{"CodDistrito":12,"NomDistrito":"JAZAN","CodProvincia":2},
        {"CodDistrito":13,"NomDistrito":"JUMBILLA","CodProvincia":2},{"CodDistrito":14,"NomDistrito":"RECTA","CodProvincia":2},{"CodDistrito":15,"NomDistrito":"SAN CARLOS","CodProvincia":2},
        {"CodDistrito":16,"NomDistrito":"SHIPASBAMBA","CodProvincia":2},{"CodDistrito":17,"NomDistrito":"VALERA","CodProvincia":2},{"CodDistrito":18,"NomDistrito":"YAMBRASBAMBA","CodProvincia":2},
        {"CodDistrito":19,"NomDistrito":"ASUNCIÓN","CodProvincia":3},{"CodDistrito":20,"NomDistrito":"BALSAS","CodProvincia":3},{"CodDistrito":21,"NomDistrito":"CHACHAPOYAS","CodProvincia":3},
        {"CodDistrito":22,"NomDistrito":"CHETO","CodProvincia":3},{"CodDistrito":23,"NomDistrito":"CHILIQUIN","CodProvincia":3},{"CodDistrito":24,"NomDistrito":"CHUQUIBAMBA","CodProvincia":3},
        {"CodDistrito":25,"NomDistrito":"GRANADA","CodProvincia":3},{"CodDistrito":26,"NomDistrito":"HUANCAS","CodProvincia":3},{"CodDistrito":27,"NomDistrito":"LA JALCA","CodProvincia":3},
        {"CodDistrito":28,"NomDistrito":"LEIMEBAMBA","CodProvincia":3},{"CodDistrito":29,"NomDistrito":"LEVANTO","CodProvincia":3},{"CodDistrito":30,"NomDistrito":"MAGDALENA","CodProvincia":3},
        {"CodDistrito":31,"NomDistrito":"MARISCAL CASTILLA","CodProvincia":3},{"CodDistrito":32,"NomDistrito":"MOLINOPAMPA","CodProvincia":3},{"CodDistrito":33,"NomDistrito":"MONTEVIDEO","CodProvincia":3},
        {"CodDistrito":34,"NomDistrito":"OLLEROS","CodProvincia":3},{"CodDistrito":35,"NomDistrito":"QUINJALCA","CodProvincia":3},{"CodDistrito":36,"NomDistrito":"SAN FRANCISCO DE DAGUAS","CodProvincia":3},
        {"CodDistrito":37,"NomDistrito":"SAN ISIDRO DE MAINO","CodProvincia":3},{"CodDistrito":38,"NomDistrito":"SOLOCO","CodProvincia":3},{"CodDistrito":39,"NomDistrito":"SONCHE","CodProvincia":3},
        {"CodDistrito":40,"NomDistrito":"EL CENEPA","CodProvincia":4},{"CodDistrito":41,"NomDistrito":"NIEVA","CodProvincia":4},{"CodDistrito":42,"NomDistrito":"RÍO SANTIAGO","CodProvincia":4},
        {"CodDistrito":43,"NomDistrito":"CAMPORREDONDO","CodProvincia":5},{"CodDistrito":44,"NomDistrito":"COCABAMBA","CodProvincia":5},{"CodDistrito":45,"NomDistrito":"COLCAMAR","CodProvincia":5},
        {"CodDistrito":46,"NomDistrito":"CONILA","CodProvincia":5},{"CodDistrito":47,"NomDistrito":"INGUILPATA","CodProvincia":5},{"CodDistrito":48,"NomDistrito":"LAMUD","CodProvincia":5},
        {"CodDistrito":49,"NomDistrito":"LONGUITA","CodProvincia":5},{"CodDistrito":50,"NomDistrito":"LONYA CHICO","CodProvincia":5},{"CodDistrito":51,"NomDistrito":"LUYA","CodProvincia":5},
        {"CodDistrito":52,"NomDistrito":"LUYA VIEJO","CodProvincia":5},{"CodDistrito":53,"NomDistrito":"MARÍA","CodProvincia":5},{"CodDistrito":54,"NomDistrito":"OCALLI","CodProvincia":5},
        {"CodDistrito":55,"NomDistrito":"OCUMAL","CodProvincia":5},{"CodDistrito":56,"NomDistrito":"PISUQUIA","CodProvincia":5},{"CodDistrito":57,"NomDistrito":"PROVIDENCIA","CodProvincia":5},
        {"CodDistrito":58,"NomDistrito":"SAN CRISTÓBAL","CodProvincia":5},{"CodDistrito":59,"NomDistrito":"SAN FRANCISCO DEL YESO","CodProvincia":5},{"CodDistrito":60,"NomDistrito":"SAN JERÓNIMO","CodProvincia":5},
        {"CodDistrito":61,"NomDistrito":"SAN JUAN DE LOPECANCHA","CodProvincia":5},{"CodDistrito":62,"NomDistrito":"SANTA CATALINA","CodProvincia":5},{"CodDistrito":63,"NomDistrito":"SANTO TOMAS","CodProvincia":5},
        {"CodDistrito":64,"NomDistrito":"TINGO","CodProvincia":5},{"CodDistrito":65,"NomDistrito":"TRITA","CodProvincia":5},{"CodDistrito":66,"NomDistrito":"CHIRIMOTO","CodProvincia":6},{"CodDistrito":67,"NomDistrito":"COCHAMAL","CodProvincia":6},
        {"CodDistrito":68,"NomDistrito":"HUAMBO","CodProvincia":6},{"CodDistrito":69,"NomDistrito":"LIMABAMBA","CodProvincia":6},{"CodDistrito":70,"NomDistrito":"LONGAR","CodProvincia":6},{"CodDistrito":71,"NomDistrito":"MARISCAL BENAVIDES","CodProvincia":6},
        {"CodDistrito":72,"NomDistrito":"MILPUC","CodProvincia":6},{"CodDistrito":73,"NomDistrito":"OMIA","CodProvincia":6},{"CodDistrito":74,"NomDistrito":"SAN NICOLÁS","CodProvincia":6},{"CodDistrito":75,"NomDistrito":"SANTA ROSA","CodProvincia":6},
        {"CodDistrito":76,"NomDistrito":"TOTORA","CodProvincia":6},{"CodDistrito":77,"NomDistrito":"VISTA ALEGRE","CodProvincia":6},{"CodDistrito":78,"NomDistrito":"BAGUA GRANDE","CodProvincia":7},{"CodDistrito":79,"NomDistrito":"CAJARURO","CodProvincia":7},
        {"CodDistrito":80,"NomDistrito":"CUMBA","CodProvincia":7},{"CodDistrito":81,"NomDistrito":"EL MILAGRO","CodProvincia":7},{"CodDistrito":82,"NomDistrito":"JAMALCA","CodProvincia":7},{"CodDistrito":83,"NomDistrito":"LONYA GRANDE","CodProvincia":7},
        {"CodDistrito":84,"NomDistrito":"YAMON","CodProvincia":7},{"CodDistrito":85,"NomDistrito":"AIJA","CodProvincia":8},{"CodDistrito":86,"NomDistrito":"CORIS","CodProvincia":8},{"CodDistrito":87,"NomDistrito":"HUACLLAN","CodProvincia":8},
        {"CodDistrito":88,"NomDistrito":"LA MERCED","CodProvincia":8},{"CodDistrito":89,"NomDistrito":"SUCCHA","CodProvincia":8},{"CodDistrito":90,"NomDistrito":"ACZO","CodProvincia":9},{"CodDistrito":91,"NomDistrito":"CHACCHO","CodProvincia":9},
        {"CodDistrito":92,"NomDistrito":"CHINGAS","CodProvincia":9},{"CodDistrito":93,"NomDistrito":"LLAMELLIN","CodProvincia":9},{"CodDistrito":94,"NomDistrito":"MIRGAS","CodProvincia":9},{"CodDistrito":95,"NomDistrito":"SAN JUAN DE RONTOY","CodProvincia":9},
        {"CodDistrito":96,"NomDistrito":"ACOCHACA","CodProvincia":10},{"CodDistrito":97,"NomDistrito":"CHACAS","CodProvincia":10},{"CodDistrito":98,"NomDistrito":"ABELARDO PARDO LEZAMETA","CodProvincia":11},
        {"CodDistrito":99,"NomDistrito":"ANTONIO RAYMONDI","CodProvincia":11},{"CodDistrito":100,"NomDistrito":"AQUIA","CodProvincia":11},{"CodDistrito":101,"NomDistrito":"CAJACAY","CodProvincia":11},
        {"CodDistrito":102,"NomDistrito":"CANIS","CodProvincia":11},{"CodDistrito":103,"NomDistrito":"CHIQUIAN","CodProvincia":11},{"CodDistrito":104,"NomDistrito":"COLQUIOC","CodProvincia":11},{"CodDistrito":105,"NomDistrito":"HUALLANCA","CodProvincia":11},
        {"CodDistrito":106,"NomDistrito":"HUASTA","CodProvincia":11},{"CodDistrito":107,"NomDistrito":"HUAYLLACAYAN","CodProvincia":11},{"CodDistrito":108,"NomDistrito":"LA PRIMAVERA","CodProvincia":11},{"CodDistrito":109,"NomDistrito":"MANGAS","CodProvincia":11},
        {"CodDistrito":110,"NomDistrito":"PACLLON","CodProvincia":11},{"CodDistrito":111,"NomDistrito":"SAN MIGUEL DE CORPANQUI","CodProvincia":11},{"CodDistrito":112,"NomDistrito":"TICLLOS","CodProvincia":11},
        {"CodDistrito":113,"NomDistrito":"ACOPAMPA","CodProvincia":12},{"CodDistrito":114,"NomDistrito":"AMASHCA","CodProvincia":12},{"CodDistrito":115,"NomDistrito":"ANTA","CodProvincia":12},{"CodDistrito":116,"NomDistrito":"ATAQUERO","CodProvincia":12},
        {"CodDistrito":117,"NomDistrito":"CARHUAZ","CodProvincia":12},{"CodDistrito":118,"NomDistrito":"MARCARA","CodProvincia":12},{"CodDistrito":119,"NomDistrito":"PARIAHUANCA","CodProvincia":12},
        {"CodDistrito":120,"NomDistrito":"SAN MIGUEL DE ACO","CodProvincia":12},{"CodDistrito":121,"NomDistrito":"SHILLA","CodProvincia":12},{"CodDistrito":122,"NomDistrito":"TINCO","CodProvincia":12},{"CodDistrito":123,"NomDistrito":"YUNGAR","CodProvincia":12},
        {"CodDistrito":124,"NomDistrito":"SAN LUIS","CodProvincia":13},{"CodDistrito":125,"NomDistrito":"SAN NICOLÁS","CodProvincia":13},{"CodDistrito":126,"NomDistrito":"YAUYA","CodProvincia":13},
        {"CodDistrito":127,"NomDistrito":"BUENA VISTA ALTA","CodProvincia":14},{"CodDistrito":128,"NomDistrito":"CASMA","CodProvincia":14},{"CodDistrito":129,"NomDistrito":"COMANDANTE NOEL","CodProvincia":14},
        {"CodDistrito":130,"NomDistrito":"YAUTAN","CodProvincia":14},{"CodDistrito":131,"NomDistrito":"ACO","CodProvincia":15},{"CodDistrito":132,"NomDistrito":"BAMBAS","CodProvincia":15},{"CodDistrito":133,"NomDistrito":"CORONGO","CodProvincia":15},
        {"CodDistrito":134,"NomDistrito":"CUSCA","CodProvincia":15},{"CodDistrito":135,"NomDistrito":"LA PAMPA","CodProvincia":15},{"CodDistrito":136,"NomDistrito":"YANAC","CodProvincia":15},{"CodDistrito":137,"NomDistrito":"YUPAN","CodProvincia":15},
        {"CodDistrito":138,"NomDistrito":"COCHABAMBA","CodProvincia":16},{"CodDistrito":139,"NomDistrito":"COLCABAMBA","CodProvincia":16},{"CodDistrito":140,"NomDistrito":"HUANCHAY","CodProvincia":16},{"CodDistrito":141,"NomDistrito":"HUARAZ","CodProvincia":16},
        {"CodDistrito":142,"NomDistrito":"INDEPENDENCIA","CodProvincia":16},{"CodDistrito":143,"NomDistrito":"JANGAS","CodProvincia":16},{"CodDistrito":144,"NomDistrito":"LA LIBERTAD","CodProvincia":16},{"CodDistrito":145,"NomDistrito":"OLLEROS","CodProvincia":16},
        {"CodDistrito":146,"NomDistrito":"PAMPAS GRANDE","CodProvincia":16},{"CodDistrito":147,"NomDistrito":"PARIACOTO","CodProvincia":16},{"CodDistrito":148,"NomDistrito":"PIRA","CodProvincia":16},{"CodDistrito":149,"NomDistrito":"TARICA","CodProvincia":16},
        {"CodDistrito":150,"NomDistrito":"ANRA","CodProvincia":17},{"CodDistrito":151,"NomDistrito":"CAJAY","CodProvincia":17},{"CodDistrito":152,"NomDistrito":"CHAVIN DE HUANTAR","CodProvincia":17},{"CodDistrito":153,"NomDistrito":"HUACACHI","CodProvincia":17},
        {"CodDistrito":154,"NomDistrito":"HUACCHIS","CodProvincia":17},{"CodDistrito":155,"NomDistrito":"HUACHIS","CodProvincia":17},{"CodDistrito":156,"NomDistrito":"HUANTAR","CodProvincia":17},{"CodDistrito":157,"NomDistrito":"HUARI","CodProvincia":17},
        {"CodDistrito":158,"NomDistrito":"MASIN","CodProvincia":17},{"CodDistrito":159,"NomDistrito":"PAUCAS","CodProvincia":17},{"CodDistrito":160,"NomDistrito":"PONTO","CodProvincia":17},{"CodDistrito":161,"NomDistrito":"RAHUAPAMPA","CodProvincia":17},
        {"CodDistrito":162,"NomDistrito":"RAPAYAN","CodProvincia":17},{"CodDistrito":163,"NomDistrito":"SAN MARCOS","CodProvincia":17},{"CodDistrito":164,"NomDistrito":"SAN PEDRO DE CHANA","CodProvincia":17},{"CodDistrito":165,"NomDistrito":"UCO","CodProvincia":17},
        {"CodDistrito":166,"NomDistrito":"COCHAPETI","CodProvincia":18},{"CodDistrito":167,"NomDistrito":"CULEBRAS","CodProvincia":18},{"CodDistrito":168,"NomDistrito":"HUARMEY","CodProvincia":18},{"CodDistrito":169,"NomDistrito":"HUAYAN","CodProvincia":18},
        {"CodDistrito":170,"NomDistrito":"MALVAS","CodProvincia":18},{"CodDistrito":171,"NomDistrito":"CARAZ","CodProvincia":19},{"CodDistrito":172,"NomDistrito":"HUALLANCA","CodProvincia":19},{"CodDistrito":173,"NomDistrito":"HUATA","CodProvincia":19},
        {"CodDistrito":174,"NomDistrito":"HUAYLAS","CodProvincia":19},{"CodDistrito":175,"NomDistrito":"MATO","CodProvincia":19},{"CodDistrito":176,"NomDistrito":"PAMPAROMAS","CodProvincia":19},{"CodDistrito":177,"NomDistrito":"PUEBLO LIBRE","CodProvincia":19},
        {"CodDistrito":178,"NomDistrito":"SANTA CRUZ","CodProvincia":19},{"CodDistrito":179,"NomDistrito":"SANTO TORIBIO","CodProvincia":19},{"CodDistrito":180,"NomDistrito":"YURACMARCA","CodProvincia":19},{"CodDistrito":181,"NomDistrito":"CASCA","CodProvincia":20},
        {"CodDistrito":182,"NomDistrito":"ELEAZAR GUZMÁN BARRÓN","CodProvincia":20},{"CodDistrito":183,"NomDistrito":"FIDEL OLIVAS ESCUDERO","CodProvincia":20},{"CodDistrito":184,"NomDistrito":"LLAMA","CodProvincia":20},
        {"CodDistrito":185,"NomDistrito":"LLUMPA","CodProvincia":20},{"CodDistrito":186,"NomDistrito":"LUCMA","CodProvincia":20},{"CodDistrito":187,"NomDistrito":"MUSGA","CodProvincia":20},{"CodDistrito":188,"NomDistrito":"PISCOBAMBA","CodProvincia":20},
        {"CodDistrito":189,"NomDistrito":"ACAS","CodProvincia":21},{"CodDistrito":190,"NomDistrito":"CAJAMARQUILLA","CodProvincia":21},{"CodDistrito":191,"NomDistrito":"CARHUAPAMPA","CodProvincia":21},{"CodDistrito":192,"NomDistrito":"COCHAS","CodProvincia":21},
        {"CodDistrito":193,"NomDistrito":"CONGAS","CodProvincia":21},{"CodDistrito":194,"NomDistrito":"LLIPA","CodProvincia":21},{"CodDistrito":195,"NomDistrito":"OCROS","CodProvincia":21},{"CodDistrito":196,"NomDistrito":"SAN CRISTÓBAL DE RAJÁN","CodProvincia":21},
        {"CodDistrito":197,"NomDistrito":"SAN PEDRO","CodProvincia":21},{"CodDistrito":198,"NomDistrito":"SANTIAGO DE CHILCAS","CodProvincia":21},{"CodDistrito":199,"NomDistrito":"BOLOGNESI","CodProvincia":22},
        {"CodDistrito":200,"NomDistrito":"CABANA","CodProvincia":22},{"CodDistrito":201,"NomDistrito":"CONCHUCOS","CodProvincia":22},{"CodDistrito":202,"NomDistrito":"HUACASCHUQUE","CodProvincia":22},{"CodDistrito":203,"NomDistrito":"HUANDOVAL","CodProvincia":22},
        {"CodDistrito":204,"NomDistrito":"LACABAMBA","CodProvincia":22},{"CodDistrito":205,"NomDistrito":"LLAPO","CodProvincia":22},{"CodDistrito":206,"NomDistrito":"PALLASCA","CodProvincia":22},{"CodDistrito":207,"NomDistrito":"PAMPAS","CodProvincia":22},
        {"CodDistrito":208,"NomDistrito":"SANTA ROSA","CodProvincia":22},{"CodDistrito":209,"NomDistrito":"TAUCA","CodProvincia":22},{"CodDistrito":210,"NomDistrito":"HUAYLLAN","CodProvincia":23},{"CodDistrito":211,"NomDistrito":"PAROBAMBA","CodProvincia":23},
        {"CodDistrito":212,"NomDistrito":"POMABAMBA","CodProvincia":23},{"CodDistrito":213,"NomDistrito":"QUINUABAMBA","CodProvincia":23},{"CodDistrito":214,"NomDistrito":"CATAC","CodProvincia":24},{"CodDistrito":215,"NomDistrito":"COTAPARACO","CodProvincia":24},
        {"CodDistrito":216,"NomDistrito":"HUAYLLAPAMPA","CodProvincia":24},{"CodDistrito":217,"NomDistrito":"LLACLLIN","CodProvincia":24},{"CodDistrito":218,"NomDistrito":"MARCA","CodProvincia":24},{"CodDistrito":219,"NomDistrito":"PAMPAS CHICO","CodProvincia":24},
        {"CodDistrito":220,"NomDistrito":"PARARIN","CodProvincia":24},{"CodDistrito":221,"NomDistrito":"RECUAY","CodProvincia":24},{"CodDistrito":222,"NomDistrito":"TAPACOCHA","CodProvincia":24},{"CodDistrito":223,"NomDistrito":"TICAPAMPA","CodProvincia":24},
        {"CodDistrito":224,"NomDistrito":"CÁCERES DEL PERÚ","CodProvincia":25},{"CodDistrito":225,"NomDistrito":"CHIMBOTE","CodProvincia":25},{"CodDistrito":226,"NomDistrito":"COISHCO","CodProvincia":25},{"CodDistrito":227,"NomDistrito":"MACATE","CodProvincia":25},
        {"CodDistrito":228,"NomDistrito":"MORO","CodProvincia":25},{"CodDistrito":229,"NomDistrito":"NEPEÑA","CodProvincia":25},{"CodDistrito":230,"NomDistrito":"NUEVO CHIMBOTE","CodProvincia":25},{"CodDistrito":231,"NomDistrito":"SAMANCO","CodProvincia":25},
        {"CodDistrito":232,"NomDistrito":"SANTA","CodProvincia":25},{"CodDistrito":233,"NomDistrito":"ACOBAMBA","CodProvincia":26},{"CodDistrito":234,"NomDistrito":"ALFONSO UGARTE","CodProvincia":26},{"CodDistrito":235,"NomDistrito":"CASHAPAMPA","CodProvincia":26},
        {"CodDistrito":236,"NomDistrito":"CHINGALPO","CodProvincia":26},{"CodDistrito":237,"NomDistrito":"HUAYLLABAMBA","CodProvincia":26},{"CodDistrito":238,"NomDistrito":"QUICHES","CodProvincia":26},{"CodDistrito":239,"NomDistrito":"RAGASH","CodProvincia":26},
        {"CodDistrito":240,"NomDistrito":"SAN JUAN","CodProvincia":26},{"CodDistrito":241,"NomDistrito":"SICSIBAMBA","CodProvincia":26},{"CodDistrito":242,"NomDistrito":"SIHUAS","CodProvincia":26},{"CodDistrito":243,"NomDistrito":"CASCAPARA","CodProvincia":27},
        {"CodDistrito":244,"NomDistrito":"MANCOS","CodProvincia":27},{"CodDistrito":245,"NomDistrito":"MATACOTO","CodProvincia":27},{"CodDistrito":246,"NomDistrito":"QUILLO","CodProvincia":27},{"CodDistrito":247,"NomDistrito":"RANRAHIRCA","CodProvincia":27},
        {"CodDistrito":248,"NomDistrito":"SHUPLUY","CodProvincia":27},{"CodDistrito":249,"NomDistrito":"YANAMA","CodProvincia":27},{"CodDistrito":250,"NomDistrito":"YUNGAY","CodProvincia":27},{"CodDistrito":251,"NomDistrito":"ABANCAY","CodProvincia":28},
        {"CodDistrito":252,"NomDistrito":"CHACOCHE","CodProvincia":28},{"CodDistrito":253,"NomDistrito":"CIRCA","CodProvincia":28},{"CodDistrito":254,"NomDistrito":"CURAHUASI","CodProvincia":28},{"CodDistrito":255,"NomDistrito":"HUANIPACA","CodProvincia":28},
        {"CodDistrito":256,"NomDistrito":"LAMBRAMA","CodProvincia":28},{"CodDistrito":257,"NomDistrito":"PICHIRHUA","CodProvincia":28},{"CodDistrito":258,"NomDistrito":"SAN PEDRO DE CACHORA","CodProvincia":28},
        {"CodDistrito":259,"NomDistrito":"TAMBURCO","CodProvincia":28},{"CodDistrito":260,"NomDistrito":"ANDAHUAYLAS","CodProvincia":29},{"CodDistrito":261,"NomDistrito":"ANDARAPA","CodProvincia":29},{"CodDistrito":262,"NomDistrito":"CHIARA","CodProvincia":29},
        {"CodDistrito":263,"NomDistrito":"HUANCARAMA","CodProvincia":29},{"CodDistrito":264,"NomDistrito":"HUANCARAY","CodProvincia":29},{"CodDistrito":265,"NomDistrito":"HUAYANA","CodProvincia":29},
        {"CodDistrito":266,"NomDistrito":"JOSÉ MARÍA ARGUEDAS","CodProvincia":29},{"CodDistrito":267,"NomDistrito":"KAQUIABAMBA","CodProvincia":29},{"CodDistrito":268,"NomDistrito":"KISHUARA","CodProvincia":29},
        {"CodDistrito":269,"NomDistrito":"PACOBAMBA","CodProvincia":29},{"CodDistrito":270,"NomDistrito":"PACUCHA","CodProvincia":29},{"CodDistrito":271,"NomDistrito":"PAMPACHIRI","CodProvincia":29},{"CodDistrito":272,"NomDistrito":"POMACOCHA","CodProvincia":29},
        {"CodDistrito":273,"NomDistrito":"SAN ANTONIO DE CACHI","CodProvincia":29},{"CodDistrito":274,"NomDistrito":"SAN JERONIMO","CodProvincia":29},{"CodDistrito":275,"NomDistrito":"SAN MIGUEL DE CHACCRAMPA","CodProvincia":29},
        {"CodDistrito":276,"NomDistrito":"SANTA MARÍA DE CHICMO","CodProvincia":29},{"CodDistrito":277,"NomDistrito":"TALAVERA","CodProvincia":29},{"CodDistrito":278,"NomDistrito":"TUMAY HUARACA","CodProvincia":29},
        {"CodDistrito":279,"NomDistrito":"TURPO","CodProvincia":29},{"CodDistrito":280,"NomDistrito":"ANTABAMBA","CodProvincia":30},{"CodDistrito":281,"NomDistrito":"EL ORO","CodProvincia":30},{"CodDistrito":282,"NomDistrito":"HUAQUIRCA","CodProvincia":30},
        {"CodDistrito":283,"NomDistrito":"JUAN ESPINOZA MEDRANO","CodProvincia":30},{"CodDistrito":284,"NomDistrito":"OROPESA","CodProvincia":30},{"CodDistrito":285,"NomDistrito":"PACHACONAS","CodProvincia":30},
        {"CodDistrito":286,"NomDistrito":"SABAINO","CodProvincia":30},{"CodDistrito":287,"NomDistrito":"CAPAYA","CodProvincia":31},{"CodDistrito":288,"NomDistrito":"CARAYBAMBA","CodProvincia":31},{"CodDistrito":289,"NomDistrito":"CHALHUANCA","CodProvincia":31},
        {"CodDistrito":290,"NomDistrito":"CHAPIMARCA","CodProvincia":31},{"CodDistrito":291,"NomDistrito":"COLCABAMBA","CodProvincia":31},{"CodDistrito":292,"NomDistrito":"COTARUSE","CodProvincia":31},{"CodDistrito":293,"NomDistrito":"HUAYLLO","CodProvincia":31},
        {"CodDistrito":294,"NomDistrito":"JUSTO APU SAHUARAURA","CodProvincia":31},{"CodDistrito":295,"NomDistrito":"LUCRE","CodProvincia":31},{"CodDistrito":296,"NomDistrito":"POCOHUANCA","CodProvincia":31},
        {"CodDistrito":297,"NomDistrito":"SAÑAYCA","CodProvincia":31},{"CodDistrito":298,"NomDistrito":"SAN JUAN DE CHACÑA","CodProvincia":31},{"CodDistrito":299,"NomDistrito":"SORAYA","CodProvincia":31},
        {"CodDistrito":300,"NomDistrito":"TAPAIRIHUA","CodProvincia":31},{"CodDistrito":301,"NomDistrito":"TINTAY","CodProvincia":31},{"CodDistrito":302,"NomDistrito":"TORAYA","CodProvincia":31},{"CodDistrito":303,"NomDistrito":"YANACA","CodProvincia":31},
        {"CodDistrito":304,"NomDistrito":"ANCO_HUALLO","CodProvincia":32},{"CodDistrito":305,"NomDistrito":"CHINCHEROS","CodProvincia":32},{"CodDistrito":306,"NomDistrito":"COCHARCAS","CodProvincia":32},
        {"CodDistrito":307,"NomDistrito":"EL PORVENIR","CodProvincia":32},{"CodDistrito":308,"NomDistrito":"HUACCANA","CodProvincia":32},{"CodDistrito":309,"NomDistrito":"OCOBAMBA","CodProvincia":32},{"CodDistrito":310,"NomDistrito":"ONGOY","CodProvincia":32},
        {"CodDistrito":311,"NomDistrito":"RANRACANCHA","CodProvincia":32},{"CodDistrito":312,"NomDistrito":"ROCCHACC","CodProvincia":32},{"CodDistrito":313,"NomDistrito":"URANMARCA","CodProvincia":32},
        {"CodDistrito":314,"NomDistrito":"CHALLHUAHUACHO","CodProvincia":33},{"CodDistrito":315,"NomDistrito":"COTABAMBAS","CodProvincia":33},{"CodDistrito":316,"NomDistrito":"COYLLURQUI","CodProvincia":33},
        {"CodDistrito":317,"NomDistrito":"HAQUIRA","CodProvincia":33},{"CodDistrito":318,"NomDistrito":"MARA","CodProvincia":33},{"CodDistrito":319,"NomDistrito":"TAMBOBAMBA","CodProvincia":33},{"CodDistrito":320,"NomDistrito":"CHUQUIBAMBILLA","CodProvincia":34},
        {"CodDistrito":321,"NomDistrito":"CURASCO","CodProvincia":34},{"CodDistrito":322,"NomDistrito":"CURPAHUASI","CodProvincia":34},{"CodDistrito":323,"NomDistrito":"GAMARRA","CodProvincia":34},{"CodDistrito":324,"NomDistrito":"HUAYLLATI","CodProvincia":34},
        {"CodDistrito":325,"NomDistrito":"MAMARA","CodProvincia":34},{"CodDistrito":326,"NomDistrito":"MICAELA BASTIDAS","CodProvincia":34},{"CodDistrito":327,"NomDistrito":"PATAYPAMPA","CodProvincia":34},
        {"CodDistrito":328,"NomDistrito":"PROGRESO","CodProvincia":34},{"CodDistrito":329,"NomDistrito":"SAN ANTONIO","CodProvincia":34},{"CodDistrito":330,"NomDistrito":"SANTA ROSA","CodProvincia":34},{"CodDistrito":331,"NomDistrito":"TURPAY","CodProvincia":34},
        {"CodDistrito":332,"NomDistrito":"VILCABAMBA","CodProvincia":34},{"CodDistrito":333,"NomDistrito":"VIRUNDO","CodProvincia":34},{"CodDistrito":334,"NomDistrito":"ALTO SELVA ALEGRE","CodProvincia":35},
        {"CodDistrito":335,"NomDistrito":"AREQUIPA","CodProvincia":35},{"CodDistrito":336,"NomDistrito":"CAYMA","CodProvincia":35},{"CodDistrito":337,"NomDistrito":"CERRO COLORADO","CodProvincia":35},{"CodDistrito":338,"NomDistrito":"CHARACATO","CodProvincia":35},
        {"CodDistrito":339,"NomDistrito":"CHIGUATA","CodProvincia":35},{"CodDistrito":340,"NomDistrito":"JACOBO HUNTER","CodProvincia":35},{"CodDistrito":341,"NomDistrito":"JOSÉ LUIS BUSTAMANTE Y RIVERO","CodProvincia":35},
        {"CodDistrito":342,"NomDistrito":"LA JOYA","CodProvincia":35},{"CodDistrito":343,"NomDistrito":"MARIANO MELGAR","CodProvincia":35},{"CodDistrito":344,"NomDistrito":"MIRAFLORES","CodProvincia":35},
        {"CodDistrito":345,"NomDistrito":"MOLLEBAYA","CodProvincia":35},{"CodDistrito":346,"NomDistrito":"PAUCARPATA","CodProvincia":35},{"CodDistrito":347,"NomDistrito":"POCSI","CodProvincia":35},{"CodDistrito":348,"NomDistrito":"POLOBAYA","CodProvincia":35},
        {"CodDistrito":349,"NomDistrito":"QUEQUEÑA","CodProvincia":35},{"CodDistrito":350,"NomDistrito":"SABANDIA","CodProvincia":35},{"CodDistrito":351,"NomDistrito":"SACHACA","CodProvincia":35},
        {"CodDistrito":352,"NomDistrito":"SAN JUAN DE SIGUAS","CodProvincia":35},{"CodDistrito":353,"NomDistrito":"SAN JUAN DE TARUCANI","CodProvincia":35},{"CodDistrito":354,"NomDistrito":"SANTA ISABEL DE SIGUAS","CodProvincia":35},
        {"CodDistrito":355,"NomDistrito":"SANTA RITA DE SIGUAS","CodProvincia":35},{"CodDistrito":356,"NomDistrito":"SOCABAYA","CodProvincia":35},{"CodDistrito":357,"NomDistrito":"TIABAYA","CodProvincia":35},
        {"CodDistrito":358,"NomDistrito":"UCHUMAYO","CodProvincia":35},{"CodDistrito":359,"NomDistrito":"VITOR","CodProvincia":35},{"CodDistrito":360,"NomDistrito":"YANAHUARA","CodProvincia":35},{"CodDistrito":361,"NomDistrito":"YARABAMBA","CodProvincia":35},
        {"CodDistrito":362,"NomDistrito":"YURA","CodProvincia":35},{"CodDistrito":363,"NomDistrito":"CAMANÁ","CodProvincia":36},{"CodDistrito":364,"NomDistrito":"JOSÉ MARÍA QUIMPER","CodProvincia":36},
        {"CodDistrito":365,"NomDistrito":"MARIANO NICOLÁS VALCÁRCEL","CodProvincia":36},{"CodDistrito":366,"NomDistrito":"MARISCAL CÁCERES","CodProvincia":36},{"CodDistrito":367,"NomDistrito":"NICOLÁS DE PIEROLA","CodProvincia":36},
        {"CodDistrito":368,"NomDistrito":"OCOÑA","CodProvincia":36},{"CodDistrito":369,"NomDistrito":"QUILCA","CodProvincia":36},{"CodDistrito":370,"NomDistrito":"SAMUEL PASTOR","CodProvincia":36},{"CodDistrito":371,"NomDistrito":"ACARÍ","CodProvincia":37},
        {"CodDistrito":372,"NomDistrito":"ATICO","CodProvincia":37},{"CodDistrito":373,"NomDistrito":"ATIQUIPA","CodProvincia":37},{"CodDistrito":374,"NomDistrito":"BELLA UNION","CodProvincia":37},{"CodDistrito":375,"NomDistrito":"CAHUACHO","CodProvincia":37},
        {"CodDistrito":376,"NomDistrito":"CARAVELÍ","CodProvincia":37},{"CodDistrito":377,"NomDistrito":"CHALA","CodProvincia":37},{"CodDistrito":378,"NomDistrito":"CHAPARRA","CodProvincia":37},{"CodDistrito":379,"NomDistrito":"HUANUHUANU","CodProvincia":37},
        {"CodDistrito":380,"NomDistrito":"JAQUI","CodProvincia":37},{"CodDistrito":381,"NomDistrito":"LOMAS","CodProvincia":37},{"CodDistrito":382,"NomDistrito":"QUICACHA","CodProvincia":37},{"CodDistrito":383,"NomDistrito":"YAUCA","CodProvincia":37},
        {"CodDistrito":384,"NomDistrito":"ANDAGUA","CodProvincia":38},{"CodDistrito":385,"NomDistrito":"APLAO","CodProvincia":38},{"CodDistrito":386,"NomDistrito":"AYO","CodProvincia":38},{"CodDistrito":387,"NomDistrito":"CHACHAS","CodProvincia":38},
        {"CodDistrito":388,"NomDistrito":"CHILCAYMARCA","CodProvincia":38},{"CodDistrito":389,"NomDistrito":"CHOCO","CodProvincia":38},{"CodDistrito":390,"NomDistrito":"HUANCARQUI","CodProvincia":38},{"CodDistrito":391,"NomDistrito":"MACHAGUAY","CodProvincia":38},
        {"CodDistrito":392,"NomDistrito":"ORCOPAMPA","CodProvincia":38},{"CodDistrito":393,"NomDistrito":"PAMPACOLCA","CodProvincia":38},{"CodDistrito":394,"NomDistrito":"TIPAN","CodProvincia":38},{"CodDistrito":395,"NomDistrito":"UÑON","CodProvincia":38},
        {"CodDistrito":396,"NomDistrito":"URACA","CodProvincia":38},{"CodDistrito":397,"NomDistrito":"VIRACO","CodProvincia":38},{"CodDistrito":398,"NomDistrito":"ACHOMA","CodProvincia":39},{"CodDistrito":399,"NomDistrito":"CABANACONDE","CodProvincia":39},
        {"CodDistrito":400,"NomDistrito":"CALLALLI","CodProvincia":39},{"CodDistrito":401,"NomDistrito":"CAYLLOMA","CodProvincia":39},{"CodDistrito":402,"NomDistrito":"CHIVAY","CodProvincia":39},{"CodDistrito":403,"NomDistrito":"COPORAQUE","CodProvincia":39},
        {"CodDistrito":404,"NomDistrito":"HUAMBO","CodProvincia":39},{"CodDistrito":405,"NomDistrito":"HUANCA","CodProvincia":39},{"CodDistrito":406,"NomDistrito":"ICHUPAMPA","CodProvincia":39},{"CodDistrito":407,"NomDistrito":"LARI","CodProvincia":39},
        {"CodDistrito":408,"NomDistrito":"LLUTA","CodProvincia":39},{"CodDistrito":409,"NomDistrito":"MACA","CodProvincia":39},{"CodDistrito":410,"NomDistrito":"MADRIGAL","CodProvincia":39},{"CodDistrito":411,"NomDistrito":"MAJES","CodProvincia":39},
        {"CodDistrito":412,"NomDistrito":"SAN ANTONIO DE CHUCA","CodProvincia":39},{"CodDistrito":413,"NomDistrito":"SIBAYO","CodProvincia":39},{"CodDistrito":414,"NomDistrito":"TAPAY","CodProvincia":39},{"CodDistrito":415,"NomDistrito":"TISCO","CodProvincia":39},
        {"CodDistrito":416,"NomDistrito":"TUTI","CodProvincia":39},{"CodDistrito":417,"NomDistrito":"YANQUE","CodProvincia":39},{"CodDistrito":418,"NomDistrito":"ANDARAY","CodProvincia":40},{"CodDistrito":419,"NomDistrito":"CAYARANI","CodProvincia":40},
        {"CodDistrito":420,"NomDistrito":"CHICHAS","CodProvincia":40},{"CodDistrito":421,"NomDistrito":"CHUQUIBAMBA","CodProvincia":40},{"CodDistrito":422,"NomDistrito":"IRAY","CodProvincia":40},{"CodDistrito":423,"NomDistrito":"RÍO GRANDE","CodProvincia":40},
        {"CodDistrito":424,"NomDistrito":"SALAMANCA","CodProvincia":40},{"CodDistrito":425,"NomDistrito":"YANAQUIHUA","CodProvincia":40},{"CodDistrito":426,"NomDistrito":"COCACHACRA","CodProvincia":41},
        {"CodDistrito":427,"NomDistrito":"DEAN VALDIVIA","CodProvincia":41},{"CodDistrito":428,"NomDistrito":"ISLAY","CodProvincia":41},{"CodDistrito":429,"NomDistrito":"MEJIA","CodProvincia":41},
        {"CodDistrito":430,"NomDistrito":"MOLLENDO","CodProvincia":41},{"CodDistrito":431,"NomDistrito":"PUNTA DE BOMBÓN","CodProvincia":41},{"CodDistrito":432,"NomDistrito":"ALCA","CodProvincia":42},
        {"CodDistrito":433,"NomDistrito":"CHARCANA","CodProvincia":42},{"CodDistrito":434,"NomDistrito":"COTAHUASI","CodProvincia":42},{"CodDistrito":435,"NomDistrito":"HUAYNACOTAS","CodProvincia":42},
        {"CodDistrito":436,"NomDistrito":"PAMPAMARCA","CodProvincia":42},{"CodDistrito":437,"NomDistrito":"PUYCA","CodProvincia":42},{"CodDistrito":438,"NomDistrito":"QUECHUALLA","CodProvincia":42},{"CodDistrito":439,"NomDistrito":"SAYLA","CodProvincia":42},
        {"CodDistrito":440,"NomDistrito":"TAURIA","CodProvincia":42},{"CodDistrito":441,"NomDistrito":"TOMEPAMPA","CodProvincia":42},{"CodDistrito":442,"NomDistrito":"TORO","CodProvincia":42},{"CodDistrito":443,"NomDistrito":"CANGALLO","CodProvincia":43},
        {"CodDistrito":444,"NomDistrito":"CHUSCHI","CodProvincia":43},{"CodDistrito":445,"NomDistrito":"LOS MOROCHUCOS","CodProvincia":43},{"CodDistrito":446,"NomDistrito":"MARÍA PARADO DE BELLIDO","CodProvincia":43},
        {"CodDistrito":447,"NomDistrito":"PARAS","CodProvincia":43},{"CodDistrito":448,"NomDistrito":"TOTOS","CodProvincia":43},{"CodDistrito":449,"NomDistrito":"ACOCRO","CodProvincia":44},{"CodDistrito":450,"NomDistrito":"ACOS VINCHOS","CodProvincia":44},
        {"CodDistrito":451,"NomDistrito":"ANDRES AVELINO CACERES DORREGARAY","CodProvincia":44},{"CodDistrito":452,"NomDistrito":"AYACUCHO","CodProvincia":44},{"CodDistrito":453,"NomDistrito":"CARMEN ALTO","CodProvincia":44},
        {"CodDistrito":454,"NomDistrito":"CHIARA","CodProvincia":44},{"CodDistrito":455,"NomDistrito":"JESÚS NAZARENO","CodProvincia":44},{"CodDistrito":456,"NomDistrito":"OCROS","CodProvincia":44},{"CodDistrito":457,"NomDistrito":"PACAYCASA","CodProvincia":44},
        {"CodDistrito":458,"NomDistrito":"QUINUA","CodProvincia":44},{"CodDistrito":459,"NomDistrito":"SAN JOSÉ DE TICLLAS","CodProvincia":44},{"CodDistrito":460,"NomDistrito":"SAN JUAN BAUTISTA","CodProvincia":44},
        {"CodDistrito":461,"NomDistrito":"SANTIAGO DE PISCHA","CodProvincia":44},{"CodDistrito":462,"NomDistrito":"SOCOS","CodProvincia":44},{"CodDistrito":463,"NomDistrito":"TAMBILLO","CodProvincia":44},{"CodDistrito":464,"NomDistrito":"VINCHOS","CodProvincia":44},
        {"CodDistrito":465,"NomDistrito":"CARAPO","CodProvincia":45},{"CodDistrito":466,"NomDistrito":"SACSAMARCA","CodProvincia":45},{"CodDistrito":467,"NomDistrito":"SANCOS","CodProvincia":45},
        {"CodDistrito":468,"NomDistrito":"SANTIAGO DE LUCANAMARCA","CodProvincia":45},{"CodDistrito":469,"NomDistrito":"AYAHUANCO","CodProvincia":46},{"CodDistrito":470,"NomDistrito":"CANAYRE","CodProvincia":46},
        {"CodDistrito":471,"NomDistrito":"CHACA","CodProvincia":46},{"CodDistrito":472,"NomDistrito":"HUAMANGUILLA","CodProvincia":46},{"CodDistrito":473,"NomDistrito":"HUANTA","CodProvincia":46},{"CodDistrito":474,"NomDistrito":"IGUAIN","CodProvincia":46},
        {"CodDistrito":475,"NomDistrito":"LLOCHEGUA","CodProvincia":46},{"CodDistrito":476,"NomDistrito":"LURICOCHA","CodProvincia":46},{"CodDistrito":477,"NomDistrito":"PUCACOLPA","CodProvincia":46},{"CodDistrito":478,"NomDistrito":"SANTILLANA","CodProvincia":46},
        {"CodDistrito":479,"NomDistrito":"SIVIA","CodProvincia":46},{"CodDistrito":480,"NomDistrito":"UCHURACCAY","CodProvincia":46},{"CodDistrito":481,"NomDistrito":"ANCHIHUAY","CodProvincia":47},{"CodDistrito":482,"NomDistrito":"ANCO","CodProvincia":47},
        {"CodDistrito":483,"NomDistrito":"AYNA","CodProvincia":47},{"CodDistrito":484,"NomDistrito":"CHILCAS","CodProvincia":47},{"CodDistrito":485,"NomDistrito":"CHUNGUI","CodProvincia":47},{"CodDistrito":486,"NomDistrito":"LUIS CARRANZA","CodProvincia":47},
        {"CodDistrito":487,"NomDistrito":"SAMUGARI","CodProvincia":47},{"CodDistrito":488,"NomDistrito":"SAN MIGUEL","CodProvincia":47},{"CodDistrito":489,"NomDistrito":"SANTA ROSA","CodProvincia":47},{"CodDistrito":490,"NomDistrito":"TAMBO","CodProvincia":47},
        {"CodDistrito":491,"NomDistrito":"AUCARA","CodProvincia":48},{"CodDistrito":492,"NomDistrito":"CABANA","CodProvincia":48},{"CodDistrito":493,"NomDistrito":"CARMEN SALCEDO","CodProvincia":48},{"CodDistrito":494,"NomDistrito":"CHAVIÑA","CodProvincia":48},
        {"CodDistrito":495,"NomDistrito":"CHIPAO","CodProvincia":48},{"CodDistrito":496,"NomDistrito":"HUAC-HUAS","CodProvincia":48},{"CodDistrito":497,"NomDistrito":"LARAMATE","CodProvincia":48},{"CodDistrito":498,"NomDistrito":"LEONCIO PRADO","CodProvincia":48},
        {"CodDistrito":499,"NomDistrito":"LLAUTA","CodProvincia":48},{"CodDistrito":500,"NomDistrito":"LUCANAS","CodProvincia":48},{"CodDistrito":501,"NomDistrito":"OCAÑA","CodProvincia":48},{"CodDistrito":502,"NomDistrito":"OTOCA","CodProvincia":48},
        {"CodDistrito":503,"NomDistrito":"PUQUIO","CodProvincia":48},{"CodDistrito":504,"NomDistrito":"SAISA","CodProvincia":48},{"CodDistrito":505,"NomDistrito":"SANCOS","CodProvincia":48},{"CodDistrito":506,"NomDistrito":"SAN CRISTÓBAL","CodProvincia":48},
        {"CodDistrito":507,"NomDistrito":"SAN JUAN","CodProvincia":48},{"CodDistrito":508,"NomDistrito":"SAN PEDRO","CodProvincia":48},{"CodDistrito":509,"NomDistrito":"SAN PEDRO DE PALCO","CodProvincia":48},
        {"CodDistrito":510,"NomDistrito":"SANTA ANA DE HUAYCAHUACHO","CodProvincia":48},{"CodDistrito":511,"NomDistrito":"SANTA LUCIA","CodProvincia":48},{"CodDistrito":512,"NomDistrito":"CHUMPI","CodProvincia":49},
        {"CodDistrito":513,"NomDistrito":"CORACORA","CodProvincia":49},{"CodDistrito":514,"NomDistrito":"CORONEL CASTAÑEDA","CodProvincia":49},{"CodDistrito":515,"NomDistrito":"PACAPAUSA","CodProvincia":49},{"CodDistrito":516,"NomDistrito":"PULLO","CodProvincia":49},
        {"CodDistrito":517,"NomDistrito":"PUYUSCA","CodProvincia":49},{"CodDistrito":518,"NomDistrito":"SAN FRANCISCO DE RAVACAYCO","CodProvincia":49},{"CodDistrito":519,"NomDistrito":"UPAHUACHO","CodProvincia":49},
        {"CodDistrito":520,"NomDistrito":"COLTA","CodProvincia":50},{"CodDistrito":521,"NomDistrito":"CORCULLA","CodProvincia":50},{"CodDistrito":522,"NomDistrito":"LAMPA","CodProvincia":50},{"CodDistrito":523,"NomDistrito":"MARCABAMBA","CodProvincia":50},
        {"CodDistrito":524,"NomDistrito":"OYOLO","CodProvincia":50},{"CodDistrito":525,"NomDistrito":"PARARCA","CodProvincia":50},{"CodDistrito":526,"NomDistrito":"PAUSA","CodProvincia":50},
        {"CodDistrito":527,"NomDistrito":"SAN JAVIER DE ALPABAMBA","CodProvincia":50},{"CodDistrito":528,"NomDistrito":"SAN JOSE DE USHUA","CodProvincia":50},{"CodDistrito":529,"NomDistrito":"SARA SARA","CodProvincia":50},
        {"CodDistrito":530,"NomDistrito":"BELÉN","CodProvincia":51},{"CodDistrito":531,"NomDistrito":"CHALCOS","CodProvincia":51},{"CodDistrito":532,"NomDistrito":"CHILCAYOC","CodProvincia":51},{"CodDistrito":533,"NomDistrito":"HUACAÑA","CodProvincia":51},
        {"CodDistrito":534,"NomDistrito":"MORCOLLA","CodProvincia":51},{"CodDistrito":535,"NomDistrito":"PAICO","CodProvincia":51},{"CodDistrito":536,"NomDistrito":"QUEROBAMBA","CodProvincia":51},
        {"CodDistrito":537,"NomDistrito":"SAN PEDRO DE LARCAY","CodProvincia":51},{"CodDistrito":538,"NomDistrito":"SAN SALVADOR DE QUIJE","CodProvincia":51},{"CodDistrito":539,"NomDistrito":"SANTIAGO DE PAUCARAY","CodProvincia":51},
        {"CodDistrito":540,"NomDistrito":"SORAS","CodProvincia":51},{"CodDistrito":541,"NomDistrito":"ALCAMENCA","CodProvincia":52},{"CodDistrito":542,"NomDistrito":"APONGO","CodProvincia":52},{"CodDistrito":543,"NomDistrito":"ASQUIPATA","CodProvincia":52},
        {"CodDistrito":544,"NomDistrito":"CANARIA","CodProvincia":52},{"CodDistrito":545,"NomDistrito":"CAYARA","CodProvincia":52},{"CodDistrito":546,"NomDistrito":"COLCA","CodProvincia":52},{"CodDistrito":547,"NomDistrito":"HUAMANQUIQUIA","CodProvincia":52},
        {"CodDistrito":548,"NomDistrito":"HUANCAPI","CodProvincia":52},{"CodDistrito":549,"NomDistrito":"HUANCARAYLLA","CodProvincia":52},{"CodDistrito":550,"NomDistrito":"HUAYA","CodProvincia":52},{"CodDistrito":551,"NomDistrito":"SARHUA","CodProvincia":52},
        {"CodDistrito":552,"NomDistrito":"VILCANCHOS","CodProvincia":52},{"CodDistrito":553,"NomDistrito":"ACCOMARCA","CodProvincia":53},{"CodDistrito":554,"NomDistrito":"CARHUANCA","CodProvincia":53},
        {"CodDistrito":555,"NomDistrito":"CONCEPCION","CodProvincia":53},{"CodDistrito":556,"NomDistrito":"HUAMBALPA","CodProvincia":53},{"CodDistrito":557,"NomDistrito":"INDEPENDENCIA","CodProvincia":53},
        {"CodDistrito":558,"NomDistrito":"SAURAMA","CodProvincia":53},{"CodDistrito":559,"NomDistrito":"VILCAS HUAMAN","CodProvincia":53},{"CodDistrito":560,"NomDistrito":"VISCHONGO","CodProvincia":53},
        {"CodDistrito":561,"NomDistrito":"CACHACHI","CodProvincia":54},{"CodDistrito":562,"NomDistrito":"CAJABAMBA","CodProvincia":54},{"CodDistrito":563,"NomDistrito":"CONDEBAMBA","CodProvincia":54},{"CodDistrito":564,"NomDistrito":"SITACOCHA","CodProvincia":54},
        {"CodDistrito":565,"NomDistrito":"ASUNCION","CodProvincia":55},{"CodDistrito":566,"NomDistrito":"CAJAMARCA","CodProvincia":55},{"CodDistrito":567,"NomDistrito":"CHETILLA","CodProvincia":55},{"CodDistrito":568,"NomDistrito":"COSPAN","CodProvincia":55},
        {"CodDistrito":569,"NomDistrito":"ENCAÑADA","CodProvincia":55},{"CodDistrito":570,"NomDistrito":"JESUS","CodProvincia":55},{"CodDistrito":571,"NomDistrito":"LLACANORA","CodProvincia":55},
        {"CodDistrito":572,"NomDistrito":"LOS BAÑOS DEL INCA","CodProvincia":55},{"CodDistrito":573,"NomDistrito":"MAGDALENA","CodProvincia":55},{"CodDistrito":574,"NomDistrito":"MATARA","CodProvincia":55},
        {"CodDistrito":575,"NomDistrito":"NAMORA","CodProvincia":55},{"CodDistrito":576,"NomDistrito":"SAN JUAN","CodProvincia":55},{"CodDistrito":577,"NomDistrito":"CELENDÍN","CodProvincia":56},{"CodDistrito":578,"NomDistrito":"CHUMUCH","CodProvincia":56},
        {"CodDistrito":579,"NomDistrito":"CORTEGANA","CodProvincia":56},{"CodDistrito":580,"NomDistrito":"HUASMIN","CodProvincia":56},{"CodDistrito":581,"NomDistrito":"JORGE CHAVEZ","CodProvincia":56},
        {"CodDistrito":582,"NomDistrito":"JOSE GALVEZ","CodProvincia":56},{"CodDistrito":583,"NomDistrito":"LA LIBERTAD DE PALLAN","CodProvincia":56},{"CodDistrito":584,"NomDistrito":"MIGUEL IGLESIAS","CodProvincia":56},
        {"CodDistrito":585,"NomDistrito":"OXAMARCA","CodProvincia":56},{"CodDistrito":586,"NomDistrito":"SOROCHUCO","CodProvincia":56},{"CodDistrito":587,"NomDistrito":"SUCRE","CodProvincia":56},{"CodDistrito":588,"NomDistrito":"UTCO","CodProvincia":56},
        {"CodDistrito":589,"NomDistrito":"ANGUIA","CodProvincia":57},{"CodDistrito":590,"NomDistrito":"CHADIN","CodProvincia":57},{"CodDistrito":591,"NomDistrito":"CHALAMARCA","CodProvincia":57},{"CodDistrito":592,"NomDistrito":"CHIGUIRIP","CodProvincia":57},
        {"CodDistrito":593,"NomDistrito":"CHIMBAN","CodProvincia":57},{"CodDistrito":594,"NomDistrito":"CHOROPAMPA","CodProvincia":57},{"CodDistrito":595,"NomDistrito":"CHOTA","CodProvincia":57},{"CodDistrito":596,"NomDistrito":"COCHABAMBA","CodProvincia":57},
        {"CodDistrito":597,"NomDistrito":"CONCHAN","CodProvincia":57},{"CodDistrito":598,"NomDistrito":"HUAMBOS","CodProvincia":57},{"CodDistrito":599,"NomDistrito":"LAJAS","CodProvincia":57},{"CodDistrito":600,"NomDistrito":"LLAMA","CodProvincia":57},
        {"CodDistrito":601,"NomDistrito":"MIRACOSTA","CodProvincia":57},{"CodDistrito":602,"NomDistrito":"PACCHA","CodProvincia":57},{"CodDistrito":603,"NomDistrito":"PION","CodProvincia":57},{"CodDistrito":604,"NomDistrito":"QUEROCOTO","CodProvincia":57},
        {"CodDistrito":605,"NomDistrito":"SAN JUAN DE LICUPIS","CodProvincia":57},{"CodDistrito":606,"NomDistrito":"TACABAMBA","CodProvincia":57},{"CodDistrito":607,"NomDistrito":"TOCMOCHE","CodProvincia":57},
        {"CodDistrito":608,"NomDistrito":"CHILETE","CodProvincia":58},{"CodDistrito":609,"NomDistrito":"CONTUMAZA","CodProvincia":58},{"CodDistrito":610,"NomDistrito":"CUPISNIQUE","CodProvincia":58},{"CodDistrito":611,"NomDistrito":"GUZMANGO","CodProvincia":58},
        {"CodDistrito":612,"NomDistrito":"SAN BENITO","CodProvincia":58},{"CodDistrito":613,"NomDistrito":"SANTA CRUZ DE TOLEDO","CodProvincia":58},{"CodDistrito":614,"NomDistrito":"TANTARICA","CodProvincia":58},
        {"CodDistrito":615,"NomDistrito":"YONAN","CodProvincia":58},{"CodDistrito":616,"NomDistrito":"CALLAYUC","CodProvincia":59},{"CodDistrito":617,"NomDistrito":"CHOROS","CodProvincia":59},{"CodDistrito":618,"NomDistrito":"CUJILLO","CodProvincia":59},
        {"CodDistrito":619,"NomDistrito":"CUTERVO","CodProvincia":59},{"CodDistrito":620,"NomDistrito":"LA RAMADA","CodProvincia":59},{"CodDistrito":621,"NomDistrito":"PIMPINGOS","CodProvincia":59},
        {"CodDistrito":622,"NomDistrito":"QUEROCOTILLO","CodProvincia":59},{"CodDistrito":623,"NomDistrito":"SAN ANDRÉS DE CUTERVO","CodProvincia":59},{"CodDistrito":624,"NomDistrito":"SAN JUAN DE CUTERVO","CodProvincia":59},
        {"CodDistrito":625,"NomDistrito":"SAN LUIS DE LUCMA","CodProvincia":59},{"CodDistrito":626,"NomDistrito":"SANTA CRUZ","CodProvincia":59},{"CodDistrito":627,"NomDistrito":"SANTO DOMINGO DE LA CAPILLA","CodProvincia":59},
        {"CodDistrito":628,"NomDistrito":"SANTO TOMAS","CodProvincia":59},{"CodDistrito":629,"NomDistrito":"SOCOTA","CodProvincia":59},{"CodDistrito":630,"NomDistrito":"TORIBIO CASANOVA","CodProvincia":59},
        {"CodDistrito":631,"NomDistrito":"BAMBAMARCA","CodProvincia":60},{"CodDistrito":632,"NomDistrito":"CHUGUR","CodProvincia":60},{"CodDistrito":633,"NomDistrito":"HUALGAYOC","CodProvincia":60},
        {"CodDistrito":634,"NomDistrito":"BELLAVISTA","CodProvincia":61},{"CodDistrito":635,"NomDistrito":"CHONTALI","CodProvincia":61},{"CodDistrito":636,"NomDistrito":"COLASAY","CodProvincia":61},{"CodDistrito":637,"NomDistrito":"HUABAL","CodProvincia":61},
        {"CodDistrito":638,"NomDistrito":"JAEN","CodProvincia":61},{"CodDistrito":639,"NomDistrito":"LAS PIRIAS","CodProvincia":61},{"CodDistrito":640,"NomDistrito":"POMAHUACA","CodProvincia":61},{"CodDistrito":641,"NomDistrito":"PUCARA","CodProvincia":61},
        {"CodDistrito":642,"NomDistrito":"SALLIQUE","CodProvincia":61},{"CodDistrito":643,"NomDistrito":"SAN FELIPE","CodProvincia":61},{"CodDistrito":644,"NomDistrito":"SAN JOSÉ DEL ALTO","CodProvincia":61},
        {"CodDistrito":645,"NomDistrito":"SANTA ROSA","CodProvincia":61},{"CodDistrito":646,"NomDistrito":"CHIRINOS","CodProvincia":62},{"CodDistrito":647,"NomDistrito":"HUARANGO","CodProvincia":62},{"CodDistrito":648,"NomDistrito":"LA COIPA","CodProvincia":62},
        {"CodDistrito":649,"NomDistrito":"NAMBALLE","CodProvincia":62},{"CodDistrito":650,"NomDistrito":"SAN IGNACIO","CodProvincia":62},{"CodDistrito":651,"NomDistrito":"SAN JOSÉ DE LOURDES","CodProvincia":62},
        {"CodDistrito":652,"NomDistrito":"TABACONAS","CodProvincia":62},{"CodDistrito":653,"NomDistrito":"CHANCAY","CodProvincia":63},{"CodDistrito":654,"NomDistrito":"EDUARDO VILLANUEVA","CodProvincia":63},
        {"CodDistrito":655,"NomDistrito":"GREGORIO PITA","CodProvincia":63},{"CodDistrito":656,"NomDistrito":"ICHOCAN","CodProvincia":63},{"CodDistrito":657,"NomDistrito":"JOSÉ MANUEL QUIROZ","CodProvincia":63},
        {"CodDistrito":658,"NomDistrito":"JOSÉ SABOGAL","CodProvincia":63},{"CodDistrito":659,"NomDistrito":"PEDRO GÁLVEZ","CodProvincia":63},{"CodDistrito":660,"NomDistrito":"BOLIVAR","CodProvincia":64},
        {"CodDistrito":661,"NomDistrito":"CALQUIS","CodProvincia":64},{"CodDistrito":662,"NomDistrito":"CATILLUC","CodProvincia":64},{"CodDistrito":663,"NomDistrito":"EL PRADO","CodProvincia":64},
        {"CodDistrito":664,"NomDistrito":"LA FLORIDA","CodProvincia":64},{"CodDistrito":665,"NomDistrito":"LLAPA","CodProvincia":64},{"CodDistrito":666,"NomDistrito":"NANCHOC","CodProvincia":64},
        {"CodDistrito":667,"NomDistrito":"NIEPOS","CodProvincia":64},{"CodDistrito":668,"NomDistrito":"SAN GREGORIO","CodProvincia":64},{"CodDistrito":669,"NomDistrito":"SAN MIGUEL","CodProvincia":64},
        {"CodDistrito":670,"NomDistrito":"SAN SILVESTRE DE COCHAN","CodProvincia":64},{"CodDistrito":671,"NomDistrito":"TONGOD","CodProvincia":64},{"CodDistrito":672,"NomDistrito":"UNIÓN AGUA BLANCA","CodProvincia":64},
        {"CodDistrito":673,"NomDistrito":"SAN BERNARDINO","CodProvincia":65},{"CodDistrito":674,"NomDistrito":"SAN LUIS","CodProvincia":65},{"CodDistrito":675,"NomDistrito":"SAN PABLO","CodProvincia":65},
        {"CodDistrito":676,"NomDistrito":"TUMBADEN","CodProvincia":65},{"CodDistrito":677,"NomDistrito":"ANDABAMBA","CodProvincia":66},{"CodDistrito":678,"NomDistrito":"CATACHE","CodProvincia":66},{"CodDistrito":679,"NomDistrito":"CHANCAYBAÑOS","CodProvincia":66},
        {"CodDistrito":680,"NomDistrito":"LA ESPERANZA","CodProvincia":66},{"CodDistrito":681,"NomDistrito":"NINABAMBA","CodProvincia":66},{"CodDistrito":682,"NomDistrito":"PULAN","CodProvincia":66},{"CodDistrito":683,"NomDistrito":"SANTA CRUZ","CodProvincia":66},
        {"CodDistrito":684,"NomDistrito":"SAUCEPAMPA","CodProvincia":66},{"CodDistrito":685,"NomDistrito":"SEXI","CodProvincia":66},{"CodDistrito":686,"NomDistrito":"UTICYACU","CodProvincia":66},{"CodDistrito":687,"NomDistrito":"YAUYUCAN","CodProvincia":66},
        {"CodDistrito":688,"NomDistrito":"BELLAVISTA","CodProvincia":67},{"CodDistrito":689,"NomDistrito":"CALLAO","CodProvincia":67},{"CodDistrito":690,"NomDistrito":"CARMEN DE LA LEGUA REYNOSO","CodProvincia":67},{"CodDistrito":691,"NomDistrito":"LA PERLA","CodProvincia":67},
        {"CodDistrito":692,"NomDistrito":"LA PUNTA","CodProvincia":67},{"CodDistrito":693,"NomDistrito":"MI PERU","CodProvincia":67},{"CodDistrito":694,"NomDistrito":"VENTANILLA","CodProvincia":67},{"CodDistrito":695,"NomDistrito":"ACOMAYO","CodProvincia":68},
        {"CodDistrito":696,"NomDistrito":"ACOPIA","CodProvincia":68},{"CodDistrito":697,"NomDistrito":"ACOS","CodProvincia":68},{"CodDistrito":698,"NomDistrito":"MOSOC LLACTA","CodProvincia":68},{"CodDistrito":699,"NomDistrito":"POMACANCHI","CodProvincia":68},
        {"CodDistrito":700,"NomDistrito":"RONDOCAN","CodProvincia":68},{"CodDistrito":701,"NomDistrito":"SANGARARA","CodProvincia":68},{"CodDistrito":702,"NomDistrito":"ANCAHUASI","CodProvincia":69},{"CodDistrito":703,"NomDistrito":"ANTA","CodProvincia":69},
        {"CodDistrito":704,"NomDistrito":"CACHIMAYO","CodProvincia":69},{"CodDistrito":705,"NomDistrito":"CHINCHAYPUJIO","CodProvincia":69},{"CodDistrito":706,"NomDistrito":"HUAROCONDO","CodProvincia":69},{"CodDistrito":707,"NomDistrito":"LIMATAMBO","CodProvincia":69},
        {"CodDistrito":708,"NomDistrito":"MOLLEPATA","CodProvincia":69},{"CodDistrito":709,"NomDistrito":"PUCYURA","CodProvincia":69},{"CodDistrito":710,"NomDistrito":"ZURITE","CodProvincia":69},{"CodDistrito":711,"NomDistrito":"CALCA","CodProvincia":70},{"CodDistrito":712,"NomDistrito":"COYA","CodProvincia":70},
        {"CodDistrito":713,"NomDistrito":"LAMAY","CodProvincia":70},{"CodDistrito":714,"NomDistrito":"LARES","CodProvincia":70},{"CodDistrito":715,"NomDistrito":"PISAC","CodProvincia":70},{"CodDistrito":716,"NomDistrito":"SAN SALVADOR","CodProvincia":70},{"CodDistrito":717,"NomDistrito":"TARAY","CodProvincia":70},
        {"CodDistrito":718,"NomDistrito":"YANATILE","CodProvincia":70},{"CodDistrito":719,"NomDistrito":"CHECCA","CodProvincia":71},{"CodDistrito":720,"NomDistrito":"KUNTURKANKI","CodProvincia":71},{"CodDistrito":721,"NomDistrito":"LANGUI","CodProvincia":71},{"CodDistrito":722,"NomDistrito":"LAYO","CodProvincia":71},
        {"CodDistrito":723,"NomDistrito":"PAMPAMARCA","CodProvincia":71},{"CodDistrito":724,"NomDistrito":"QUEHUE","CodProvincia":71},{"CodDistrito":725,"NomDistrito":"TUPAC AMARU","CodProvincia":71},{"CodDistrito":726,"NomDistrito":"YANAOCA","CodProvincia":71},
        {"CodDistrito":727,"NomDistrito":"CHECACUPE","CodProvincia":72},{"CodDistrito":728,"NomDistrito":"COMBAPATA","CodProvincia":72},{"CodDistrito":729,"NomDistrito":"MARANGANI","CodProvincia":72},{"CodDistrito":730,"NomDistrito":"PITUMARCA","CodProvincia":72},
        {"CodDistrito":731,"NomDistrito":"SAN PABLO","CodProvincia":72},{"CodDistrito":732,"NomDistrito":"SAN PEDRO","CodProvincia":72},{"CodDistrito":733,"NomDistrito":"SICUANI","CodProvincia":72},{"CodDistrito":734,"NomDistrito":"TINTA","CodProvincia":72},
        {"CodDistrito":735,"NomDistrito":"CAPACMARCA","CodProvincia":73},{"CodDistrito":736,"NomDistrito":"CHAMACA","CodProvincia":73},{"CodDistrito":737,"NomDistrito":"COLQUEMARCA","CodProvincia":73},{"CodDistrito":738,"NomDistrito":"LIVITACA","CodProvincia":73},
        {"CodDistrito":739,"NomDistrito":"LLUSCO","CodProvincia":73},{"CodDistrito":740,"NomDistrito":"QUIÑOTA","CodProvincia":73},{"CodDistrito":741,"NomDistrito":"SANTO TOMAS","CodProvincia":73},{"CodDistrito":742,"NomDistrito":"VELILLE","CodProvincia":73},
        {"CodDistrito":743,"NomDistrito":"CCORCA","CodProvincia":74},{"CodDistrito":744,"NomDistrito":"CUSCO","CodProvincia":74},{"CodDistrito":745,"NomDistrito":"POROY","CodProvincia":74},{"CodDistrito":746,"NomDistrito":"SAN JERONIMO","CodProvincia":74},
        {"CodDistrito":747,"NomDistrito":"SAN SEBASTIAN","CodProvincia":74},{"CodDistrito":748,"NomDistrito":"SANTIAGO","CodProvincia":74},{"CodDistrito":749,"NomDistrito":"SAYLLA","CodProvincia":74},{"CodDistrito":750,"NomDistrito":"WANCHAQ","CodProvincia":74},
        {"CodDistrito":751,"NomDistrito":"ALTO PICHIGUA","CodProvincia":75},{"CodDistrito":752,"NomDistrito":"CONDOROMA","CodProvincia":75},{"CodDistrito":753,"NomDistrito":"COPORAQUE","CodProvincia":75},{"CodDistrito":754,"NomDistrito":"ESPINAR","CodProvincia":75},
        {"CodDistrito":755,"NomDistrito":"OCORURO","CodProvincia":75},{"CodDistrito":756,"NomDistrito":"PALLPATA","CodProvincia":75},{"CodDistrito":757,"NomDistrito":"PICHIGUA","CodProvincia":75},{"CodDistrito":758,"NomDistrito":"SUYCKUTAMBO","CodProvincia":75},
        {"CodDistrito":759,"NomDistrito":"ECHARATE","CodProvincia":76},{"CodDistrito":760,"NomDistrito":"HUAYOPATA","CodProvincia":76},{"CodDistrito":761,"NomDistrito":"INKAWASI","CodProvincia":76},{"CodDistrito":762,"NomDistrito":"KIMBIRI","CodProvincia":76},
        {"CodDistrito":763,"NomDistrito":"MARANURA","CodProvincia":76},{"CodDistrito":764,"NomDistrito":"OCOBAMBA","CodProvincia":76},{"CodDistrito":765,"NomDistrito":"PICHARI","CodProvincia":76},{"CodDistrito":766,"NomDistrito":"QUELLOUNO","CodProvincia":76},
        {"CodDistrito":767,"NomDistrito":"SANTA ANA","CodProvincia":76},
        {"CodDistrito":768,"NomDistrito":"SANTA TERESA","CodProvincia":76},
        {"CodDistrito":769,"NomDistrito":"VILCABAMBA","CodProvincia":76},
        {"CodDistrito":770,"NomDistrito":"VILLA KINTIARINA","CodProvincia":76},
        {"CodDistrito":771,"NomDistrito":"VILLA VIRGEN","CodProvincia":76},
        {"CodDistrito":772,"NomDistrito":"ACCHA","CodProvincia":77},
        {"CodDistrito":773,"NomDistrito":"CCAPI","CodProvincia":77},
        {"CodDistrito":774,"NomDistrito":"COLCHA","CodProvincia":77},
        {"CodDistrito":775,"NomDistrito":"HUANOQUITE","CodProvincia":77},
        {"CodDistrito":776,"NomDistrito":"OMACHA","CodProvincia":77},
        {"CodDistrito":777,"NomDistrito":"PACCARITAMBO","CodProvincia":77},
        {"CodDistrito":778,"NomDistrito":"PARURO","CodProvincia":77},
        {"CodDistrito":779,"NomDistrito":"PILLPINTO","CodProvincia":77},
        {"CodDistrito":780,"NomDistrito":"YAURISQUE","CodProvincia":77},
        {"CodDistrito":781,"NomDistrito":"CAICAY","CodProvincia":78},
        {"CodDistrito":782,"NomDistrito":"CHALLABAMBA","CodProvincia":78},
        {"CodDistrito":783,"NomDistrito":"COLQUEPATA","CodProvincia":78},
        {"CodDistrito":784,"NomDistrito":"HUANCARANI","CodProvincia":78},
        {"CodDistrito":785,"NomDistrito":"KOSÑIPATA","CodProvincia":78},
        {"CodDistrito":786,"NomDistrito":"PAUCARTAMBO","CodProvincia":78},
        {"CodDistrito":787,"NomDistrito":"ANDAHUAYLILLAS","CodProvincia":79},
        {"CodDistrito":788,"NomDistrito":"CAMANTI","CodProvincia":79},
        {"CodDistrito":789,"NomDistrito":"CCARHUAYO","CodProvincia":79},
        {"CodDistrito":790,"NomDistrito":"CCATCA","CodProvincia":79},
        {"CodDistrito":791,"NomDistrito":"CUSIPATA","CodProvincia":79},
        {"CodDistrito":792,"NomDistrito":"HUARO","CodProvincia":79},
        {"CodDistrito":793,"NomDistrito":"LUCRE","CodProvincia":79},
        {"CodDistrito":794,"NomDistrito":"MARCAPATA","CodProvincia":79},
        {"CodDistrito":795,"NomDistrito":"OCONGATE","CodProvincia":79},
        {"CodDistrito":796,"NomDistrito":"OROPESA","CodProvincia":79},
        {"CodDistrito":797,"NomDistrito":"QUIQUIJANA","CodProvincia":79},
        {"CodDistrito":798,"NomDistrito":"URCOS","CodProvincia":79},
        {"CodDistrito":799,"NomDistrito":"CHINCHERO","CodProvincia":80},
        {"CodDistrito":800,"NomDistrito":"HUAYLLABAMBA","CodProvincia":80},
        {"CodDistrito":801,"NomDistrito":"MACHUPICCHU","CodProvincia":80},
        {"CodDistrito":802,"NomDistrito":"MARAS","CodProvincia":80},
        {"CodDistrito":803,"NomDistrito":"OLLANTAYTAMBO","CodProvincia":80},
        {"CodDistrito":804,"NomDistrito":"URUBAMBA","CodProvincia":80},
        {"CodDistrito":805,"NomDistrito":"YUCAY","CodProvincia":80},
        {"CodDistrito":806,"NomDistrito":"ACOBAMBA","CodProvincia":81},
        {"CodDistrito":807,"NomDistrito":"ANDABAMBA","CodProvincia":81},
        {"CodDistrito":808,"NomDistrito":"ANTA","CodProvincia":81},
        {"CodDistrito":809,"NomDistrito":"CAJA","CodProvincia":81},
        {"CodDistrito":810,"NomDistrito":"MARCAS","CodProvincia":81},
        {"CodDistrito":811,"NomDistrito":"PAUCARA","CodProvincia":81},
        {"CodDistrito":812,"NomDistrito":"POMACOCHA","CodProvincia":81},
        {"CodDistrito":813,"NomDistrito":"ROSARIO","CodProvincia":81},
        {"CodDistrito":814,"NomDistrito":"ANCHONGA","CodProvincia":82},
        {"CodDistrito":815,"NomDistrito":"CALLANMARCA","CodProvincia":82},
        {"CodDistrito":816,"NomDistrito":"CCOCHACCASA","CodProvincia":82},
        {"CodDistrito":817,"NomDistrito":"CHINCHO","CodProvincia":82},
        {"CodDistrito":818,"NomDistrito":"CONGALLA","CodProvincia":82},
        {"CodDistrito":819,"NomDistrito":"HUANCA-HUANCA","CodProvincia":82},
        {"CodDistrito":820,"NomDistrito":"HUAYLLAY GRANDE","CodProvincia":82},
        {"CodDistrito":821,"NomDistrito":"JULCAMARCA","CodProvincia":82},
        {"CodDistrito":822,"NomDistrito":"LIRCAY","CodProvincia":82},
        {"CodDistrito":823,"NomDistrito":"SAN ANTONIO DE ANTAPARCO","CodProvincia":82},
        {"CodDistrito":824,"NomDistrito":"SANTO TOMAS DE PATA","CodProvincia":82},
        {"CodDistrito":825,"NomDistrito":"SECCLLA","CodProvincia":82},
        {"CodDistrito":826,"NomDistrito":"ARMA","CodProvincia":83},
        {"CodDistrito":827,"NomDistrito":"AURAHUA","CodProvincia":83},
        {"CodDistrito":828,"NomDistrito":"CAPILLAS","CodProvincia":83},
        {"CodDistrito":829,"NomDistrito":"CASTROVIRREYNA","CodProvincia":83},
        {"CodDistrito":830,"NomDistrito":"CHUPAMARCA","CodProvincia":83},
        {"CodDistrito":831,"NomDistrito":"COCAS","CodProvincia":83},
        {"CodDistrito":832,"NomDistrito":"HUACHOS","CodProvincia":83},
        {"CodDistrito":833,"NomDistrito":"HUAMATAMBO","CodProvincia":83},
        {"CodDistrito":834,"NomDistrito":"MOLLEPAMPA","CodProvincia":83},
        {"CodDistrito":835,"NomDistrito":"SAN JUAN","CodProvincia":83},
        {"CodDistrito":836,"NomDistrito":"SANTA ANA","CodProvincia":83},
        {"CodDistrito":837,"NomDistrito":"TANTARA","CodProvincia":83},
        {"CodDistrito":838,"NomDistrito":"TICRAPO","CodProvincia":83},
        {"CodDistrito":839,"NomDistrito":"ANCO","CodProvincia":84},
        {"CodDistrito":840,"NomDistrito":"CHINCHIHUASI","CodProvincia":84},
        {"CodDistrito":841,"NomDistrito":"CHURCAMPA","CodProvincia":84},
        {"CodDistrito":842,"NomDistrito":"COSME","CodProvincia":84},
        {"CodDistrito":843,"NomDistrito":"EL CARMEN","CodProvincia":84},
        {"CodDistrito":844,"NomDistrito":"LA MERCED","CodProvincia":84},
        {"CodDistrito":845,"NomDistrito":"LOCROJA","CodProvincia":84},
        {"CodDistrito":846,"NomDistrito":"PACHAMARCA","CodProvincia":84},
        {"CodDistrito":847,"NomDistrito":"PAUCARBAMBA","CodProvincia":84},
        {"CodDistrito":848,"NomDistrito":"SAN MIGUEL DE MAYOCC","CodProvincia":84},
        {"CodDistrito":849,"NomDistrito":"SAN PEDRO DE CORIS","CodProvincia":84},
        {"CodDistrito":850,"NomDistrito":"ACOBAMBILLA","CodProvincia":85},
        {"CodDistrito":851,"NomDistrito":"ACORIA","CodProvincia":85},
        {"CodDistrito":852,"NomDistrito":"ASCENSIÓN","CodProvincia":85},
        {"CodDistrito":853,"NomDistrito":"CONAYCA","CodProvincia":85},
        {"CodDistrito":854,"NomDistrito":"CUENCA","CodProvincia":85},
        {"CodDistrito":855,"NomDistrito":"HUACHOCOLPA","CodProvincia":85},
        {"CodDistrito":856,"NomDistrito":"HUANCAVELICA","CodProvincia":85},
        {"CodDistrito":857,"NomDistrito":"HUANDO","CodProvincia":85},
        {"CodDistrito":858,"NomDistrito":"HUAYLLAHUARA","CodProvincia":85},
        {"CodDistrito":859,"NomDistrito":"IZCUCHACA","CodProvincia":85},
        {"CodDistrito":860,"NomDistrito":"LARIA","CodProvincia":85},
        {"CodDistrito":861,"NomDistrito":"MANTA","CodProvincia":85},
        {"CodDistrito":862,"NomDistrito":"MARISCAL CÁCERES","CodProvincia":85},
        {"CodDistrito":863,"NomDistrito":"MOYA","CodProvincia":85},
        {"CodDistrito":864,"NomDistrito":"NUEVO OCCORO","CodProvincia":85},
        {"CodDistrito":865,"NomDistrito":"PALCA","CodProvincia":85},
        {"CodDistrito":866,"NomDistrito":"PILCHACA","CodProvincia":85},
        {"CodDistrito":867,"NomDistrito":"VILCA","CodProvincia":85},
        {"CodDistrito":868,"NomDistrito":"YAULI","CodProvincia":85},
        {"CodDistrito":869,"NomDistrito":"AYAVI","CodProvincia":86},
        {"CodDistrito":870,"NomDistrito":"CÓRDOVA","CodProvincia":86},
        {"CodDistrito":871,"NomDistrito":"HUAYACUNDO ARMA","CodProvincia":86},
        {"CodDistrito":872,"NomDistrito":"HUAYTARA","CodProvincia":86},
        {"CodDistrito":873,"NomDistrito":"LARAMARCA","CodProvincia":86},
        {"CodDistrito":874,"NomDistrito":"OCOYO","CodProvincia":86},
        {"CodDistrito":875,"NomDistrito":"PILPICHACA","CodProvincia":86},
        {"CodDistrito":876,"NomDistrito":"QUERCO","CodProvincia":86},
        {"CodDistrito":877,"NomDistrito":"QUITO-ARMA","CodProvincia":86},
        {"CodDistrito":878,"NomDistrito":"SAN ANTONIO DE CUSICANCHA","CodProvincia":86},
        {"CodDistrito":879,"NomDistrito":"SAN FRANCISCO DE SANGAYAICO","CodProvincia":86},
        {"CodDistrito":880,"NomDistrito":"SAN ISIDRO","CodProvincia":86},
        {"CodDistrito":881,"NomDistrito":"SANTIAGO DE CHOCORVOS","CodProvincia":86},
        {"CodDistrito":882,"NomDistrito":"SANTIAGO DE QUIRAHUARA","CodProvincia":86},
        {"CodDistrito":883,"NomDistrito":"SANTO DOMINGO DE CAPILLAS","CodProvincia":86},
        {"CodDistrito":884,"NomDistrito":"TAMBO","CodProvincia":86},
        {"CodDistrito":885,"NomDistrito":"ACOSTAMBO","CodProvincia":87},
        {"CodDistrito":886,"NomDistrito":"ACRAQUIA","CodProvincia":87},
        {"CodDistrito":887,"NomDistrito":"AHUAYCHA","CodProvincia":87},
        {"CodDistrito":888,"NomDistrito":"ANDAYMARCA","CodProvincia":87},
        {"CodDistrito":889,"NomDistrito":"COLCABAMBA","CodProvincia":87},
        {"CodDistrito":890,"NomDistrito":"DANIEL HERNÁNDEZ","CodProvincia":87},
        {"CodDistrito":891,"NomDistrito":"HUACHOCOLPA","CodProvincia":87},
        {"CodDistrito":892,"NomDistrito":"HUARIBAMBA","CodProvincia":87},
        {"CodDistrito":893,"NomDistrito":"ÑAHUIMPUQUIO","CodProvincia":87},
        {"CodDistrito":894,"NomDistrito":"PAMPAS","CodProvincia":87},
        {"CodDistrito":895,"NomDistrito":"PAZOS","CodProvincia":87},
        {"CodDistrito":896,"NomDistrito":"PICHOS","CodProvincia":87},
        {"CodDistrito":897,"NomDistrito":"QUICHUAS","CodProvincia":87},
        {"CodDistrito":898,"NomDistrito":"QUISHUAR","CodProvincia":87},
        {"CodDistrito":899,"NomDistrito":"ROBLE","CodProvincia":87},
        {"CodDistrito":900,"NomDistrito":"SALCABAMBA","CodProvincia":87},
        {"CodDistrito":901,"NomDistrito":"SALCAHUASI","CodProvincia":87},
        {"CodDistrito":902,"NomDistrito":"SAN MARCOS DE ROCCHAC","CodProvincia":87},
        {"CodDistrito":903,"NomDistrito":"SURCUBAMBA","CodProvincia":87},
        {"CodDistrito":904,"NomDistrito":"TINTAY PUNCU","CodProvincia":87},
        {"CodDistrito":905,"NomDistrito":"AMBO","CodProvincia":88},
        {"CodDistrito":906,"NomDistrito":"CAYNA","CodProvincia":88},
        {"CodDistrito":907,"NomDistrito":"COLPAS","CodProvincia":88},
        {"CodDistrito":908,"NomDistrito":"CONCHAMARCA","CodProvincia":88},
        {"CodDistrito":909,"NomDistrito":"HUACAR","CodProvincia":88},
        {"CodDistrito":910,"NomDistrito":"SAN FRANCISCO","CodProvincia":88},
        {"CodDistrito":911,"NomDistrito":"SAN RAFAEL","CodProvincia":88},
        {"CodDistrito":912,"NomDistrito":"TOMAY KICHWA","CodProvincia":88},
        {"CodDistrito":913,"NomDistrito":"CHUQUIS","CodProvincia":89},
        {"CodDistrito":914,"NomDistrito":"LA UNIÓN","CodProvincia":89},
        {"CodDistrito":915,"NomDistrito":"MARÍAS","CodProvincia":89},
        {"CodDistrito":916,"NomDistrito":"PACHAS","CodProvincia":89},
        {"CodDistrito":917,"NomDistrito":"QUIVILLA","CodProvincia":89},
        {"CodDistrito":918,"NomDistrito":"RIPAN","CodProvincia":89},
        {"CodDistrito":919,"NomDistrito":"SHUNQUI","CodProvincia":89},
        {"CodDistrito":920,"NomDistrito":"SILLAPATA","CodProvincia":89},
        {"CodDistrito":921,"NomDistrito":"YANAS","CodProvincia":89},
        {"CodDistrito":922,"NomDistrito":"CANCHABAMBA","CodProvincia":90},
        {"CodDistrito":923,"NomDistrito":"COCHABAMBA","CodProvincia":90},
        {"CodDistrito":924,"NomDistrito":"HUACAYBAMBA","CodProvincia":90},
        {"CodDistrito":925,"NomDistrito":"PINRA","CodProvincia":90},
        {"CodDistrito":926,"NomDistrito":"ARANCAY","CodProvincia":91},
        {"CodDistrito":927,"NomDistrito":"CHAVÍN DE PARIARCA","CodProvincia":91},
        {"CodDistrito":928,"NomDistrito":"JACAS GRANDE","CodProvincia":91},
        {"CodDistrito":929,"NomDistrito":"JIRCAN","CodProvincia":91},
        {"CodDistrito":930,"NomDistrito":"LLATA","CodProvincia":91},
        {"CodDistrito":931,"NomDistrito":"MIRAFLORES","CodProvincia":91},
        {"CodDistrito":932,"NomDistrito":"MONZÍN","CodProvincia":91},
        {"CodDistrito":933,"NomDistrito":"PUNCHAO","CodProvincia":91},
        {"CodDistrito":934,"NomDistrito":"PUÑOS","CodProvincia":91},
        {"CodDistrito":935,"NomDistrito":"SINGA","CodProvincia":91},
        {"CodDistrito":936,"NomDistrito":"TANTAMAYO","CodProvincia":91},
        {"CodDistrito":937,"NomDistrito":"AMARILIS","CodProvincia":92},
        {"CodDistrito":938,"NomDistrito":"CHINCHAO","CodProvincia":92},
        {"CodDistrito":939,"NomDistrito":"CHURUBAMBA","CodProvincia":92},
        {"CodDistrito":940,"NomDistrito":"HUANUCO","CodProvincia":92},
        {"CodDistrito":941,"NomDistrito":"MARGOS","CodProvincia":92},
        {"CodDistrito":942,"NomDistrito":"PILLCO MARCA","CodProvincia":92},
        {"CodDistrito":943,"NomDistrito":"QUISQUI (KICHKI)","CodProvincia":92},
        {"CodDistrito":944,"NomDistrito":"SAN FRANCISCO DE CAYRAN","CodProvincia":92},
        {"CodDistrito":945,"NomDistrito":"SAN PABLO DE PILLAO","CodProvincia":92},
        {"CodDistrito":946,"NomDistrito":"SAN PEDRO DE CHAULAN","CodProvincia":92},
        {"CodDistrito":947,"NomDistrito":"SANTA MARÍA DEL VALLE","CodProvincia":92},
        {"CodDistrito":948,"NomDistrito":"YACUS","CodProvincia":92},
        {"CodDistrito":949,"NomDistrito":"YARUMAYO","CodProvincia":92},
        {"CodDistrito":950,"NomDistrito":"BAÑOS","CodProvincia":93},
        {"CodDistrito":951,"NomDistrito":"JESÚS","CodProvincia":93},
        {"CodDistrito":952,"NomDistrito":"JIVIA","CodProvincia":93},
        {"CodDistrito":953,"NomDistrito":"QUEROPALCA","CodProvincia":93},
        {"CodDistrito":954,"NomDistrito":"RONDOS","CodProvincia":93},
        {"CodDistrito":955,"NomDistrito":"SAN FRANCISCO DE ASÍS","CodProvincia":93},
        {"CodDistrito":956,"NomDistrito":"SAN MIGUEL DE CAURI","CodProvincia":93},
        {"CodDistrito":957,"NomDistrito":"CASTILLO GRANDE","CodProvincia":94},
        {"CodDistrito":958,"NomDistrito":"DANIEL ALOMÍA ROBLES","CodProvincia":94},
        {"CodDistrito":959,"NomDistrito":"HERMÍLIO VALDIZAN","CodProvincia":94},
        {"CodDistrito":960,"NomDistrito":"JOSÉ CRESPO Y CASTILLO","CodProvincia":94},
        {"CodDistrito":961,"NomDistrito":"LUYANDO","CodProvincia":94},
        {"CodDistrito":962,"NomDistrito":"MARIANO DAMASO BERAUN","CodProvincia":94},
        {"CodDistrito":963,"NomDistrito":"PUCAYACU","CodProvincia":94},
        {"CodDistrito":964,"NomDistrito":"RUPA-RUPA","CodProvincia":94},
        {"CodDistrito":965,"NomDistrito":"CHOLON","CodProvincia":95},
        {"CodDistrito":966,"NomDistrito":"HUACRACHUCO","CodProvincia":95},
        {"CodDistrito":967,"NomDistrito":"LA MORADA","CodProvincia":95},
        {"CodDistrito":968,"NomDistrito":"SAN BUENAVENTURA","CodProvincia":95},
        {"CodDistrito":969,"NomDistrito":"SANTA ROSA DE ALTO YANAJANCA","CodProvincia":95},
        {"CodDistrito":970,"NomDistrito":"CHAGLLA","CodProvincia":96},
        {"CodDistrito":971,"NomDistrito":"MOLINO","CodProvincia":96},
        {"CodDistrito":972,"NomDistrito":"PANAO","CodProvincia":96},
        {"CodDistrito":973,"NomDistrito":"UMARI","CodProvincia":96},
        {"CodDistrito":974,"NomDistrito":"CODO DEL POZUZO","CodProvincia":97},
        {"CodDistrito":975,"NomDistrito":"HONORIA","CodProvincia":97},
        {"CodDistrito":976,"NomDistrito":"PUERTO INCA","CodProvincia":97},
        {"CodDistrito":977,"NomDistrito":"TOURNAVISTA","CodProvincia":97},
        {"CodDistrito":978,"NomDistrito":"YUYAPICHIS","CodProvincia":97},
        {"CodDistrito":979,"NomDistrito":"APARICIO POMARES","CodProvincia":98},
        {"CodDistrito":980,"NomDistrito":"CAHUAC","CodProvincia":98},
        {"CodDistrito":981,"NomDistrito":"CHACABAMBA","CodProvincia":98},
        {"CodDistrito":982,"NomDistrito":"CHAVINILLO","CodProvincia":98},
        {"CodDistrito":983,"NomDistrito":"CHORAS","CodProvincia":98},
        {"CodDistrito":984,"NomDistrito":"JACAS CHICO","CodProvincia":98},
        {"CodDistrito":985,"NomDistrito":"OBAS","CodProvincia":98},
        {"CodDistrito":986,"NomDistrito":"PAMPAMARCA","CodProvincia":98},
        {"CodDistrito":987,"NomDistrito":"ALTO LARAN","CodProvincia":99},
        {"CodDistrito":988,"NomDistrito":"CHAVIN","CodProvincia":99},
        {"CodDistrito":989,"NomDistrito":"CHINCHA ALTA","CodProvincia":99},
        {"CodDistrito":990,"NomDistrito":"CHINCHA BAJA","CodProvincia":99},
        {"CodDistrito":991,"NomDistrito":"EL CARMEN","CodProvincia":99},
        {"CodDistrito":992,"NomDistrito":"GROCIO PRADO","CodProvincia":99},
        {"CodDistrito":993,"NomDistrito":"PUEBLO NUEVO","CodProvincia":99},
        {"CodDistrito":994,"NomDistrito":"SAN JUAN DE YANAC","CodProvincia":99},
        {"CodDistrito":995,"NomDistrito":"SAN PEDRO DE HUACARPANA","CodProvincia":99},
        {"CodDistrito":996,"NomDistrito":"SUNAMPE","CodProvincia":99},
        {"CodDistrito":997,"NomDistrito":"TAMBO DE MORA","CodProvincia":99},
        {"CodDistrito":998,"NomDistrito":"ICA","CodProvincia":100},
        {"CodDistrito":999,"NomDistrito":"LA TINGUIÑA","CodProvincia":100},
        {"CodDistrito":1000,"NomDistrito":"LOS AQUIJES","CodProvincia":100},
        {"CodDistrito":1001,"NomDistrito":"OCUCAJE","CodProvincia":100},
        {"CodDistrito":1002,"NomDistrito":"PACHACUTEC","CodProvincia":100},
        {"CodDistrito":1003,"NomDistrito":"PARCONA","CodProvincia":100},
        {"CodDistrito":1004,"NomDistrito":"PUEBLO NUEVO","CodProvincia":100},
        {"CodDistrito":1005,"NomDistrito":"SALAS","CodProvincia":100},
        {"CodDistrito":1006,"NomDistrito":"SAN JOSE DE LOS MOLINOS","CodProvincia":100},
        {"CodDistrito":1007,"NomDistrito":"SAN JUAN BAUTISTA","CodProvincia":100},
        {"CodDistrito":1008,"NomDistrito":"SANTIAGO","CodProvincia":100},
        {"CodDistrito":1009,"NomDistrito":"SUBTANJALLA","CodProvincia":100},
        {"CodDistrito":1010,"NomDistrito":"TATE","CodProvincia":100},
        {"CodDistrito":1011,"NomDistrito":"YAUCA DEL ROSARIO","CodProvincia":100},
        {"CodDistrito":1012,"NomDistrito":"CHANGUILLO","CodProvincia":101},
        {"CodDistrito":1013,"NomDistrito":"EL INGENIO","CodProvincia":101},
        {"CodDistrito":1014,"NomDistrito":"MARCONA","CodProvincia":101},
        {"CodDistrito":1015,"NomDistrito":"NASCA","CodProvincia":101},
        {"CodDistrito":1016,"NomDistrito":"VISTA ALEGRE","CodProvincia":101},
        {"CodDistrito":1017,"NomDistrito":"LLIPATA","CodProvincia":102},
        {"CodDistrito":1018,"NomDistrito":"PALPA","CodProvincia":102},
        {"CodDistrito":1019,"NomDistrito":"RÍO GRANDE","CodProvincia":102},
        {"CodDistrito":1020,"NomDistrito":"SANTA CRUZ","CodProvincia":102},
        {"CodDistrito":1021,"NomDistrito":"TIBILLO","CodProvincia":102},
        {"CodDistrito":1022,"NomDistrito":"HUANCANO","CodProvincia":103},
        {"CodDistrito":1023,"NomDistrito":"HUMAY","CodProvincia":103},
        {"CodDistrito":1024,"NomDistrito":"INDEPENDENCIA","CodProvincia":103},
        {"CodDistrito":1025,"NomDistrito":"PARACAS","CodProvincia":103},
        {"CodDistrito":1026,"NomDistrito":"PISCO","CodProvincia":103},
        {"CodDistrito":1027,"NomDistrito":"SAN ANDRÉS","CodProvincia":103},
        {"CodDistrito":1028,"NomDistrito":"SAN CLEMENTE","CodProvincia":103},
        {"CodDistrito":1029,"NomDistrito":"TUPAC AMARU INCA","CodProvincia":103},
        {"CodDistrito":1030,"NomDistrito":"CHANCHAMAYO","CodProvincia":104},
        {"CodDistrito":1031,"NomDistrito":"PERENE","CodProvincia":104},
        {"CodDistrito":1032,"NomDistrito":"PICHANAQUI","CodProvincia":104},
        {"CodDistrito":1033,"NomDistrito":"SAN LUIS DE SHUARO","CodProvincia":104},
        {"CodDistrito":1034,"NomDistrito":"SAN RAMÓN","CodProvincia":104},
        {"CodDistrito":1035,"NomDistrito":"VITOC","CodProvincia":104},
        {"CodDistrito":1036,"NomDistrito":"AHUAC","CodProvincia":105},
        {"CodDistrito":1037,"NomDistrito":"CHONGOS BAJO","CodProvincia":105},
        {"CodDistrito":1038,"NomDistrito":"CHUPACA","CodProvincia":105},
        {"CodDistrito":1039,"NomDistrito":"HUACHAC","CodProvincia":105},
        {"CodDistrito":1040,"NomDistrito":"HUAMANCACA CHICO","CodProvincia":105},
        {"CodDistrito":1041,"NomDistrito":"SAN JUAN DE ISCOS","CodProvincia":105},
        {"CodDistrito":1042,"NomDistrito":"SAN JUAN DE JARPA","CodProvincia":105},
        {"CodDistrito":1043,"NomDistrito":"TRES DE DICIEMBRE","CodProvincia":105},
        {"CodDistrito":1044,"NomDistrito":"YANACANCHA","CodProvincia":105},
        {"CodDistrito":1045,"NomDistrito":"ACO","CodProvincia":106},
        {"CodDistrito":1046,"NomDistrito":"ANDAMARCA","CodProvincia":106},
        {"CodDistrito":1047,"NomDistrito":"CHAMBARA","CodProvincia":106},
        {"CodDistrito":1048,"NomDistrito":"COCHAS","CodProvincia":106},
        {"CodDistrito":1049,"NomDistrito":"COMAS","CodProvincia":106},
        {"CodDistrito":1050,"NomDistrito":"CONCEPCION","CodProvincia":106},
        {"CodDistrito":1051,"NomDistrito":"HEROINAS TOLEDO","CodProvincia":106},
        {"CodDistrito":1052,"NomDistrito":"MANZANARES","CodProvincia":106},
        {"CodDistrito":1053,"NomDistrito":"MARISCAL CASTILLA","CodProvincia":106},
        {"CodDistrito":1054,"NomDistrito":"MATAHUASI","CodProvincia":106},
        {"CodDistrito":1055,"NomDistrito":"MITO","CodProvincia":106},
        {"CodDistrito":1056,"NomDistrito":"NUEVE DE JULIO","CodProvincia":106},
        {"CodDistrito":1057,"NomDistrito":"ORCOTUNA","CodProvincia":106},
        {"CodDistrito":1058,"NomDistrito":"SAN JOSÉ DE QUERO","CodProvincia":106},
        {"CodDistrito":1059,"NomDistrito":"SANTA ROSA DE OCOPA","CodProvincia":106},
        {"CodDistrito":1060,"NomDistrito":"CARHUACALLANGA","CodProvincia":107},
        {"CodDistrito":1061,"NomDistrito":"CHACAPAMPA","CodProvincia":107},
        {"CodDistrito":1062,"NomDistrito":"CHICCHE","CodProvincia":107},
        {"CodDistrito":1063,"NomDistrito":"CHILCA","CodProvincia":107},
        {"CodDistrito":1064,"NomDistrito":"CHONGOS ALTO","CodProvincia":107},
        {"CodDistrito":1065,"NomDistrito":"CHUPURO","CodProvincia":107},
        {"CodDistrito":1066,"NomDistrito":"COLCA","CodProvincia":107},
        {"CodDistrito":1067,"NomDistrito":"CULLHUAS","CodProvincia":107},
        {"CodDistrito":1068,"NomDistrito":"EL TAMBO","CodProvincia":107},
        {"CodDistrito":1069,"NomDistrito":"HUACRAPUQUIO","CodProvincia":107},
        {"CodDistrito":1070,"NomDistrito":"HUALHUAS","CodProvincia":107},
        {"CodDistrito":1071,"NomDistrito":"HUANCAN","CodProvincia":107},
        {"CodDistrito":1072,"NomDistrito":"HUANCAYO","CodProvincia":107},
        {"CodDistrito":1073,"NomDistrito":"HUASICANCHA","CodProvincia":107},
        {"CodDistrito":1074,"NomDistrito":"HUAYUCACHI","CodProvincia":107},
        {"CodDistrito":1075,"NomDistrito":"INGENIO","CodProvincia":107},
        {"CodDistrito":1076,"NomDistrito":"PARIAHUANCA","CodProvincia":107},
        {"CodDistrito":1077,"NomDistrito":"PILCOMAYO","CodProvincia":107},
        {"CodDistrito":1078,"NomDistrito":"PUCARA","CodProvincia":107},
        {"CodDistrito":1079,"NomDistrito":"QUICHUAY","CodProvincia":107},
        {"CodDistrito":1080,"NomDistrito":"QUILCAS","CodProvincia":107},
        {"CodDistrito":1081,"NomDistrito":"SAN AGUSTIN","CodProvincia":107},
        {"CodDistrito":1082,"NomDistrito":"SAN JERONIMO DE TUNAN","CodProvincia":107},
        {"CodDistrito":1083,"NomDistrito":"SAÑO","CodProvincia":107},
        {"CodDistrito":1084,"NomDistrito":"SANTO DOMINGO DE ACOBAMBA","CodProvincia":107},
        {"CodDistrito":1085,"NomDistrito":"SAPALLANGA","CodProvincia":107},
        {"CodDistrito":1086,"NomDistrito":"SICAYA","CodProvincia":107},
        {"CodDistrito":1087,"NomDistrito":"VIQUES","CodProvincia":107},
        {"CodDistrito":1088,"NomDistrito":"ACOLLA","CodProvincia":108},
        {"CodDistrito":1089,"NomDistrito":"APATA","CodProvincia":108},
        {"CodDistrito":1090,"NomDistrito":"ATAURA","CodProvincia":108},
        {"CodDistrito":1091,"NomDistrito":"CANCHAYLLO","CodProvincia":108},
        {"CodDistrito":1092,"NomDistrito":"CURICACA","CodProvincia":108},
        {"CodDistrito":1093,"NomDistrito":"EL MANTARO","CodProvincia":108},
        {"CodDistrito":1094,"NomDistrito":"HUAMALI","CodProvincia":108},
        {"CodDistrito":1095,"NomDistrito":"HUARIPAMPA","CodProvincia":108},
        {"CodDistrito":1096,"NomDistrito":"HUERTAS","CodProvincia":108},
        {"CodDistrito":1097,"NomDistrito":"JANJAILLO","CodProvincia":108},
        {"CodDistrito":1098,"NomDistrito":"JAUJA","CodProvincia":108},
        {"CodDistrito":1099,"NomDistrito":"JULCÁN","CodProvincia":108},
        {"CodDistrito":1100,"NomDistrito":"LEONOR ORDÓÑEZ","CodProvincia":108},
        {"CodDistrito":1101,"NomDistrito":"LLOCLLAPAMPA","CodProvincia":108},
        {"CodDistrito":1102,"NomDistrito":"MARCO","CodProvincia":108},
        {"CodDistrito":1103,"NomDistrito":"MASMA","CodProvincia":108},
        {"CodDistrito":1104,"NomDistrito":"MASMA CHICCHE","CodProvincia":108},
        {"CodDistrito":1105,"NomDistrito":"MOLINOS","CodProvincia":108},
        {"CodDistrito":1106,"NomDistrito":"MONOBAMBA","CodProvincia":108},
        {"CodDistrito":1107,"NomDistrito":"MUQUI","CodProvincia":108},
        {"CodDistrito":1108,"NomDistrito":"MUQUIYAUYO","CodProvincia":108},
        {"CodDistrito":1109,"NomDistrito":"PACA","CodProvincia":108},
        {"CodDistrito":1110,"NomDistrito":"PACCHA","CodProvincia":108},
        {"CodDistrito":1111,"NomDistrito":"PANCAN","CodProvincia":108},
        {"CodDistrito":1112,"NomDistrito":"PARCO","CodProvincia":108},
        {"CodDistrito":1113,"NomDistrito":"POMACANCHA","CodProvincia":108},
        {"CodDistrito":1114,"NomDistrito":"RICRAN","CodProvincia":108},
        {"CodDistrito":1115,"NomDistrito":"SAN LORENZO","CodProvincia":108},
        {"CodDistrito":1116,"NomDistrito":"SAN PEDRO DE CHUNAN","CodProvincia":108},
        {"CodDistrito":1117,"NomDistrito":"SAUSA","CodProvincia":108},
        {"CodDistrito":1118,"NomDistrito":"SINCOS","CodProvincia":108},
        {"CodDistrito":1119,"NomDistrito":"TUNAN MARCA","CodProvincia":108},
        {"CodDistrito":1120,"NomDistrito":"YAULI","CodProvincia":108},
        {"CodDistrito":1121,"NomDistrito":"YAUYOS","CodProvincia":108},
        {"CodDistrito":1122,"NomDistrito":"CARHUAMAYO","CodProvincia":109},
        {"CodDistrito":1123,"NomDistrito":"JUNIN","CodProvincia":109},
        {"CodDistrito":1124,"NomDistrito":"ONDORES","CodProvincia":109},
        {"CodDistrito":1125,"NomDistrito":"ULCUMAYO","CodProvincia":109},
        {"CodDistrito":1126,"NomDistrito":"COVIRIALI","CodProvincia":110},
        {"CodDistrito":1127,"NomDistrito":"LLAYLLA","CodProvincia":110},
        {"CodDistrito":1128,"NomDistrito":"MAZAMARI","CodProvincia":110},
        {"CodDistrito":1129,"NomDistrito":"PAMPA HERMOSA","CodProvincia":110},
        {"CodDistrito":1130,"NomDistrito":"PANGOA","CodProvincia":110},
        {"CodDistrito":1131,"NomDistrito":"RIO NEGRO","CodProvincia":110},
        {"CodDistrito":1132,"NomDistrito":"RIO TAMBO","CodProvincia":110},
        {"CodDistrito":1133,"NomDistrito":"SATIPO","CodProvincia":110},
        {"CodDistrito":1134,"NomDistrito":"VIZCATAN DEL ENE","CodProvincia":110},
        {"CodDistrito":1135,"NomDistrito":"ACOBAMBA","CodProvincia":111},
        {"CodDistrito":1136,"NomDistrito":"HUARICOLCA","CodProvincia":111},
        {"CodDistrito":1137,"NomDistrito":"HUASAHUASI","CodProvincia":111},
        {"CodDistrito":1138,"NomDistrito":"LA UNION","CodProvincia":111},
        {"CodDistrito":1139,"NomDistrito":"PALCA","CodProvincia":111},
        {"CodDistrito":1140,"NomDistrito":"PALCAMAYO","CodProvincia":111},
        {"CodDistrito":1141,"NomDistrito":"SAN PEDRO DE CAJAS","CodProvincia":111},
        {"CodDistrito":1142,"NomDistrito":"TAPO","CodProvincia":111},
        {"CodDistrito":1143,"NomDistrito":"TARMA","CodProvincia":111},
        {"CodDistrito":1144,"NomDistrito":"CHACAPALPA","CodProvincia":112},
        {"CodDistrito":1145,"NomDistrito":"HUAY-HUAY","CodProvincia":112},
        {"CodDistrito":1146,"NomDistrito":"LA OROYA","CodProvincia":112},
        {"CodDistrito":1147,"NomDistrito":"MARCAPOMACOCHA","CodProvincia":112},
        {"CodDistrito":1148,"NomDistrito":"MOROCOCHA","CodProvincia":112},
        {"CodDistrito":1149,"NomDistrito":"PACCHA","CodProvincia":112},
        {"CodDistrito":1150,"NomDistrito":"SANTA BÁRBARA DE CARHUACAYAN","CodProvincia":112},
        {"CodDistrito":1151,"NomDistrito":"SANTA ROSA DE SACCO","CodProvincia":112},
        {"CodDistrito":1152,"NomDistrito":"SUITUCANCHA","CodProvincia":112},
        {"CodDistrito":1153,"NomDistrito":"YAULI","CodProvincia":112},
        {"CodDistrito":1154,"NomDistrito":"ASCOPE","CodProvincia":113},
        {"CodDistrito":1155,"NomDistrito":"CASA GRANDE","CodProvincia":113},
        {"CodDistrito":1156,"NomDistrito":"CHICAMA","CodProvincia":113},
        {"CodDistrito":1157,"NomDistrito":"CHOCOPE","CodProvincia":113},
        {"CodDistrito":1158,"NomDistrito":"MAGDALENA DE CAO","CodProvincia":113},
        {"CodDistrito":1159,"NomDistrito":"PAIJAN","CodProvincia":113},
        {"CodDistrito":1160,"NomDistrito":"RÁZURI","CodProvincia":113},
        {"CodDistrito":1161,"NomDistrito":"SANTIAGO DE CAO","CodProvincia":113},
        {"CodDistrito":1162,"NomDistrito":"BAMBAMARCA","CodProvincia":114},
        {"CodDistrito":1163,"NomDistrito":"BOLIVAR","CodProvincia":114},
        {"CodDistrito":1164,"NomDistrito":"CONDORMARCA","CodProvincia":114},
        {"CodDistrito":1165,"NomDistrito":"LONGOTEA","CodProvincia":114},
        {"CodDistrito":1166,"NomDistrito":"UCHUMARCA","CodProvincia":114},
        {"CodDistrito":1167,"NomDistrito":"UCUNCHA","CodProvincia":114},
        {"CodDistrito":1168,"NomDistrito":"CHEPEN","CodProvincia":115},
        {"CodDistrito":1169,"NomDistrito":"PACANGA","CodProvincia":115},
        {"CodDistrito":1170,"NomDistrito":"PUEBLO NUEVO","CodProvincia":115},
        {"CodDistrito":1171,"NomDistrito":"CASCAS","CodProvincia":116},
        {"CodDistrito":1172,"NomDistrito":"LUCMA","CodProvincia":116},
        {"CodDistrito":1173,"NomDistrito":"MARMOT","CodProvincia":116},
        {"CodDistrito":1174,"NomDistrito":"SAYAPULLO","CodProvincia":116},
        {"CodDistrito":1175,"NomDistrito":"CALAMARCA","CodProvincia":117},
        {"CodDistrito":1176,"NomDistrito":"CARABAMBA","CodProvincia":117},
        {"CodDistrito":1177,"NomDistrito":"HUASO","CodProvincia":117},
        {"CodDistrito":1178,"NomDistrito":"JULCAN","CodProvincia":117},
        {"CodDistrito":1179,"NomDistrito":"AGALLPAMPA","CodProvincia":118},
        {"CodDistrito":1180,"NomDistrito":"CHARAT","CodProvincia":118},
        {"CodDistrito":1181,"NomDistrito":"HUARANCHAL","CodProvincia":118},
        {"CodDistrito":1182,"NomDistrito":"LA CUESTA","CodProvincia":118},
        {"CodDistrito":1183,"NomDistrito":"MACHE","CodProvincia":118},
        {"CodDistrito":1184,"NomDistrito":"OTUZCO","CodProvincia":118},
        {"CodDistrito":1185,"NomDistrito":"PARANDAY","CodProvincia":118},
        {"CodDistrito":1186,"NomDistrito":"SALPO","CodProvincia":118},
        {"CodDistrito":1187,"NomDistrito":"SINSICAP","CodProvincia":118},
        {"CodDistrito":1188,"NomDistrito":"USQUIL","CodProvincia":118},
        {"CodDistrito":1189,"NomDistrito":"GUADALUPE","CodProvincia":119},
        {"CodDistrito":1190,"NomDistrito":"JEQUETEPEQUE","CodProvincia":119},
        {"CodDistrito":1191,"NomDistrito":"PACASMAYO","CodProvincia":119},
        {"CodDistrito":1192,"NomDistrito":"SAN JOSE","CodProvincia":119},
        {"CodDistrito":1193,"NomDistrito":"SAN PEDRO DE LLOC","CodProvincia":119},
        {"CodDistrito":1194,"NomDistrito":"BULDIBUYO","CodProvincia":120},
        {"CodDistrito":1195,"NomDistrito":"CHILLIA","CodProvincia":120},
        {"CodDistrito":1196,"NomDistrito":"HUANCASPATA","CodProvincia":120},
        {"CodDistrito":1197,"NomDistrito":"HUAYLILLAS","CodProvincia":120},
        {"CodDistrito":1198,"NomDistrito":"HUAYO","CodProvincia":120},
        {"CodDistrito":1199,"NomDistrito":"ONGON","CodProvincia":120},
        {"CodDistrito":1200,"NomDistrito":"PARCOY","CodProvincia":120},
        {"CodDistrito":1201,"NomDistrito":"PATAZ","CodProvincia":120},
        {"CodDistrito":1202,"NomDistrito":"PIAS","CodProvincia":120},
        {"CodDistrito":1203,"NomDistrito":"SANTIAGO DE CHALLAS","CodProvincia":120},
        {"CodDistrito":1204,"NomDistrito":"TAURIJA","CodProvincia":120},
        {"CodDistrito":1205,"NomDistrito":"TAYABAMBA","CodProvincia":120},
        {"CodDistrito":1206,"NomDistrito":"URPAY","CodProvincia":120},
        {"CodDistrito":1207,"NomDistrito":"CHUGAY","CodProvincia":121},
        {"CodDistrito":1208,"NomDistrito":"COCHORCO","CodProvincia":121},
        {"CodDistrito":1209,"NomDistrito":"CURGOS","CodProvincia":121},
        {"CodDistrito":1210,"NomDistrito":"HUAMACHUCO","CodProvincia":121},
        {"CodDistrito":1211,"NomDistrito":"MARCABAL","CodProvincia":121},
        {"CodDistrito":1212,"NomDistrito":"SANAGORAN","CodProvincia":121},
        {"CodDistrito":1213,"NomDistrito":"SARIN","CodProvincia":121},
        {"CodDistrito":1214,"NomDistrito":"SARTIMBAMBA","CodProvincia":121},
        {"CodDistrito":1215,"NomDistrito":"ANGASMARCA","CodProvincia":122},
        {"CodDistrito":1216,"NomDistrito":"CACHICADAN","CodProvincia":122},
        {"CodDistrito":1217,"NomDistrito":"MOLLEBAMBA","CodProvincia":122},
        {"CodDistrito":1218,"NomDistrito":"MOLLEPATA","CodProvincia":122},
        {"CodDistrito":1219,"NomDistrito":"QUIRUVILCA","CodProvincia":122},
        {"CodDistrito":1220,"NomDistrito":"SANTA CRUZ DE CHUCA","CodProvincia":122},
        {"CodDistrito":1221,"NomDistrito":"SANTIAGO DE CHUCO","CodProvincia":122},
        {"CodDistrito":1222,"NomDistrito":"SITABAMBA","CodProvincia":122},
        {"CodDistrito":1223,"NomDistrito":"EL PORVENIR","CodProvincia":123},
        {"CodDistrito":1224,"NomDistrito":"FLORENCIA DE MORA","CodProvincia":123},
        {"CodDistrito":1225,"NomDistrito":"HUANCHACO","CodProvincia":123},
        {"CodDistrito":1226,"NomDistrito":"LA ESPERANZA","CodProvincia":123},
        {"CodDistrito":1227,"NomDistrito":"LAREDO","CodProvincia":123},
        {"CodDistrito":1228,"NomDistrito":"MOCHE","CodProvincia":123},
        {"CodDistrito":1229,"NomDistrito":"POROTO","CodProvincia":123},
        {"CodDistrito":1230,"NomDistrito":"SALAVERRY","CodProvincia":123},
        {"CodDistrito":1231,"NomDistrito":"SIMBAL","CodProvincia":123},
        {"CodDistrito":1232,"NomDistrito":"TRUJILLO","CodProvincia":123},
        {"CodDistrito":1233,"NomDistrito":"VICTOR LARCO HERRERA","CodProvincia":123},
        {"CodDistrito":1234,"NomDistrito":"CHAO","CodProvincia":124},
        {"CodDistrito":1235,"NomDistrito":"GUADALUPITO","CodProvincia":124},
        {"CodDistrito":1236,"NomDistrito":"VIRU","CodProvincia":124},
        {"CodDistrito":1237,"NomDistrito":"CAYALTI","CodProvincia":125},
        {"CodDistrito":1238,"NomDistrito":"CHICLAYO","CodProvincia":125},
        {"CodDistrito":1239,"NomDistrito":"CHONGOYAPE","CodProvincia":125},
        {"CodDistrito":1240,"NomDistrito":"ETEN","CodProvincia":125},
        {"CodDistrito":1241,"NomDistrito":"ETEN PUERTO","CodProvincia":125},
        {"CodDistrito":1242,"NomDistrito":"JOSÉ LEONARDO ORTIZ","CodProvincia":125},
        {"CodDistrito":1243,"NomDistrito":"LAGUNAS","CodProvincia":125},
        {"CodDistrito":1244,"NomDistrito":"LA VICTORIA","CodProvincia":125},
        {"CodDistrito":1245,"NomDistrito":"MONSEFU","CodProvincia":125},
        {"CodDistrito":1246,"NomDistrito":"NUEVA ARICA","CodProvincia":125},
        {"CodDistrito":1247,"NomDistrito":"OYOTUN","CodProvincia":125},
        {"CodDistrito":1248,"NomDistrito":"PATAPO","CodProvincia":125},
        {"CodDistrito":1249,"NomDistrito":"PICSI","CodProvincia":125},
        {"CodDistrito":1250,"NomDistrito":"PIMENTEL","CodProvincia":125},
        {"CodDistrito":1251,"NomDistrito":"POMALCA","CodProvincia":125},
        {"CodDistrito":1252,"NomDistrito":"PUCALA","CodProvincia":125},
        {"CodDistrito":1253,"NomDistrito":"REQUE","CodProvincia":125},
        {"CodDistrito":1254,"NomDistrito":"SAÑA","CodProvincia":125},
        {"CodDistrito":1255,"NomDistrito":"SANTA ROSA","CodProvincia":125},
        {"CodDistrito":1256,"NomDistrito":"TUMAN","CodProvincia":125},
        {"CodDistrito":1257,"NomDistrito":"CAÑARIS","CodProvincia":126},
        {"CodDistrito":1258,"NomDistrito":"FERREÑAFE","CodProvincia":126},
        {"CodDistrito":1259,"NomDistrito":"INCAHUASI","CodProvincia":126},
        {"CodDistrito":1260,"NomDistrito":"MANUEL ANTONIO MESONES MURO","CodProvincia":126},
        {"CodDistrito":1261,"NomDistrito":"PITIPO","CodProvincia":126},
        {"CodDistrito":1262,"NomDistrito":"PUEBLO NUEVO","CodProvincia":126},
        {"CodDistrito":1263,"NomDistrito":"CHOCHOPE","CodProvincia":127},
        {"CodDistrito":1264,"NomDistrito":"ILLIMO","CodProvincia":127},
        {"CodDistrito":1265,"NomDistrito":"JAYANCA","CodProvincia":127},
        {"CodDistrito":1266,"NomDistrito":"LAMBAYEQUE","CodProvincia":127},
        {"CodDistrito":1267,"NomDistrito":"MOCHUMI","CodProvincia":127},
        {"CodDistrito":1268,"NomDistrito":"MORROPE","CodProvincia":127},
        {"CodDistrito":1269,"NomDistrito":"MOTUPE","CodProvincia":127},
        {"CodDistrito":1270,"NomDistrito":"OLMOS","CodProvincia":127},
        {"CodDistrito":1271,"NomDistrito":"PACORA","CodProvincia":127},
        {"CodDistrito":1272,"NomDistrito":"SALAS","CodProvincia":127},
        {"CodDistrito":1273,"NomDistrito":"SAN JOSÉ","CodProvincia":127},
        {"CodDistrito":1274,"NomDistrito":"TUCUME","CodProvincia":127},
        {"CodDistrito":1275,"NomDistrito":"BARRANCA","CodProvincia":128},
        {"CodDistrito":1276,"NomDistrito":"PARAMONGA","CodProvincia":128},
        {"CodDistrito":1277,"NomDistrito":"PATIVILCA","CodProvincia":128},
        {"CodDistrito":1278,"NomDistrito":"SUPE","CodProvincia":128},
        {"CodDistrito":1279,"NomDistrito":"SUPE PUERTO","CodProvincia":128},
        {"CodDistrito":1280,"NomDistrito":"CAJATAMBO","CodProvincia":129},
        {"CodDistrito":1281,"NomDistrito":"COPA","CodProvincia":129},
        {"CodDistrito":1282,"NomDistrito":"GORGOR","CodProvincia":129},
        {"CodDistrito":1283,"NomDistrito":"HUANCAPON","CodProvincia":129},
        {"CodDistrito":1284,"NomDistrito":"MANAS","CodProvincia":129},
        {"CodDistrito":1285,"NomDistrito":"ASIA","CodProvincia":130},
        {"CodDistrito":1286,"NomDistrito":"CALANGO","CodProvincia":130},
        {"CodDistrito":1287,"NomDistrito":"CERRO AZUL","CodProvincia":130},
        {"CodDistrito":1288,"NomDistrito":"CHILCA","CodProvincia":130},
        {"CodDistrito":1289,"NomDistrito":"COAYLLO","CodProvincia":130},
        {"CodDistrito":1290,"NomDistrito":"IMPERIAL","CodProvincia":130},
        {"CodDistrito":1291,"NomDistrito":"LUNAHUANA","CodProvincia":130},
        {"CodDistrito":1292,"NomDistrito":"MALA","CodProvincia":130},
        {"CodDistrito":1293,"NomDistrito":"NUEVO IMPERIAL","CodProvincia":130},
        {"CodDistrito":1294,"NomDistrito":"PACARAN","CodProvincia":130},
        {"CodDistrito":1295,"NomDistrito":"QUILMANA","CodProvincia":130},
        {"CodDistrito":1296,"NomDistrito":"SAN ANTONIO","CodProvincia":130},
        {"CodDistrito":1297,"NomDistrito":"SAN LUIS","CodProvincia":130},
        {"CodDistrito":1298,"NomDistrito":"SANTA CRUZ DE FLORES","CodProvincia":130},
        {"CodDistrito":1299,"NomDistrito":"SAN VICENTE DE CAÑETE","CodProvincia":130},
        {"CodDistrito":1300,"NomDistrito":"ZÚÑIGA","CodProvincia":130},
        {"CodDistrito":1301,"NomDistrito":"ARAHUAY","CodProvincia":131},
        {"CodDistrito":1302,"NomDistrito":"CANTA","CodProvincia":131},
        {"CodDistrito":1303,"NomDistrito":"HUAMANTANGA","CodProvincia":131},
        {"CodDistrito":1304,"NomDistrito":"HUAROS","CodProvincia":131},
        {"CodDistrito":1305,"NomDistrito":"LACHAQUI","CodProvincia":131},
        {"CodDistrito":1306,"NomDistrito":"SAN BUENAVENTURA","CodProvincia":131},
        {"CodDistrito":1307,"NomDistrito":"SANTA ROSA DE QUIVES","CodProvincia":131},
        {"CodDistrito":1308,"NomDistrito":"ATAVILLOS ALTO","CodProvincia":132},
        {"CodDistrito":1309,"NomDistrito":"ATAVILLOS BAJO","CodProvincia":132},
        {"CodDistrito":1310,"NomDistrito":"AUCALLAMA","CodProvincia":132},
        {"CodDistrito":1311,"NomDistrito":"CHANCAY","CodProvincia":132},
        {"CodDistrito":1312,"NomDistrito":"HUARAL","CodProvincia":132},
        {"CodDistrito":1313,"NomDistrito":"IHUARI","CodProvincia":132},
        {"CodDistrito":1314,"NomDistrito":"LAMPIAN","CodProvincia":132},
        {"CodDistrito":1315,"NomDistrito":"PACARAOS","CodProvincia":132},
        {"CodDistrito":1316,"NomDistrito":"SAN MIGUEL DE ACOS","CodProvincia":132},
        {"CodDistrito":1317,"NomDistrito":"SANTA CRUZ DE ANDAMARCA","CodProvincia":132},
        {"CodDistrito":1318,"NomDistrito":"SUMBILCA","CodProvincia":132},
        {"CodDistrito":1319,"NomDistrito":"VEINTISIETE DE NOVIEMBRE","CodProvincia":132},
        {"CodDistrito":1320,"NomDistrito":"ANTIOQUIA","CodProvincia":133},
        {"CodDistrito":1321,"NomDistrito":"CALLAHUANCA","CodProvincia":133},
        {"CodDistrito":1322,"NomDistrito":"CARAMPOMA","CodProvincia":133},
        {"CodDistrito":1323,"NomDistrito":"CHICLA","CodProvincia":133},
        {"CodDistrito":1324,"NomDistrito":"CUENCA","CodProvincia":133},
        {"CodDistrito":1325,"NomDistrito":"HUACHUPAMPA","CodProvincia":133},
        {"CodDistrito":1326,"NomDistrito":"HUANZA","CodProvincia":133},
        {"CodDistrito":1327,"NomDistrito":"HUAROCHIRI","CodProvincia":133},
        {"CodDistrito":1328,"NomDistrito":"LAHUAYTAMBO","CodProvincia":133},
        {"CodDistrito":1329,"NomDistrito":"LANGA","CodProvincia":133},
        {"CodDistrito":1330,"NomDistrito":"LARAOS","CodProvincia":133},
        {"CodDistrito":1331,"NomDistrito":"MARIATANA","CodProvincia":133},
        {"CodDistrito":1332,"NomDistrito":"MATUCANA","CodProvincia":133},
        {"CodDistrito":1333,"NomDistrito":"RICARDO PALMA","CodProvincia":133},
        {"CodDistrito":1334,"NomDistrito":"SAN ANDRÉS DE TUPICOCHA","CodProvincia":133},
        {"CodDistrito":1335,"NomDistrito":"SAN ANTONIO","CodProvincia":133},
        {"CodDistrito":1336,"NomDistrito":"SAN BARTOLOMÉ","CodProvincia":133},
        {"CodDistrito":1337,"NomDistrito":"SAN DAMIAN","CodProvincia":133},
        {"CodDistrito":1338,"NomDistrito":"SANGALLAYA","CodProvincia":133},
        {"CodDistrito":1339,"NomDistrito":"SAN JUAN DE IRIS","CodProvincia":133},
        {"CodDistrito":1340,"NomDistrito":"SAN JUAN DE TANTARANCHE","CodProvincia":133},
        {"CodDistrito":1341,"NomDistrito":"SAN LORENZO DE QUINTI","CodProvincia":133},
        {"CodDistrito":1342,"NomDistrito":"SAN MATEO","CodProvincia":133},
        {"CodDistrito":1343,"NomDistrito":"SAN MATEO DE OTAO","CodProvincia":133},
        {"CodDistrito":1344,"NomDistrito":"SAN PEDRO DE CASTA","CodProvincia":133},
        {"CodDistrito":1345,"NomDistrito":"SAN PEDRO DE HUANCAYRE","CodProvincia":133},
        {"CodDistrito":1346,"NomDistrito":"SANTA CRUZ DE COCACHACRA","CodProvincia":133},
        {"CodDistrito":1347,"NomDistrito":"SANTA EULALIA","CodProvincia":133},
        {"CodDistrito":1348,"NomDistrito":"SANTIAGO DE ANCHUCAYA","CodProvincia":133},
        {"CodDistrito":1349,"NomDistrito":"SANTIAGO DE TUNA","CodProvincia":133},
        {"CodDistrito":1350,"NomDistrito":"SANTO DOMINGO DE LOS OLLEROS","CodProvincia":133},
        {"CodDistrito":1351,"NomDistrito":"SURCO","CodProvincia":133},
        {"CodDistrito":1352,"NomDistrito":"AMBAR","CodProvincia":134},
        {"CodDistrito":1353,"NomDistrito":"CALETA DE CARQUIN","CodProvincia":134},
        {"CodDistrito":1354,"NomDistrito":"CHECRAS","CodProvincia":134},
        {"CodDistrito":1355,"NomDistrito":"HUACHO","CodProvincia":134},
        {"CodDistrito":1356,"NomDistrito":"HUALMAY","CodProvincia":134},
        {"CodDistrito":1357,"NomDistrito":"HUAURA","CodProvincia":134},
        {"CodDistrito":1358,"NomDistrito":"LEONCIO PRADO","CodProvincia":134},
        {"CodDistrito":1359,"NomDistrito":"PACCHO","CodProvincia":134},
        {"CodDistrito":1360,"NomDistrito":"SANTA LEONOR","CodProvincia":134},
        {"CodDistrito":1361,"NomDistrito":"SANTA MARÍA","CodProvincia":134},
        {"CodDistrito":1362,"NomDistrito":"SAYAN","CodProvincia":134},
        {"CodDistrito":1363,"NomDistrito":"VEGUETA","CodProvincia":134},
        {"CodDistrito":1364,"NomDistrito":"ANCÓN","CodProvincia":135},
        {"CodDistrito":1365,"NomDistrito":"ATE","CodProvincia":135},
        {"CodDistrito":1366,"NomDistrito":"BARRANCO","CodProvincia":135},
        {"CodDistrito":1367,"NomDistrito":"BREÑA","CodProvincia":135},
        {"CodDistrito":1368,"NomDistrito":"CARABAYLLO","CodProvincia":135},
        {"CodDistrito":1369,"NomDistrito":"CHACLACAYO","CodProvincia":135},
        {"CodDistrito":1370,"NomDistrito":"CHORRILLOS","CodProvincia":135},
        {"CodDistrito":1371,"NomDistrito":"CIENEGUILLA","CodProvincia":135},
        {"CodDistrito":1372,"NomDistrito":"COMAS","CodProvincia":135},
        {"CodDistrito":1373,"NomDistrito":"EL AGUSTINO","CodProvincia":135},
        {"CodDistrito":1374,"NomDistrito":"INDEPENDENCIA","CodProvincia":135},
        {"CodDistrito":1375,"NomDistrito":"JESÚS MARÍA","CodProvincia":135},
        {"CodDistrito":1376,"NomDistrito":"LA MOLINA","CodProvincia":135},
        {"CodDistrito":1377,"NomDistrito":"LA VICTORIA","CodProvincia":135},
        {"CodDistrito":1378,"NomDistrito":"LIMA","CodProvincia":135},
        {"CodDistrito":1379,"NomDistrito":"LINCE","CodProvincia":135},
        {"CodDistrito":1380,"NomDistrito":"LOS OLIVOS","CodProvincia":135},
        {"CodDistrito":1381,"NomDistrito":"LURIGANCHO","CodProvincia":135},
        {"CodDistrito":1382,"NomDistrito":"LURIN","CodProvincia":135},
        {"CodDistrito":1383,"NomDistrito":"MAGDALENA DEL MAR","CodProvincia":135},
        {"CodDistrito":1384,"NomDistrito":"MIRAFLORES","CodProvincia":135},
        {"CodDistrito":1385,"NomDistrito":"PACHACAMAC","CodProvincia":135},
        {"CodDistrito":1386,"NomDistrito":"PUCUSANA","CodProvincia":135},
        {"CodDistrito":1387,"NomDistrito":"PUEBLO LIBRE","CodProvincia":135},
        {"CodDistrito":1388,"NomDistrito":"PUENTE PIEDRA","CodProvincia":135},
        {"CodDistrito":1389,"NomDistrito":"PUNTA HERMOSA","CodProvincia":135},
        {"CodDistrito":1390,"NomDistrito":"PUNTA NEGRA","CodProvincia":135},
        {"CodDistrito":1391,"NomDistrito":"RÍMAC","CodProvincia":135},
        {"CodDistrito":1392,"NomDistrito":"SAN BARTOLO","CodProvincia":135},
        {"CodDistrito":1393,"NomDistrito":"SAN BORJA","CodProvincia":135},
        {"CodDistrito":1394,"NomDistrito":"SAN ISIDRO","CodProvincia":135},
        {"CodDistrito":1395,"NomDistrito":"SAN JUAN DE LURIGANCHO","CodProvincia":135},
        {"CodDistrito":1396,"NomDistrito":"SAN JUAN DE MIRAFLORES","CodProvincia":135},
        {"CodDistrito":1397,"NomDistrito":"SAN LUIS","CodProvincia":135},
        {"CodDistrito":1398,"NomDistrito":"SAN MARTÍN DE PORRES","CodProvincia":135},
        {"CodDistrito":1399,"NomDistrito":"SAN MIGUEL","CodProvincia":135},
        {"CodDistrito":1400,"NomDistrito":"SANTA ANITA","CodProvincia":135},
        {"CodDistrito":1401,"NomDistrito":"SANTA MARÍA DEL MAR","CodProvincia":135},
        {"CodDistrito":1402,"NomDistrito":"SANTA ROSA","CodProvincia":135},
        {"CodDistrito":1403,"NomDistrito":"SANTIAGO DE SURCO","CodProvincia":135},
        {"CodDistrito":1404,"NomDistrito":"SURQUILLO","CodProvincia":135},
        {"CodDistrito":1405,"NomDistrito":"VILLA EL SALVADOR","CodProvincia":135},
        {"CodDistrito":1406,"NomDistrito":"VILLA MARÍA DEL TRIUNFO","CodProvincia":135},
        {"CodDistrito":1407,"NomDistrito":"ANDAJES","CodProvincia":136},
        {"CodDistrito":1408,"NomDistrito":"CAUJUL","CodProvincia":136},
        {"CodDistrito":1409,"NomDistrito":"COCHAMARCA","CodProvincia":136},
        {"CodDistrito":1410,"NomDistrito":"NAVAN","CodProvincia":136},
        {"CodDistrito":1411,"NomDistrito":"OYÓN","CodProvincia":136},
        {"CodDistrito":1412,"NomDistrito":"PACHANGARA","CodProvincia":136},
        {"CodDistrito":1413,"NomDistrito":"ALIS","CodProvincia":137},
        {"CodDistrito":1414,"NomDistrito":"ALLAUCA","CodProvincia":137},
        {"CodDistrito":1415,"NomDistrito":"AYAVIRI","CodProvincia":137},
        {"CodDistrito":1416,"NomDistrito":"AZÁNGARO","CodProvincia":137},
        {"CodDistrito":1417,"NomDistrito":"CACRA","CodProvincia":137},
        {"CodDistrito":1418,"NomDistrito":"CARANIA","CodProvincia":137},
        {"CodDistrito":1419,"NomDistrito":"CATAHUASI","CodProvincia":137},
        {"CodDistrito":1420,"NomDistrito":"CHOCOS","CodProvincia":137},
        {"CodDistrito":1421,"NomDistrito":"COCHAS","CodProvincia":137},
        {"CodDistrito":1422,"NomDistrito":"COLONIA","CodProvincia":137},
        {"CodDistrito":1423,"NomDistrito":"HONGOS","CodProvincia":137},
        {"CodDistrito":1424,"NomDistrito":"HUAMPARA","CodProvincia":137},
        {"CodDistrito":1425,"NomDistrito":"HUANCAYA","CodProvincia":137},
        {"CodDistrito":1426,"NomDistrito":"HUAÑEC","CodProvincia":137},
        {"CodDistrito":1427,"NomDistrito":"HUANGASCAR","CodProvincia":137},
        {"CodDistrito":1428,"NomDistrito":"HUANTAN","CodProvincia":137},
        {"CodDistrito":1429,"NomDistrito":"LARAOS","CodProvincia":137},
        {"CodDistrito":1430,"NomDistrito":"LINCHA","CodProvincia":137},
        {"CodDistrito":1431,"NomDistrito":"MADEAN","CodProvincia":137},
        {"CodDistrito":1432,"NomDistrito":"MIRAFLORES","CodProvincia":137},
        {"CodDistrito":1433,"NomDistrito":"OMAS","CodProvincia":137},
        {"CodDistrito":1434,"NomDistrito":"PUTINZA","CodProvincia":137},
        {"CodDistrito":1435,"NomDistrito":"QUINCHES","CodProvincia":137},
        {"CodDistrito":1436,"NomDistrito":"QUINOCAY","CodProvincia":137},
        {"CodDistrito":1437,"NomDistrito":"SAN JOAQUÍN","CodProvincia":137},
        {"CodDistrito":1438,"NomDistrito":"SAN PEDRO DE PILAS","CodProvincia":137},
        {"CodDistrito":1439,"NomDistrito":"TANTA","CodProvincia":137},
        {"CodDistrito":1440,"NomDistrito":"TAURIPAMPA","CodProvincia":137},
        {"CodDistrito":1441,"NomDistrito":"TOMAS","CodProvincia":137},
        {"CodDistrito":1442,"NomDistrito":"TUPE","CodProvincia":137},
        {"CodDistrito":1443,"NomDistrito":"VIÑAC","CodProvincia":137},
        {"CodDistrito":1444,"NomDistrito":"VITIS","CodProvincia":137},
        {"CodDistrito":1445,"NomDistrito":"YAUYOS","CodProvincia":137},
        {"CodDistrito":1446,"NomDistrito":"BALSAPUERTO","CodProvincia":138},
        {"CodDistrito":1447,"NomDistrito":"JEBEROS","CodProvincia":138},
        {"CodDistrito":1448,"NomDistrito":"LAGUNAS","CodProvincia":138},
        {"CodDistrito":1449,"NomDistrito":"SANTA CRUZ","CodProvincia":138},
        {"CodDistrito":1450,"NomDistrito":"TENIENTE CESAR LÓPEZ ROJAS","CodProvincia":138},
        {"CodDistrito":1451,"NomDistrito":"YURIMAGUAS","CodProvincia":138},
        {"CodDistrito":1452,"NomDistrito":"ANDOAS","CodProvincia":139},
        {"CodDistrito":1453,"NomDistrito":"BARRANCA","CodProvincia":139},
        {"CodDistrito":1454,"NomDistrito":"CAHUAPANAS","CodProvincia":139},
        {"CodDistrito":1455,"NomDistrito":"MANSERICHE","CodProvincia":139},
        {"CodDistrito":1456,"NomDistrito":"MORONA","CodProvincia":139},
        {"CodDistrito":1457,"NomDistrito":"PASTAZA","CodProvincia":139},
        {"CodDistrito":1458,"NomDistrito":"NAUTA","CodProvincia":140},
        {"CodDistrito":1459,"NomDistrito":"PARINARI","CodProvincia":140},
        {"CodDistrito":1460,"NomDistrito":"TIGRE","CodProvincia":140},
        {"CodDistrito":1461,"NomDistrito":"TROMPETEROS","CodProvincia":140},
        {"CodDistrito":1462,"NomDistrito":"URARINAS","CodProvincia":140},
        {"CodDistrito":1463,"NomDistrito":"PEBAS","CodProvincia":141},
        {"CodDistrito":1464,"NomDistrito":"RAMÓN CASTILLA","CodProvincia":141},
        {"CodDistrito":1465,"NomDistrito":"SAN PABLO","CodProvincia":141},
        {"CodDistrito":1466,"NomDistrito":"YAVARI","CodProvincia":141},
        {"CodDistrito":1467,"NomDistrito":"ALTO NANAY","CodProvincia":142},
        {"CodDistrito":1468,"NomDistrito":"BELÉN","CodProvincia":142},
        {"CodDistrito":1469,"NomDistrito":"FERNANDO LORES","CodProvincia":142},
        {"CodDistrito":1470,"NomDistrito":"INDIANA","CodProvincia":142},
        {"CodDistrito":1471,"NomDistrito":"IQUITOS","CodProvincia":142},
        {"CodDistrito":1472,"NomDistrito":"LAS AMAZONAS","CodProvincia":142},
        {"CodDistrito":1473,"NomDistrito":"MAZAN","CodProvincia":142},
        {"CodDistrito":1474,"NomDistrito":"NAPO","CodProvincia":142},
        {"CodDistrito":1475,"NomDistrito":"PUNCHANA","CodProvincia":142},
        {"CodDistrito":1476,"NomDistrito":"SAN JUAN BAUTISTA","CodProvincia":142},
        {"CodDistrito":1477,"NomDistrito":"TORRES CAUSANA","CodProvincia":142},
        {"CodDistrito":1478,"NomDistrito":"PUTUMAYO","CodProvincia":143},
        {"CodDistrito":1479,"NomDistrito":"ROSA PANDURO","CodProvincia":143},
        {"CodDistrito":1480,"NomDistrito":"TENIENTE MANUEL CLAVERO","CodProvincia":143},
        {"CodDistrito":1481,"NomDistrito":"YAGUAS","CodProvincia":143},
        {"CodDistrito":1482,"NomDistrito":"ALTO TAPICHE","CodProvincia":144},
        {"CodDistrito":1483,"NomDistrito":"CAPELO","CodProvincia":144},
        {"CodDistrito":1484,"NomDistrito":"EMILIO SAN MARTÍN","CodProvincia":144},
        {"CodDistrito":1485,"NomDistrito":"JENARO HERRERA","CodProvincia":144},
        {"CodDistrito":1486,"NomDistrito":"MAQUIA","CodProvincia":144},
        {"CodDistrito":1487,"NomDistrito":"PUINAHUA","CodProvincia":144},
        {"CodDistrito":1488,"NomDistrito":"REQUENA","CodProvincia":144},
        {"CodDistrito":1489,"NomDistrito":"SAQUENA","CodProvincia":144},
        {"CodDistrito":1490,"NomDistrito":"SOPLIN","CodProvincia":144},
        {"CodDistrito":1491,"NomDistrito":"TAPICHE","CodProvincia":144},
        {"CodDistrito":1492,"NomDistrito":"YAQUERANA","CodProvincia":144},
        {"CodDistrito":1493,"NomDistrito":"CONTAMANA","CodProvincia":145},
        {"CodDistrito":1494,"NomDistrito":"INAHUAYA","CodProvincia":145},
        {"CodDistrito":1495,"NomDistrito":"PADRE MÁRQUEZ","CodProvincia":145},
        {"CodDistrito":1496,"NomDistrito":"PAMPA HERMOSA","CodProvincia":145},
        {"CodDistrito":1497,"NomDistrito":"SARAYACU","CodProvincia":145},
        {"CodDistrito":1498,"NomDistrito":"VARGAS GUERRA","CodProvincia":145},
        {"CodDistrito":1499,"NomDistrito":"FITZCARRALD","CodProvincia":146},
        {"CodDistrito":1500,"NomDistrito":"HUEPETUHE","CodProvincia":146},
        {"CodDistrito":1501,"NomDistrito":"MADRE DE DIOS","CodProvincia":146},
        {"CodDistrito":1502,"NomDistrito":"MANU","CodProvincia":146},
        {"CodDistrito":1503,"NomDistrito":"IBERIA","CodProvincia":147},
        {"CodDistrito":1504,"NomDistrito":"IÑAPARI","CodProvincia":147},
        {"CodDistrito":1505,"NomDistrito":"TAHUAMANU","CodProvincia":147},
        {"CodDistrito":1506,"NomDistrito":"INAMBARI","CodProvincia":148},
        {"CodDistrito":1507,"NomDistrito":"LABERINTO","CodProvincia":148},
        {"CodDistrito":1508,"NomDistrito":"LAS PIEDRAS","CodProvincia":148},
        {"CodDistrito":1509,"NomDistrito":"TAMBOPATA","CodProvincia":148},
        {"CodDistrito":1510,"NomDistrito":"CHOJATA","CodProvincia":149},
        {"CodDistrito":1511,"NomDistrito":"COALAQUE","CodProvincia":149},
        {"CodDistrito":1512,"NomDistrito":"ICHUÑA","CodProvincia":149},
        {"CodDistrito":1513,"NomDistrito":"LA CAPILLA","CodProvincia":149},
        {"CodDistrito":1514,"NomDistrito":"LLOQUE","CodProvincia":149},
        {"CodDistrito":1515,"NomDistrito":"MATALAQUE","CodProvincia":149},
        {"CodDistrito":1516,"NomDistrito":"OMATE","CodProvincia":149},
        {"CodDistrito":1517,"NomDistrito":"PUQUINA","CodProvincia":149},
        {"CodDistrito":1518,"NomDistrito":"QUINISTAQUILLAS","CodProvincia":149},
        {"CodDistrito":1519,"NomDistrito":"UBINAS","CodProvincia":149},
        {"CodDistrito":1520,"NomDistrito":"YUNGA","CodProvincia":149},
        {"CodDistrito":1521,"NomDistrito":"EL ALGARROBAL","CodProvincia":150},
        {"CodDistrito":1522,"NomDistrito":"ILO","CodProvincia":150},
        {"CodDistrito":1523,"NomDistrito":"PACOCHA","CodProvincia":150},
        {"CodDistrito":1524,"NomDistrito":"CARUMAS","CodProvincia":151},
        {"CodDistrito":1525,"NomDistrito":"CUCHUMBAYA","CodProvincia":151},
        {"CodDistrito":1526,"NomDistrito":"MOQUEGUA","CodProvincia":151},
        {"CodDistrito":1527,"NomDistrito":"SAMEGUA","CodProvincia":151},
        {"CodDistrito":1528,"NomDistrito":"SAN CRISTÓBAL","CodProvincia":151},
        {"CodDistrito":1529,"NomDistrito":"TORATA","CodProvincia":151},
        {"CodDistrito":1530,"NomDistrito":"CHACAYAN","CodProvincia":152},
        {"CodDistrito":1531,"NomDistrito":"GOYLLARISQUIZGA","CodProvincia":152},
        {"CodDistrito":1532,"NomDistrito":"PAUCAR","CodProvincia":152},
        {"CodDistrito":1533,"NomDistrito":"SAN PEDRO DE PILLAO","CodProvincia":152},
        {"CodDistrito":1534,"NomDistrito":"SANTA ANA DE TUSI","CodProvincia":152},
        {"CodDistrito":1535,"NomDistrito":"TAPUC","CodProvincia":152},
        {"CodDistrito":1536,"NomDistrito":"VILCABAMBA","CodProvincia":152},
        {"CodDistrito":1537,"NomDistrito":"YANAHUANCA","CodProvincia":152},
        {"CodDistrito":1538,"NomDistrito":"CHONTABAMBA","CodProvincia":153},
        {"CodDistrito":1539,"NomDistrito":"CONSTITUCIÓN","CodProvincia":153},
        {"CodDistrito":1540,"NomDistrito":"HUANCABAMBA","CodProvincia":153},
        {"CodDistrito":1541,"NomDistrito":"OXAPAMPA","CodProvincia":153},
        {"CodDistrito":1542,"NomDistrito":"PALCAZU","CodProvincia":153},
        {"CodDistrito":1543,"NomDistrito":"POZUZO","CodProvincia":153},
        {"CodDistrito":1544,"NomDistrito":"PUERTO BERMÚDEZ","CodProvincia":153},
        {"CodDistrito":1545,"NomDistrito":"VILLA RICA","CodProvincia":153},
        {"CodDistrito":1546,"NomDistrito":"CHAUPIMARCA","CodProvincia":154},
        {"CodDistrito":1547,"NomDistrito":"HUACHON","CodProvincia":154},
        {"CodDistrito":1548,"NomDistrito":"HUARIACA","CodProvincia":154},
        {"CodDistrito":1549,"NomDistrito":"HUAYLLAY","CodProvincia":154},
        {"CodDistrito":1550,"NomDistrito":"NINACACA","CodProvincia":154},
        {"CodDistrito":1551,"NomDistrito":"PALLANCHACRA","CodProvincia":154},
        {"CodDistrito":1552,"NomDistrito":"PAUCARTAMBO","CodProvincia":154},
        {"CodDistrito":1553,"NomDistrito":"SAN FRANCISCO DE ASÍS DE YARUSYACAN","CodProvincia":154},
        {"CodDistrito":1554,"NomDistrito":"SIMON BOLÍVAR","CodProvincia":154},
        {"CodDistrito":1555,"NomDistrito":"TICLACAYAN","CodProvincia":154},
        {"CodDistrito":1556,"NomDistrito":"TINYAHUARCO","CodProvincia":154},
        {"CodDistrito":1557,"NomDistrito":"VICCO","CodProvincia":154},
        {"CodDistrito":1558,"NomDistrito":"YANACANCHA","CodProvincia":154},
        {"CodDistrito":1559,"NomDistrito":"AYABACA","CodProvincia":155},
        {"CodDistrito":1560,"NomDistrito":"FRIAS","CodProvincia":155},
        {"CodDistrito":1561,"NomDistrito":"JILILI","CodProvincia":155},
        {"CodDistrito":1562,"NomDistrito":"LAGUNAS","CodProvincia":155},
        {"CodDistrito":1563,"NomDistrito":"MONTERO","CodProvincia":155},
        {"CodDistrito":1564,"NomDistrito":"PACAIPAMPA","CodProvincia":155},
        {"CodDistrito":1565,"NomDistrito":"PAIMAS","CodProvincia":155},
        {"CodDistrito":1566,"NomDistrito":"SAPILLICA","CodProvincia":155},
        {"CodDistrito":1567,"NomDistrito":"SICCHEZ","CodProvincia":155},
        {"CodDistrito":1568,"NomDistrito":"SUYO","CodProvincia":155},
        {"CodDistrito":1569,"NomDistrito":"CANCHAQUE","CodProvincia":156},
        {"CodDistrito":1570,"NomDistrito":"EL CARMEN DE LA FRONTERA","CodProvincia":156},
        {"CodDistrito":1571,"NomDistrito":"HUANCABAMBA","CodProvincia":156},
        {"CodDistrito":1572,"NomDistrito":"HUARMACA","CodProvincia":156},
        {"CodDistrito":1573,"NomDistrito":"LALAQUIZ","CodProvincia":156},
        {"CodDistrito":1574,"NomDistrito":"SAN MIGUEL DE EL FAIQUE","CodProvincia":156},
        {"CodDistrito":1575,"NomDistrito":"SONDOR","CodProvincia":156},
        {"CodDistrito":1576,"NomDistrito":"SONDORILLO","CodProvincia":156},
        {"CodDistrito":1577,"NomDistrito":"BUENOS AIRES","CodProvincia":157},
        {"CodDistrito":1578,"NomDistrito":"CHALACO","CodProvincia":157},
        {"CodDistrito":1579,"NomDistrito":"CHULUCANAS","CodProvincia":157},
        {"CodDistrito":1580,"NomDistrito":"LA MATANZA","CodProvincia":157},
        {"CodDistrito":1581,"NomDistrito":"MORROPON","CodProvincia":157},
        {"CodDistrito":1582,"NomDistrito":"SALITRAL","CodProvincia":157},
        {"CodDistrito":1583,"NomDistrito":"SAN JUAN DE BIGOTE","CodProvincia":157},
        {"CodDistrito":1584,"NomDistrito":"SANTA CATALINA DE MOSSA","CodProvincia":157},
        {"CodDistrito":1585,"NomDistrito":"SANTO DOMINGO","CodProvincia":157},
        {"CodDistrito":1586,"NomDistrito":"YAMANGO","CodProvincia":157},
        {"CodDistrito":1587,"NomDistrito":"AMOTAPE","CodProvincia":158},
        {"CodDistrito":1588,"NomDistrito":"ARENAL","CodProvincia":158},
        {"CodDistrito":1589,"NomDistrito":"COLAN","CodProvincia":158},
        {"CodDistrito":1590,"NomDistrito":"LA HUACA","CodProvincia":158},
        {"CodDistrito":1591,"NomDistrito":"PAITA","CodProvincia":158},
        {"CodDistrito":1592,"NomDistrito":"TAMARINDO","CodProvincia":158},
        {"CodDistrito":1593,"NomDistrito":"VICHAYAL","CodProvincia":158},
        {"CodDistrito":1594,"NomDistrito":"CASTILLA","CodProvincia":159},
        {"CodDistrito":1595,"NomDistrito":"CATACAOS","CodProvincia":159},
        {"CodDistrito":1596,"NomDistrito":"CURA MORI","CodProvincia":159},
        {"CodDistrito":1597,"NomDistrito":"EL TALLAN","CodProvincia":159},
        {"CodDistrito":1598,"NomDistrito":"LA ARENA","CodProvincia":159},
        {"CodDistrito":1599,"NomDistrito":"LAS LOMAS","CodProvincia":159},
        {"CodDistrito":1600,"NomDistrito":"LA UNIÓN","CodProvincia":159},
        {"CodDistrito":1601,"NomDistrito":"PIURA","CodProvincia":159},
        {"CodDistrito":1602,"NomDistrito":"TAMBO GRANDE","CodProvincia":159},
        {"CodDistrito":1603,"NomDistrito":"VEINTISEIS DE OCTUBRE","CodProvincia":159},
        {"CodDistrito":1604,"NomDistrito":"BELLAVISTA DE LA UNIÓN","CodProvincia":160},
        {"CodDistrito":1605,"NomDistrito":"BERNAL","CodProvincia":160},
        {"CodDistrito":1606,"NomDistrito":"CRISTO NOS VALGA","CodProvincia":160},
        {"CodDistrito":1607,"NomDistrito":"RINCONADA LLICUAR","CodProvincia":160},
        {"CodDistrito":1608,"NomDistrito":"SECHURA","CodProvincia":160},
        {"CodDistrito":1609,"NomDistrito":"VICE","CodProvincia":160},
        {"CodDistrito":1610,"NomDistrito":"BELLAVISTA","CodProvincia":161},
        {"CodDistrito":1611,"NomDistrito":"IGNACIO ESCUDERO","CodProvincia":161},
        {"CodDistrito":1612,"NomDistrito":"LANCONES","CodProvincia":161},
        {"CodDistrito":1613,"NomDistrito":"MARCAVELICA","CodProvincia":161},
        {"CodDistrito":1614,"NomDistrito":"MIGUEL CHECA","CodProvincia":161},
        {"CodDistrito":1615,"NomDistrito":"QUERECOTILLO","CodProvincia":161},
        {"CodDistrito":1616,"NomDistrito":"SALITRAL","CodProvincia":161},
        {"CodDistrito":1617,"NomDistrito":"SULLANA","CodProvincia":161},
        {"CodDistrito":1618,"NomDistrito":"EL ALTO","CodProvincia":162},
        {"CodDistrito":1619,"NomDistrito":"LA BREA","CodProvincia":162},
        {"CodDistrito":1620,"NomDistrito":"LOBITOS","CodProvincia":162},
        {"CodDistrito":1621,"NomDistrito":"LOS ORGANOS","CodProvincia":162},
        {"CodDistrito":1622,"NomDistrito":"MANCORA","CodProvincia":162},
        {"CodDistrito":1623,"NomDistrito":"PARIÑAS","CodProvincia":162},
        {"CodDistrito":1624,"NomDistrito":"ACHAYA","CodProvincia":163},
        {"CodDistrito":1625,"NomDistrito":"ARAPA","CodProvincia":163},
        {"CodDistrito":1626,"NomDistrito":"ASILLO","CodProvincia":163},
        {"CodDistrito":1627,"NomDistrito":"AZÁNGARO","CodProvincia":163},
        {"CodDistrito":1628,"NomDistrito":"CAMINACA","CodProvincia":163},
        {"CodDistrito":1629,"NomDistrito":"CHUPA","CodProvincia":163},
        {"CodDistrito":1630,"NomDistrito":"JOSÉ DOMINGO CHOQUEHUANCA","CodProvincia":163},
        {"CodDistrito":1631,"NomDistrito":"MUÑANI","CodProvincia":163},
        {"CodDistrito":1632,"NomDistrito":"POTONI","CodProvincia":163},
        {"CodDistrito":1633,"NomDistrito":"SAMAN","CodProvincia":163},
        {"CodDistrito":1634,"NomDistrito":"SAN ANTON","CodProvincia":163},
        {"CodDistrito":1635,"NomDistrito":"SAN JOSÉ","CodProvincia":163},
        {"CodDistrito":1636,"NomDistrito":"SAN JUAN DE SALINAS","CodProvincia":163},
        {"CodDistrito":1637,"NomDistrito":"SANTIAGO DE PUPUJA","CodProvincia":163},
        {"CodDistrito":1638,"NomDistrito":"TIRAPATA","CodProvincia":163},
        {"CodDistrito":1639,"NomDistrito":"AJOYANI","CodProvincia":164},
        {"CodDistrito":1640,"NomDistrito":"AYAPATA","CodProvincia":164},
        {"CodDistrito":1641,"NomDistrito":"COASA","CodProvincia":164},
        {"CodDistrito":1642,"NomDistrito":"CORANI","CodProvincia":164},
        {"CodDistrito":1643,"NomDistrito":"CRUCERO","CodProvincia":164},
        {"CodDistrito":1644,"NomDistrito":"ITUATA","CodProvincia":164},
        {"CodDistrito":1645,"NomDistrito":"MACUSANI","CodProvincia":164},
        {"CodDistrito":1646,"NomDistrito":"OLLACHEA","CodProvincia":164},
        {"CodDistrito":1647,"NomDistrito":"SAN GABAN","CodProvincia":164},
        {"CodDistrito":1648,"NomDistrito":"USICAYOS","CodProvincia":164},
        {"CodDistrito":1649,"NomDistrito":"DESAGUADERO","CodProvincia":165},
        {"CodDistrito":1650,"NomDistrito":"HUACULLANI","CodProvincia":165},
        {"CodDistrito":1651,"NomDistrito":"JULI","CodProvincia":165},
        {"CodDistrito":1652,"NomDistrito":"KELLUYO","CodProvincia":165},
        {"CodDistrito":1653,"NomDistrito":"PISACOMA","CodProvincia":165},
        {"CodDistrito":1654,"NomDistrito":"POMATA","CodProvincia":165},
        {"CodDistrito":1655,"NomDistrito":"ZEPITA","CodProvincia":165},
        {"CodDistrito":1656,"NomDistrito":"CAPAZO","CodProvincia":166},
        {"CodDistrito":1657,"NomDistrito":"CONDURIRI","CodProvincia":166},
        {"CodDistrito":1658,"NomDistrito":"ILAVE","CodProvincia":166},
        {"CodDistrito":1659,"NomDistrito":"PILCUYO","CodProvincia":166},
        {"CodDistrito":1660,"NomDistrito":"SANTA ROSA","CodProvincia":166},
        {"CodDistrito":1661,"NomDistrito":"COJATA","CodProvincia":167},
        {"CodDistrito":1662,"NomDistrito":"HUANCANE","CodProvincia":167},
        {"CodDistrito":1663,"NomDistrito":"HUATASANI","CodProvincia":167},
        {"CodDistrito":1664,"NomDistrito":"INCHUPALLA","CodProvincia":167},
        {"CodDistrito":1665,"NomDistrito":"PUSI","CodProvincia":167},
        {"CodDistrito":1666,"NomDistrito":"ROSASPATA","CodProvincia":167},
        {"CodDistrito":1667,"NomDistrito":"TARACO","CodProvincia":167},
        {"CodDistrito":1668,"NomDistrito":"VILQUE CHICO","CodProvincia":167},
        {"CodDistrito":1669,"NomDistrito":"CABANILLA","CodProvincia":168},
        {"CodDistrito":1670,"NomDistrito":"CALAPUJA","CodProvincia":168},
        {"CodDistrito":1671,"NomDistrito":"LAMPA","CodProvincia":168},
        {"CodDistrito":1672,"NomDistrito":"NICASIO","CodProvincia":168},
        {"CodDistrito":1673,"NomDistrito":"OCUVIRI","CodProvincia":168},
        {"CodDistrito":1674,"NomDistrito":"PALCA","CodProvincia":168},
        {"CodDistrito":1675,"NomDistrito":"PARATIA","CodProvincia":168},
        {"CodDistrito":1676,"NomDistrito":"PUCARA","CodProvincia":168},
        {"CodDistrito":1677,"NomDistrito":"SANTA LUCIA","CodProvincia":168},
        {"CodDistrito":1678,"NomDistrito":"VILAVILA","CodProvincia":168},
        {"CodDistrito":1679,"NomDistrito":"ANTAUTA","CodProvincia":169},
        {"CodDistrito":1680,"NomDistrito":"AYAVIRI","CodProvincia":169},
        {"CodDistrito":1681,"NomDistrito":"CUPI","CodProvincia":169},
        {"CodDistrito":1682,"NomDistrito":"LLALLI","CodProvincia":169},
        {"CodDistrito":1683,"NomDistrito":"MACARI","CodProvincia":169},
        {"CodDistrito":1684,"NomDistrito":"NUÑOA","CodProvincia":169},
        {"CodDistrito":1685,"NomDistrito":"ORURILLO","CodProvincia":169},
        {"CodDistrito":1686,"NomDistrito":"SANTA ROSA","CodProvincia":169},
        {"CodDistrito":1687,"NomDistrito":"UMACHIRI","CodProvincia":169},
        {"CodDistrito":1688,"NomDistrito":"CONIMA","CodProvincia":170},
        {"CodDistrito":1689,"NomDistrito":"HUAYRAPATA","CodProvincia":170},
        {"CodDistrito":1690,"NomDistrito":"MOHO","CodProvincia":170},
        {"CodDistrito":1691,"NomDistrito":"TILALI","CodProvincia":170},
        {"CodDistrito":1692,"NomDistrito":"ACORA","CodProvincia":171},
        {"CodDistrito":1693,"NomDistrito":"AMANTANI","CodProvincia":171},
        {"CodDistrito":1694,"NomDistrito":"ATUNCOLLA","CodProvincia":171},
        {"CodDistrito":1695,"NomDistrito":"CAPACHICA","CodProvincia":171},
        {"CodDistrito":1696,"NomDistrito":"CHUCUITO","CodProvincia":171},
        {"CodDistrito":1697,"NomDistrito":"COATA","CodProvincia":171},
        {"CodDistrito":1698,"NomDistrito":"HUATA","CodProvincia":171},
        {"CodDistrito":1699,"NomDistrito":"MAÑAZO","CodProvincia":171},
        {"CodDistrito":1700,"NomDistrito":"PAUCARCOLLA","CodProvincia":171},
        {"CodDistrito":1701,"NomDistrito":"PICHACANI","CodProvincia":171},
        {"CodDistrito":1702,"NomDistrito":"PLATERIA","CodProvincia":171},
        {"CodDistrito":1703,"NomDistrito":"PUNO","CodProvincia":171},
        {"CodDistrito":1704,"NomDistrito":"SAN ANTONIO","CodProvincia":171},
        {"CodDistrito":1705,"NomDistrito":"TIQUILLACA","CodProvincia":171},
        {"CodDistrito":1706,"NomDistrito":"VILQUE","CodProvincia":171},
        {"CodDistrito":1707,"NomDistrito":"ANANEA","CodProvincia":172},
        {"CodDistrito":1708,"NomDistrito":"PEDRO VILCA APAZA","CodProvincia":172},
        {"CodDistrito":1709,"NomDistrito":"PUTINA","CodProvincia":172},
        {"CodDistrito":1710,"NomDistrito":"QUILCAPUNCU","CodProvincia":172},
        {"CodDistrito":1711,"NomDistrito":"SINA","CodProvincia":172},
        {"CodDistrito":1712,"NomDistrito":"ALTO INAMBARI","CodProvincia":173},
        {"CodDistrito":1713,"NomDistrito":"CUYOCUYO","CodProvincia":173},
        {"CodDistrito":1714,"NomDistrito":"LIMBANI","CodProvincia":173},
        {"CodDistrito":1715,"NomDistrito":"PATAMBUCO","CodProvincia":173},
        {"CodDistrito":1716,"NomDistrito":"PHARA","CodProvincia":173},
        {"CodDistrito":1717,"NomDistrito":"QUIACA","CodProvincia":173},
        {"CodDistrito":1718,"NomDistrito":"SANDIA","CodProvincia":173},
        {"CodDistrito":1719,"NomDistrito":"SAN JUAN DEL ORO","CodProvincia":173},
        {"CodDistrito":1720,"NomDistrito":"SAN PEDRO DE PUTINA PUNCO","CodProvincia":173},
        {"CodDistrito":1721,"NomDistrito":"YANAHUAYA","CodProvincia":173},
        {"CodDistrito":1722,"NomDistrito":"CABANA","CodProvincia":174},
        {"CodDistrito":1723,"NomDistrito":"CABANILLAS","CodProvincia":174},
        {"CodDistrito":1724,"NomDistrito":"CARACOTO","CodProvincia":174},
        {"CodDistrito":1725,"NomDistrito":"JULIACA","CodProvincia":174},
        {"CodDistrito":1726,"NomDistrito":"ANAPIA","CodProvincia":175},
        {"CodDistrito":1727,"NomDistrito":"COPANI","CodProvincia":175},
        {"CodDistrito":1728,"NomDistrito":"CUTURAPI","CodProvincia":175},
        {"CodDistrito":1729,"NomDistrito":"OLLARAYA","CodProvincia":175},
        {"CodDistrito":1730,"NomDistrito":"TINICACHI","CodProvincia":175},
        {"CodDistrito":1731,"NomDistrito":"UNICACHI","CodProvincia":175},
        {"CodDistrito":1732,"NomDistrito":"YUNGUYO","CodProvincia":175},
        {"CodDistrito":1733,"NomDistrito":"ALTO BIAVO","CodProvincia":176},
        {"CodDistrito":1734,"NomDistrito":"BAJO BIAVO","CodProvincia":176},
        {"CodDistrito":1735,"NomDistrito":"BELLAVISTA","CodProvincia":176},
        {"CodDistrito":1736,"NomDistrito":"HUALLAGA","CodProvincia":176},
        {"CodDistrito":1737,"NomDistrito":"SAN PABLO","CodProvincia":176},
        {"CodDistrito":1738,"NomDistrito":"SAN RAFAEL","CodProvincia":176},
        {"CodDistrito":1739,"NomDistrito":"AGUA BLANCA","CodProvincia":177},
        {"CodDistrito":1740,"NomDistrito":"SAN JOSÉ DE SISA","CodProvincia":177},
        {"CodDistrito":1741,"NomDistrito":"SAN MARTÍN","CodProvincia":177},
        {"CodDistrito":1742,"NomDistrito":"SANTA ROSA","CodProvincia":177},
        {"CodDistrito":1743,"NomDistrito":"SHATOJA","CodProvincia":177},
        {"CodDistrito":1744,"NomDistrito":"ALTO SAPOSOA","CodProvincia":178},
        {"CodDistrito":1745,"NomDistrito":"EL ESLABÓN","CodProvincia":178},
        {"CodDistrito":1746,"NomDistrito":"PISCOYACU","CodProvincia":178},
        {"CodDistrito":1747,"NomDistrito":"SACANCHE","CodProvincia":178},
        {"CodDistrito":1748,"NomDistrito":"SAPOSOA","CodProvincia":178},
        {"CodDistrito":1749,"NomDistrito":"TINGO DE SAPOSOA","CodProvincia":178},
        {"CodDistrito":1750,"NomDistrito":"ALONSO DE ALVARADO","CodProvincia":179},
        {"CodDistrito":1751,"NomDistrito":"BARRANQUITA","CodProvincia":179},
        {"CodDistrito":1752,"NomDistrito":"CAYNARACHI","CodProvincia":179},
        {"CodDistrito":1753,"NomDistrito":"CUÑUMBUQUI","CodProvincia":179},
        {"CodDistrito":1754,"NomDistrito":"LAMAS","CodProvincia":179},
        {"CodDistrito":1755,"NomDistrito":"PINTO RECODO","CodProvincia":179},
        {"CodDistrito":1756,"NomDistrito":"RUMISAPA","CodProvincia":179},
        {"CodDistrito":1757,"NomDistrito":"SAN ROQUE DE CUMBAZA","CodProvincia":179},
        {"CodDistrito":1758,"NomDistrito":"SHANAO","CodProvincia":179},
        {"CodDistrito":1759,"NomDistrito":"TABALOSOS","CodProvincia":179},
        {"CodDistrito":1760,"NomDistrito":"ZAPATERO","CodProvincia":179},
        {"CodDistrito":1761,"NomDistrito":"CAMPANILLA","CodProvincia":180},
        {"CodDistrito":1762,"NomDistrito":"HUICUNGO","CodProvincia":180},
        {"CodDistrito":1763,"NomDistrito":"JUANJUÍ","CodProvincia":180},
        {"CodDistrito":1764,"NomDistrito":"PACHIZA","CodProvincia":180},
        {"CodDistrito":1765,"NomDistrito":"PAJARILLO","CodProvincia":180},
        {"CodDistrito":1766,"NomDistrito":"CALZADA","CodProvincia":181},
        {"CodDistrito":1767,"NomDistrito":"HABANA","CodProvincia":181},
        {"CodDistrito":1768,"NomDistrito":"JEPELACIO","CodProvincia":181},
        {"CodDistrito":1769,"NomDistrito":"MOYOBAMBA","CodProvincia":181},
        {"CodDistrito":1770,"NomDistrito":"SORITOR","CodProvincia":181},
        {"CodDistrito":1771,"NomDistrito":"YANTALO","CodProvincia":181},
        {"CodDistrito":1772,"NomDistrito":"BUENOS AIRES","CodProvincia":182},
        {"CodDistrito":1773,"NomDistrito":"CASPISAPA","CodProvincia":182},
        {"CodDistrito":1774,"NomDistrito":"PICOTA","CodProvincia":182},
        {"CodDistrito":1775,"NomDistrito":"PILLUANA","CodProvincia":182},
        {"CodDistrito":1776,"NomDistrito":"PUCACACA","CodProvincia":182},
        {"CodDistrito":1777,"NomDistrito":"SAN CRISTÓBAL","CodProvincia":182},
        {"CodDistrito":1778,"NomDistrito":"SAN HILARIÓN","CodProvincia":182},
        {"CodDistrito":1779,"NomDistrito":"SHAMBOYACU","CodProvincia":182},
        {"CodDistrito":1780,"NomDistrito":"TINGO DE PONASA","CodProvincia":182},
        {"CodDistrito":1781,"NomDistrito":"TRES UNIDOS","CodProvincia":182},
        {"CodDistrito":1782,"NomDistrito":"AWAJUN","CodProvincia":183},
        {"CodDistrito":1783,"NomDistrito":"ELÉAS SOPLIN VARGAS","CodProvincia":183},
        {"CodDistrito":1784,"NomDistrito":"NUEVA CAJAMARCA","CodProvincia":183},
        {"CodDistrito":1785,"NomDistrito":"PARDO MIGUEL","CodProvincia":183},
        {"CodDistrito":1786,"NomDistrito":"POSIC","CodProvincia":183},
        {"CodDistrito":1787,"NomDistrito":"RIOJA","CodProvincia":183},
        {"CodDistrito":1788,"NomDistrito":"SAN FERNANDO","CodProvincia":183},
        {"CodDistrito":1789,"NomDistrito":"YORONGOS","CodProvincia":183},
        {"CodDistrito":1790,"NomDistrito":"YURACYACU","CodProvincia":183},
        {"CodDistrito":1791,"NomDistrito":"ALBERTO LEVEAU","CodProvincia":184},
        {"CodDistrito":1792,"NomDistrito":"CACATACHI","CodProvincia":184},
        {"CodDistrito":1793,"NomDistrito":"CHAZUTA","CodProvincia":184},
        {"CodDistrito":1794,"NomDistrito":"CHIPURANA","CodProvincia":184},
        {"CodDistrito":1795,"NomDistrito":"EL PORVENIR","CodProvincia":184},
        {"CodDistrito":1796,"NomDistrito":"HUIMBAYOC","CodProvincia":184},
        {"CodDistrito":1797,"NomDistrito":"JUAN GUERRA","CodProvincia":184},
        {"CodDistrito":1798,"NomDistrito":"LA BANDA DE SHILCAYO","CodProvincia":184},
        {"CodDistrito":1799,"NomDistrito":"MORALES","CodProvincia":184},
        {"CodDistrito":1800,"NomDistrito":"PAPAPLAYA","CodProvincia":184},
        {"CodDistrito":1801,"NomDistrito":"SAN ANTONIO","CodProvincia":184},
        {"CodDistrito":1802,"NomDistrito":"SAUCE","CodProvincia":184},
        {"CodDistrito":1803,"NomDistrito":"SHAPAJA","CodProvincia":184},
        {"CodDistrito":1804,"NomDistrito":"TARAPOTO","CodProvincia":184},
        {"CodDistrito":1805,"NomDistrito":"NUEVO PROGRESO","CodProvincia":185},
        {"CodDistrito":1806,"NomDistrito":"POLVORA","CodProvincia":185},
        {"CodDistrito":1807,"NomDistrito":"SHUNTE","CodProvincia":185},
        {"CodDistrito":1808,"NomDistrito":"TOCACHE","CodProvincia":185},
        {"CodDistrito":1809,"NomDistrito":"UCHIZA","CodProvincia":185},
        {"CodDistrito":1810,"NomDistrito":"CAIRANI","CodProvincia":186},
        {"CodDistrito":1811,"NomDistrito":"CAMILACA","CodProvincia":186},
        {"CodDistrito":1812,"NomDistrito":"CANDARAVE","CodProvincia":186},
        {"CodDistrito":1813,"NomDistrito":"CURIBAYA","CodProvincia":186},
        {"CodDistrito":1814,"NomDistrito":"HUANUARA","CodProvincia":186},
        {"CodDistrito":1815,"NomDistrito":"QUILAHUANI","CodProvincia":186},
        {"CodDistrito":1816,"NomDistrito":"ILABAYA","CodProvincia":187},
        {"CodDistrito":1817,"NomDistrito":"ITE","CodProvincia":187},
        {"CodDistrito":1818,"NomDistrito":"LOCUMBA","CodProvincia":187},
        {"CodDistrito":1819,"NomDistrito":"ALTO DE LA ALIANZA","CodProvincia":188},
        {"CodDistrito":1820,"NomDistrito":"CALANA","CodProvincia":188},
        {"CodDistrito":1821,"NomDistrito":"CIUDAD NUEVA","CodProvincia":188},
        {"CodDistrito":1822,"NomDistrito":"CORONEL GREGORIO ALBARRACÓN LANCHIPA","CodProvincia":188},
        {"CodDistrito":1823,"NomDistrito":"INCLAN","CodProvincia":188},
        {"CodDistrito":1824,"NomDistrito":"LA YARADA LOS PALOS","CodProvincia":188},
        {"CodDistrito":1825,"NomDistrito":"PACHIA","CodProvincia":188},
        {"CodDistrito":1826,"NomDistrito":"PALCA","CodProvincia":188},
        {"CodDistrito":1827,"NomDistrito":"POCOLLAY","CodProvincia":188},
        {"CodDistrito":1828,"NomDistrito":"SAMA","CodProvincia":188},
        {"CodDistrito":1829,"NomDistrito":"TACNA","CodProvincia":188},
        {"CodDistrito":1830,"NomDistrito":"ESTIQUE","CodProvincia":189},
        {"CodDistrito":1831,"NomDistrito":"ESTIQUE-PAMPA","CodProvincia":189},
        {"CodDistrito":1832,"NomDistrito":"HÉROES ALBARRACÓN","CodProvincia":189},
        {"CodDistrito":1833,"NomDistrito":"SITAJARA","CodProvincia":189},
        {"CodDistrito":1834,"NomDistrito":"SUSAPAYA","CodProvincia":189},
        {"CodDistrito":1835,"NomDistrito":"TARATA","CodProvincia":189},
        {"CodDistrito":1836,"NomDistrito":"TARUCACHI","CodProvincia":189},
        {"CodDistrito":1837,"NomDistrito":"TICACO","CodProvincia":189},
        {"CodDistrito":1838,"NomDistrito":"CANOAS DE PUNTA SAL","CodProvincia":190},
        {"CodDistrito":1839,"NomDistrito":"CASITAS","CodProvincia":190},
        {"CodDistrito":1840,"NomDistrito":"ZORRITOS","CodProvincia":190},
        {"CodDistrito":1841,"NomDistrito":"CORRALES","CodProvincia":191},
        {"CodDistrito":1842,"NomDistrito":"LA CRUZ","CodProvincia":191},
        {"CodDistrito":1843,"NomDistrito":"PAMPAS DE HOSPITAL","CodProvincia":191},
        {"CodDistrito":1844,"NomDistrito":"SAN JACINTO","CodProvincia":191},
        {"CodDistrito":1845,"NomDistrito":"SAN JUAN DE LA VIRGEN","CodProvincia":191},
        {"CodDistrito":1846,"NomDistrito":"TUMBES","CodProvincia":191},
        {"CodDistrito":1847,"NomDistrito":"AGUAS VERDES","CodProvincia":192},
        {"CodDistrito":1848,"NomDistrito":"MATAPALO","CodProvincia":192},
        {"CodDistrito":1849,"NomDistrito":"PAPAYAL","CodProvincia":192},
        {"CodDistrito":1850,"NomDistrito":"ZARUMILLA","CodProvincia":192},
        {"CodDistrito":1851,"NomDistrito":"RAYMONDI","CodProvincia":193},
        {"CodDistrito":1852,"NomDistrito":"SEPAHUA","CodProvincia":193},
        {"CodDistrito":1853,"NomDistrito":"TAHUANIA","CodProvincia":193},
        {"CodDistrito":1854,"NomDistrito":"YURUA","CodProvincia":193},
        {"CodDistrito":1855,"NomDistrito":"CALLERIA","CodProvincia":194},
        {"CodDistrito":1856,"NomDistrito":"CAMPOVERDE","CodProvincia":194},
        {"CodDistrito":1857,"NomDistrito":"IPARIA","CodProvincia":194},
        {"CodDistrito":1858,"NomDistrito":"MANANTAY","CodProvincia":194},
        {"CodDistrito":1859,"NomDistrito":"MASISEA","CodProvincia":194},
        {"CodDistrito":1860,"NomDistrito":"NUEVA REQUENA","CodProvincia":194},
        {"CodDistrito":1861,"NomDistrito":"YARINACOCHA","CodProvincia":194},
        {"CodDistrito":1862,"NomDistrito":"ALEXANDER VON HUMBOLDT","CodProvincia":195},
        {"CodDistrito":1863,"NomDistrito":"CURIMANA","CodProvincia":195},
        {"CodDistrito":1864,"NomDistrito":"IRAZOLA","CodProvincia":195},
        {"CodDistrito":1865,"NomDistrito":"NESHUYA","CodProvincia":195},
        {"CodDistrito":1866,"NomDistrito":"PADRE ABAD","CodProvincia":195},
        {"CodDistrito":1867,"NomDistrito":"PURUS","CodProvincia":196}
    ]

export const useRegiones = () => {
    const [ paises, setPaises ] = useState([]);
    const [ departamentos, setDepartamentos ] = useState(departamentosPrueba);
    const [ provincias, setProvincias ] = useState(provinciasPruebas);
    const [ distritos, setDistritos ] = useState(distritosPrueba);
    
    useEffect(()=>{
        
    })
    const obtenerPaises = async () => {
        // const paisesEjemplo = [{nombre: "Peru", cod:1},{nombre: "Peru2", cod:2}] 
        // setPaises(paisesEjemplo)-
        const respuesta = await Fetch({
            url: urlBasePlamin + '/regiones/paises'
        });

        if (respuesta.isValid) {
            setPaises(d => respuesta.content);
        } else {
            notify(respuesta.content, 'error');
        }
    }

    const obtenerDepartamentos = async () => {      
        setDepartamentos(departamentosPrueba)
    }

    const obtenerProvincias = async (codDepartamento) => {
        
        setProvincias(provinciasPruebas)
        const provinciasEncontradas = []
        provinciasPruebas.map( p =>{
            if(codDepartamento == p.CodDepartamento){
                provinciasEncontradas.push(p) 
            }
        }) 
        setProvincias(provinciasEncontradas)
        setDistritos(d => [])
        // if (codDepartamento === 0) {
        //     setProvincias(p => []);            
        //     return;
        // }

        // if (!codDepartamento) {
        //     notify('Debe enviar un departamento', 'error');
        //     return;
        // }
    }

    const obtenerDistritos = async (codProvincia) => {
        setDistritos(distritosPrueba)
        const distritosEncontrados = []
        distritosPrueba.map( p =>{
            if(codProvincia == p.CodProvincia){
                distritosEncontrados.push(p) 
            }
        })
        
        setDistritos(distritosEncontrados)
        //     if (codProvincia === 0) {
        //         setDistritos(d => []);
        //         return;
        //     }

        //     if (!codProvincia) {
        //         notify('Debe enviar una provincia', 'error');
        //         return;
        //     }
    }

    return { paises, obtenerPaises, departamentos, obtenerDepartamentos, provincias, obtenerProvincias, distritos, obtenerDistritos }
}